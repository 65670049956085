/* eslint-disable react/no-unescaped-entities */
import { Channel, useNewChannel, useUpdateUser } from '@axios/mutations';
import {
  FacebookPage,
  InstagramAccount,
  useChannels,
  useFacebookPages,
  useInstagramAccounts,
  useNangoConnections,
  User,
  useTiktokAccounts,
} from '@axios/queries';
import PopupComponent from '@components/popup/PopupComponent';
import { ScreenLoader } from '@components/screen-loader/ScreenLoader';
import { useNango } from '@hooks';
import arrowRight from '@images/arrow-right-s-line.svg';
import backButton from '@images/back_btn.svg';
import checkFill from '@images/check-fill.svg';
import errorIcon from '@images/error_icon.png';
import InstagramSVG from '@images/instagram.svg';
import loaderBlueAnimation from '@images/LoaderBlue.json';
import { usePopupStore } from '@redux/store';
import { Crisp } from 'crisp-sdk-web';
import Lottie from 'lottie-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import ChoosePage from './choose-page/ChoosePage';
import channelsData from './connectChannel.config';
import styles from './ConnectChannels.module.scss';

interface ConnectChannelsProps {
  user: User;
  user_id: string;
  channels: Channel[];
}

const ConnectChannels: React.FC<ConnectChannelsProps> = ({ user, user_id, channels }) => {
  const { t } = useTranslation();

  const [connectedChannels, setConnectedChannels] = useState(
    channels?.map((c) => c.type),
  );

  const [errorChannels, setErrorChannels] = useState(
    channels?.map((c) => {
      if (c.isConnected == false) {
        return c.type;
      }
    }),
  );

  const [showChoosePage, setShowChoosePage] = useState(null);
  const [isLoadingConnection, setIsLoadingConnection] = useState<Record<string, boolean>>(
    {},
  ); // Change to track loading state by channel

  const { connectWithNango } = useNango();
  const setPopup = usePopupStore((state) => state.setPopup); // Use the setPopup function
  const popupRef = useRef(null); // Create a ref for the popup container

  // Function to close popup
  const closePopup = () => setPopup(null, null);

  // const handleClickOutside = useCallback(
  //   (event) => {
  //     if (popupRef.current && !popupRef.current.contains(event.target)) {
  //       closePopup();
  //     }
  //   },
  //   [setPopup],
  // );

  const { mutateAsync: newChannel } = useNewChannel();

  const {
    data: instagramPages,
    isLoading: isLoadingInstagramAccounts,
    refetch: fetchInstagramAccounts,
  } = useInstagramAccounts(user_id, { enabled: false });

  const {
    data: facebookPages,
    isLoading: isLoadingFacebookPages,
    refetch: fetchFacebookPages,
  } = useFacebookPages(user_id, { enabled: false });

  const {
    data: tiktokAccounts,
    isLoading: isLoadingTiktokAccounts,
    refetch: fetchTiktokAccounts,
  } = useTiktokAccounts(user_id, { enabled: false });

  const requiresAdditionalSteps = (channelKey) => {
    // Define logic to determine if a channel requires additional steps
    return channelKey === 'instagram' || channelKey === 'facebook';
  };

  const showToast = () => {
    toast(
      <div style={{ display: 'flex' }}>
        <img src={checkFill} width={'24px'} alt="" style={{ marginRight: '12px' }} />
        {t('connectChannel.addAccountSuccess')}
      </div>,
      {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      },
    );

    // Set up a timer to refresh the page 5 seconds after showing the toast
    setTimeout(() => {
      window.location.reload();
    }, 5000); // 5000 milliseconds = 5 seconds
  };

  const handleConnect = useCallback(
    async (channelKey) => {
      setIsLoadingConnection((prev) => ({ ...prev, [channelKey]: true })); // Set loading true for specific channel

      try {
        await connectWithNango(channelsData[channelKey].integrationId, user_id);

        // After successful connection
        if (requiresAdditionalSteps(channelKey)) {
          if (channelKey == 'instagram') {
            await fetchInstagramAccounts().then(() => {
              setShowChoosePage('instagram');
            });
          } else if (channelKey == 'facebook') {
            await fetchFacebookPages().then(() => {
              setShowChoosePage('facebook');
            });
          }
        } else {
          if (channelKey == 'tiktok') {
            await fetchTiktokAccounts().then(async (data) => {
              console.log('data:', data);
              // Logic to update the database with the selected page
              await newChannel({
                channel_id: data?.data?.business_id,
                type: 'tiktok',
              });
            });
          }

          setIsLoadingConnection((prev) => ({ ...prev, [channelKey]: false })); // Set loading false for specific channel
          setConnectedChannels((prev) => [...prev, channelKey]);
          showToast();
        }
      } catch (error) {
        console.error(`Error connecting to ${channelKey}:`, error);
      } finally {
        setIsLoadingConnection((prev) => ({ ...prev, [channelKey]: false })); // Set loading false for specific channel
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      connectWithNango,
      user_id,
      fetchInstagramAccounts,
      fetchFacebookPages,
      fetchTiktokAccounts,
      newChannel,
    ],
  );

  const handlePageConfirm = useCallback(
    async (page: InstagramAccount | FacebookPage) => {
      try {
        // Await the completion of the newChannel call
        await newChannel({
          channel_id: page.id,
          type: showChoosePage,
        });

        // Update the connected channels state
        setConnectedChannels((prev) => [...prev, showChoosePage]);
        showToast();
      } catch (error) {
        console.error(`Error while adding new channel: ${showChoosePage}`, error);
      } finally {
        // Update the loading state based on the type of page
        if (showChoosePage === 'instagram') {
          setIsLoadingConnection({ instagram: false });
        } else if (showChoosePage === 'facebook') {
          setIsLoadingConnection({ facebook: false });
        }

        setShowChoosePage(null);
      }
    },
    [newChannel, showChoosePage],
  );

  const handlePageCancel = useCallback(() => {
    // Logic to cancel the Nango connection
    console.log('Cancelling Nango connection');
    if (showChoosePage === 'instagram') {
      setIsLoadingConnection({ instagram: false }); // Set loading false for specific channel
    } else if (showChoosePage === 'facebook') {
      setIsLoadingConnection({ facebook: false }); // Set loading false for specific channel
    }
    // Call a function or API to cancel the Nango connection
    // TODO delete? connection in Nango -- not sure with the current logic (si pas ds la db, demande de se reconnecter)
    setShowChoosePage(null);
  }, []);

  const { mutateAsync: updateUser } = useUpdateUser(user_id);

  useEffect(() => {
    const lastExecution = localStorage.getItem('helpConnectMessage');
    const now = new Date();

    if (!lastExecution || now.getTime() - new Date(lastExecution).getTime() > 86400000) {
      // 86400000 milliseconds in a day
      const timer = setTimeout(() => {
        console.log('PUSH EVENT');
        Crisp.message.showText(t('helpConnectMessage'));
        // Crisp.session.pushEvent('connect-channel');
        // Crisp.trigger.run('connect-channel');

        // Update last execution date in local storage
        localStorage.setItem('helpConnectMessage', now.toISOString());
      }, 20000); // 10000 milliseconds = 10 seconds

      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
  }, []); // Dependency array remains empty, so this effect runs only once after the initial render

  const openCrisp = () => {
    // Crisp.message.send('text', t('needHelpToConnect'));
    Crisp.chat.open();
  };

  // useEffect(() => {
  //   // Add when the component mounts
  //   document.addEventListener('mousedown', handleClickOutside);
  //   // Remove when the component unmounts
  //   return () => document.removeEventListener('mousedown', handleClickOutside);
  // }, [handleClickOutside]);

  if (showChoosePage && (!isLoadingInstagramAccounts || !isLoadingFacebookPages)) {
    return (
      <ChoosePage
        networkAccounts={showChoosePage === 'instagram' ? instagramPages : facebookPages}
        onConfirmPage={handlePageConfirm}
        onCancel={handlePageCancel}
        channel={showChoosePage}
      />
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.connectChannelsContainer} ref={popupRef}>
        <button onClick={closePopup} className={styles.returnButton}>
          <img src={backButton} alt="" />
        </button>

        <div className={styles.titleContainer}>
          <h2 className={styles.title}>{t('connectChannel.addNewAccount')}</h2>
          <div className={styles.description}>
            {t('connectChannel.description')}

            <button onClick={openCrisp} className={styles.descriptionHelp}>
              {t('onboarding.connectChannel.description 2')}
            </button>
          </div>
        </div>

        <div className={styles.channelContainer}>
          {/* <p className={styles.chooseNetwork}>{t('connectChannel.choose')}</p> */}

          <div className={styles.connectionButtonsContainer}>
            {Object.keys(channelsData).map((channel) => (
              <button
                key={channel}
                className={styles.connectionButton}
                onClick={() => handleConnect(channel)}
                disabled={
                  connectedChannels.includes(channel) && !errorChannels.includes(channel)
                }
              >
                <img
                  src={channelsData[channel].image}
                  alt={''}
                  className={styles.channelLogo}
                />
                <div className={styles.channelInfoContainer}>
                  <div className={styles.channelText}>
                    <h3 className={styles.channelTitle}>{channelsData[channel].name}</h3>
                    {t(channelsData[channel].description) &&
                      !errorChannels.includes(channel) && (
                        <p className={styles.channelDescription}>
                          {t(channelsData[channel].description)}{' '}
                          {channelsData[channel].urlTuto && '('}
                          {channelsData[channel].urlTuto && (
                            <a
                              href={channelsData[channel].urlTuto}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={styles.tutorialLink}
                              onClick={(e) => e.stopPropagation()} // Empêcher l'événement de remonter
                            >
                              {t('connectChannel.knowMore')}
                            </a>
                          )}
                          {channelsData[channel].urlTuto && ')'}
                        </p>
                      )}

                    {errorChannels.includes(channel) && (
                      <p className={styles.error}>{t('errorReconnectChannelLong')}</p>
                    )}
                  </div>

                  {isLoadingConnection[channel] ? (
                    <Lottie
                      className={styles.checkFill}
                      animationData={loaderBlueAnimation}
                      loop={true}
                      style={{ width: 20, height: 20 }}
                    />
                  ) : connectedChannels.includes(channel) &&
                    !errorChannels.includes(channel) ? (
                    connectedChannels.includes(channel) && (
                      <img src={checkFill} alt="" className={styles.checkFill} />
                    )
                  ) : (
                    <img src={arrowRight} alt="" className={styles.arrowRight} />
                  )}
                </div>
              </button>
            ))}
          </div>
        </div>

        <button
          onClick={() => {
            closePopup();
          }}
          className={styles.confirmButton}
        >
          {t('connectChannel.confirm')}
        </button>
      </div>
    </div>
  );
};

export default ConnectChannels;
