/* eslint-disable max-len */
import { useCreateCheckoutSession } from '@axios/mutations';
import { User } from '@axios/queries';
import PricingPlan from '@components/onboarding/choose-plan/pricing-plan/PricingPlan';
import { PAYMENT_LINKS, PLANS } from '@components/onboarding/plan.config';
import backButton from '@images/back_btn.svg';
import { Group, Switch, Text } from '@mantine/core';
import { Crisp } from 'crisp-sdk-web';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ChoosePlanPopup.module.scss';

// Define the prop types
interface ChoosePlanPopupProps {
  onClose?: () => void;
  user: User;
}

const ChoosePlanPopup = ({ onClose, user }: ChoosePlanPopupProps) => {
  const { t } = useTranslation();

  const [billingCycle, setBillingCycle] = useState<'monthly' | 'yearly'>('yearly');
  const { mutateAsync: createCheckoutSession } = useCreateCheckoutSession();

  // Basculer entre la facturation mensuelle et annuelle
  const toggleBillingCycle = (isYearly: boolean) => {
    setBillingCycle(isYearly ? 'yearly' : 'monthly');
  };

  const onChoosePlan = (plan: number, billingCycle: 'monthly' | 'yearly') => {
    const referral_id = window?.Rewardful?.referral;
    const user_id = user.user_id;
    // const session_url = await createCheckoutSession({
    //   planId: plan,
    //   billingCycle,
    //   user_id,
    //   referral_id,
    // }).then((data) => data.session_url);

    const stripe_customer_id = user?.stripe_customer_id;
    // const stripe_customer_id = window?.Rewardful?.referral;
    const email = user?.email;
    const prefilled_promo_code = window?.Rewardful?.coupon.id
      ? `&prefilled_promo_code=${window?.Rewardful?.coupon.id}`
      : '';

    const encodedEmail = encodeURIComponent(email);
    const paymentLinkBase =
      import.meta.env.VITE_NODE_ENV == 'development'
        ? 'https://buy.stripe.com/test_fZe8zj812baT87KdQQ'
        : PAYMENT_LINKS[plan][billingCycle];

    const paymentLink = `${paymentLinkBase}?prefilled_email=${encodedEmail}&client_reference_id=${stripe_customer_id}${prefilled_promo_code}`;

    window.location.href = paymentLink;
    onClose();
  };

  useEffect(() => {
    window?.rewardful('ready', function () {
      if (window?.Rewardful?.referral) {
        console.log('Current referral ID: ', window?.Rewardful?.referral);
        // console.log('Current COUPON: ', window?.rewardful?.coupon);
      }
    });
  });

  return (
    <div className={styles.root}>
      <div className={styles.popupContainer}>
        {onClose && (
          <button onClick={onClose} className={styles.returnButton}>
            <img src={backButton} alt="" />
          </button>
        )}

        <div className={styles.choosePlanContainer}>
          <div className={styles.titleContainer}>
            <h6 className={styles.title}>
              {t('onboarding.choosePlan.title.firstPart')}{' '}
              <span className={styles.underlineTitle}>
                {t('onboarding.choosePlan.title.boldPart')}
              </span>
            </h6>
            <p className={styles.description}>{t('onboarding.choosePlan.description')}</p>
          </div>

          <div className={styles.billingCycleToggle}>
            {t('onboarding.choosePlan.monthly')}
            <Switch
              checked={billingCycle === 'yearly'}
              color={'#375DFB'}
              onChange={(event) => toggleBillingCycle(event.currentTarget.checked)}
            />
            {t('onboarding.choosePlan.yearly')}
            <div className={styles.toggleLabel}>
              {t('onboarding.choosePlan.discount')}
            </div>
          </div>

          <div className={styles.plansContainer}>
            {PLANS.map((plan) => (
              <PricingPlan
                key={plan.id}
                name={t(plan.name)}
                description={t(plan.description)}
                features={plan.features}
                monthlyPrice={plan.monthlyPrice}
                yearlyPrice={plan.yearlyPrice}
                onChoosePlan={(billingCycle) => {
                  onChoosePlan(plan.id, billingCycle);
                }}
                isPopular={plan.isPopular}
                billingCycle={billingCycle}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoosePlanPopup;
