import { useAxiosInstance } from '@axios/useAxiosInstance';
import { QueryFunction, useQuery, UseQueryOptions } from '@tanstack/react-query';

type FacebookPage = {
  name: string;
  business: string;
  biography: string;
  profile_picture_url: string;
  username: string;
  followers_count: number;
  fan_count: string;
  category: number;
  emails: [string];
  website: string;
  cover: string;
  id: string;
};

const useFacebookPages = (
  user_id: string,
  options?: Omit<
    UseQueryOptions<FacebookPage[], Error, FacebookPage[], [string, string]>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  },
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const fetchFacebookPages: QueryFunction<
    FacebookPage[],
    [string, string]
  > = async () => {
    const instance = await getAxiosInstance();

    return instance
      .get('api/facebook/accounts', { params: { user_id } })
      .then((response) => response.data);
  };

  return useQuery<FacebookPage[], Error, FacebookPage[], [string, string]>(
    ['facebook-accounts', user_id],
    fetchFacebookPages,
    {
      ...options,
      onSuccess:
        options?.onSuccess ||
        ((data) => {
          console.log('facebook Accounts fetched successfully!', data);
        }),
      onError:
        options?.onError ||
        ((error) => {
          console.error('Error fetching facebook Accounts:', error);
        }),
    },
  );
};

export { useFacebookPages };
export type { FacebookPage };
