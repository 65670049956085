import { Channel, useGenerateReply, useUpdateReply } from '@axios/mutations';
import { Comment, SocialMediaInteraction } from '@axios/queries';
import ElonIcon from '@images/elon_icon.svg';
import loaderAnimation from '@images/loader.json';
import sendIcon from '@images/send_icon.svg';
import skipIcon from '@images/skip-icon.svg';
import { Tooltip } from '@mantine/core';
import { intersection } from 'lodash';
import Lottie from 'lottie-react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ElonComponent.module.scss';
import ValidationComponent from './validation-component/ValidationComponent';

type ElonComponentProps = {
  channel: Channel; // Assuming you have a Channel type defined
  interaction: Comment;
  handleValidation?: (any?) => void;
  showSkipButton?: boolean;
  skipComment?: (any?) => void;
};

type Message = {
  id?: string;
  role: 'assistant' | 'user' | 'system';
  content: string;
  action?: string | null | boolean; // Indicates if the message requires user action
};

const ElonComponent: React.FC<ElonComponentProps> = ({
  channel,
  interaction,
  handleValidation,
  showSkipButton = false,
  skipComment,
}) => {
  const { t } = useTranslation();

  const [instructions, setInstructions] = useState([]);

  const [messages, setMessages] = useState<Message[]>([]);
  const [message, setMessage] = useState<string>('');
  const messagesEndRef = useRef(null);

  const [reply, setReply] = useState<string>('');
  const [action, setAction] = useState<string>('');

  const [isTyping, setIsTyping] = useState(false);
  const [isLoadingReply, setIsLoadingReply] = useState<boolean>(false);
  const [isLoadingSkip, setIsLoadingSkip] = useState<boolean>(false);

  const [showButtonGenerateReply, setShowButtonGenerateReply] = useState(true);

  const { mutateAsync: updateReply, isLoading: isUpdatingReply } = useUpdateReply({
    onSuccess: (data) => {},
    onError: (error) => {
      console.error('Error updating AI reply:', error);
    },
  });

  // Function to simulate typing effect
  const simulateTyping = (message: Message) => {
    const { content, action } = message;

    setIsTyping(true);
    setReply(content);
    setAction(action as string);

    // let typedText = '';
    // for (let i = 0; i < content.length; i++) {
    //   typedText += content.charAt(i);
    //   // Simulate typing by updating messages array with partially typed text
    //   // Update the message with partially typed text
    //   if (i % 2 === 0 || i === content.length - 2) {
    //     // Update at intervals for performance
    //     setReply(typedText); // '|' acts as a cursor
    //     await new Promise((r) => setTimeout(r, 35)); // Delay to simulate typing speed
    //   }
    // }

    // await new Promise((r) => setTimeout(r, 100)); // Delay to simulate typing speed

    // typedText = '';
    // if (action) {
    //   for (let i = 0; i < action.length; i++) {
    //     typedText += action.charAt(i);
    //     // Simulate typing by updating messages array with partially typed text
    //     // Update the message with partially typed text
    //     if (i % 2 === 0 || i === action.length - 2) {
    //       // Update at intervals for performance
    //       setAction(typedText); // '|' acts as a cursor
    //       await new Promise((r) => setTimeout(r, 50)); // Delay to simulate typing speed
    //     }
    //   }
    // }

    setReply('');
    setAction('');
    setIsTyping(false);
    setMessages((msgs) => [...msgs, message]);
  };

  // Effect to start typing simulation when component mounts or interaction changes
  useEffect(() => {
    if (interaction.shouldIgnore?.status) {
      // If there's an AI-generated reply that requires action
      const message: Message = {
        content: t('defaultMessageAvoid'),
        role: 'assistant',
        action: null,
      };

      setMessages((msgs) => [...msgs, message]);
    } else if (interaction.lackContext?.status) {
      // If there's an AI-generated reply that requires action
      const message: Message = {
        content: t('defaultMessageLackContext'),
        role: 'assistant',
        action: null,
      };

      setMessages((msgs) => [...msgs, message]);
    } else if (interaction.shouldUseReply && !interaction.shouldUseReply?.status) {
      // If there's an AI-generated reply that requires action
      const message: Message = {
        content: t('defaultMessageLackContext'),
        role: 'assistant',
        action: null,
      };

      setMessages((msgs) => [...msgs, message]);
    } else if (
      interaction.aiReply &&
      interaction.aiReply.text &&
      interaction.aiReply?.text != interaction.reply?.text
    ) {
      // If there's an AI-generated reply that requires action
      const message: Message = {
        content: t('elon.defaultMessage'),
        role: 'assistant',
        action: interaction.aiReply.text,
      };

      setMessages((msgs) => [...msgs, message]);
    }
  }, []);

  const handleSendMessage = (message: string) => {
    setShowButtonGenerateReply(false);
    setIsLoadingReply(true);
    setMessage('');

    const userMessage: Message = {
      content: message,
      role: 'user',
    };

    setMessages((msgs) => [...msgs, userMessage]);

    setInstructions((instructions) => [...instructions, userMessage]);
    const newInstructions = [...instructions, userMessage];

    void updateReply({
      messages: newInstructions,
      aiReplyId: interaction.aiReply?._id,
      userMessage: message,
      comment_id: interaction._id,
    }).then(
      ({
        updatedReply,
        message: messageElon,
        action,
        language,
        instruction,
        aiReply,
      }) => {
        if (interaction.aiReply?._id && updatedReply) {
          interaction.aiReply.text = updatedReply;
        }

        if (aiReply) {
          interaction.aiReply = aiReply;
        }

        setInstructions((instructions) => [
          ...instructions,
          {
            content: JSON.stringify({
              action: action,
              message: messageElon,
              language: language,
              instruction: instruction,
            }),
            role: 'assistant',
          },
        ]);

        console.log('updatedReply:', updatedReply);
        console.log('messageElon:', messageElon);
        console.log('action:', action);

        const message: Message = {
          content: messageElon,
          role: 'assistant',
          action: action == 'instruction' ? updatedReply : null,
        };

        void simulateTyping(message);
        setIsLoadingReply(false);
        setShowButtonGenerateReply(true);
      },
    );
  };

  const handleSkip = () => {
    setIsLoadingSkip(true);
    skipComment();

    setIsLoadingSkip(false);
  };

  const handleGenerateReply = () => {
    handleSendMessage(t('writeReply'));
    setShowButtonGenerateReply(false);
  };

  const onCloseWriteReply = () => {
    const updatedMessages = messages.filter((message) => message.id !== '0');
    setMessages(updatedMessages);
    setShowButtonGenerateReply(true);
  };

  const writeReply = () => {
    setShowButtonGenerateReply(false);

    setMessage('');

    const message: Message = {
      id: '0',
      content: '',
      role: 'assistant',
      action: true,
    };

    void simulateTyping(message);
    setIsLoadingReply(false);
  };

  useEffect(() => {
    console.log('SELECTED INTERACTION (elonComponent):', interaction);
    const currentContainer = messagesEndRef.current;
    if (currentContainer) {
      currentContainer.scrollTo({
        top: currentContainer.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messages]);

  return (
    <div className={styles.elonContainer}>
      <div className={`${styles.messagesContainer}`} ref={messagesEndRef}>
        {messages.map((msg, index) => (
          <div key={msg.id || index}>
            {msg.content && msg.content.length > 0 && (
              <div
                className={
                  msg.role === 'assistant' ? styles.aiMessage : styles.userMessage
                }
              >
                <img
                  src={msg.role === 'assistant' ? ElonIcon : channel.profile_picture_url}
                  alt=""
                  className={styles.profilePicture}
                />
                <span>{msg.content}</span>
              </div>
            )}

            {msg.action && (
              <div className={styles.fadeIn}>
                <ValidationComponent
                  channel={channel}
                  text={msg.action == true ? '' : msg.action}
                  onValidate={
                    handleValidation ? (reply?) => handleValidation(reply) : null
                  }
                  comment={interaction}
                  onClose={onCloseWriteReply}
                />
              </div>
            )}
          </div>
        ))}
      </div>

      <div
        className={styles.inputContainer}
        style={messages && messages.length > 0 ? { marginTop: '16px' } : {}}
      >
        {(showButtonGenerateReply || isLoadingReply) && (
          <div className={styles.buttonContainer}>
            {!interaction.aiReply?.text &&
              (showButtonGenerateReply || isLoadingReply) && (
                <button
                  className={styles.buttonGenerateReply}
                  disabled={
                    isLoadingReply ||
                    interaction.shouldIgnore?.status ||
                    interaction.lackContext?.status
                  }
                  onClick={() => handleGenerateReply()}
                >
                  {t('writeReply')}
                  {!isLoadingReply ? (
                    '✨'
                  ) : (
                    <Lottie
                      animationData={loaderAnimation}
                      loop={true}
                      style={{ width: 16, height: 16, marginLeft: 8 }}
                    />
                  )}
                </button>
              )}

            {(!interaction.aiReply ||
              interaction.aiReply?.text == interaction.reply?.text) &&
              showButtonGenerateReply && (
                <button
                  className={`${styles.buttonGenerateReply} ${styles.buttonWriteReply}`}
                  disabled={isLoadingReply}
                  onClick={() => writeReply()}
                >
                  {t('writeByMySelf')} ✏️
                </button>
              )}

            {showSkipButton && (
              <Tooltip
                openDelay={500}
                label={t('skipComment')}
                position="top"
                color="#667085"
                styles={{
                  tooltip: {
                    color: '#FFFFFF', // Custom text color
                  },
                }}
              >
                <button
                  className={`${styles.skipButton}`}
                  onClick={() => handleSkip()}
                  disabled={
                    isLoadingReply || isLoadingSkip || interaction.reply ? true : false
                  }
                >
                  {t('skipComment')}
                  {!isLoadingSkip ? (
                    <img src={skipIcon} alt="" />
                  ) : (
                    <Lottie
                      animationData={loaderAnimation}
                      loop={true}
                      style={{ width: 16, height: 16, filter: 'brightness(0.2)' }}
                    />
                  )}
                </button>
              </Tooltip>
            )}
          </div>
        )}

        {/* <div className={styles.input}>
          <input
            type="text"
            className={styles.messageInput}
            placeholder={t('elon.writeToElon')}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSendMessage(message)}
            // disabled={isLoadingReply || (interaction.reply ? true : false)}
            disabled={
              isLoadingReply ||
              interaction.shouldIgnore?.status ||
              interaction.lackContext?.status
            }
            // disabled={true}
          />

          <div className={styles.inputButtonsContainer}>
            {showSkipButton && (
              <Tooltip
                openDelay={500}
                label={t('skipComment')}
                position="top"
                color="#667085"
                styles={{
                  tooltip: {
                    color: '#FFFFFF', // Custom text color
                  },
                }}
              >
                <button
                  className={`${styles.skipButton}`}
                  onClick={() => handleSkip()}
                  disabled={
                    isLoadingReply || isLoadingSkip || interaction.reply ? true : false
                  }
                >
                  {!isLoadingSkip ? (
                    <img src={skipIcon} alt="" />
                  ) : (
                    <Lottie
                      animationData={loaderAnimation}
                      loop={true}
                      style={{ width: 16, height: 16, filter: 'brightness(0.2)' }}
                    />
                  )}
                </button>
              </Tooltip>
            )}

            <button
              className={`${styles.sendButton} ${
                !(message?.length > 0) && !isLoadingReply && styles.sendButtonDisabled
              }`}
              onClick={() => handleSendMessage(message)}
              disabled={isLoadingReply || !(message?.length > 0)}
            >
              {!isLoadingReply ? (
                <img src={sendIcon} alt="" />
              ) : (
                <Lottie
                  animationData={loaderAnimation}
                  loop={true}
                  style={{ width: 16, height: 16 }}
                />
              )}
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ElonComponent;
