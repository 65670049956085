import { Reply } from '@axios/queries';
import { useAxiosInstance } from '@axios/useAxiosInstance';
import { MutationFunction, useMutation, UseMutationOptions } from '@tanstack/react-query';

type SendReplyInput = {
  channel_id: string;
  comment_id: string;
  message: string;
};

type SendReplyOutput = {
  reply: Reply;
};

const useSendReply = (
  options?: Omit<
    UseMutationOptions<SendReplyOutput, Error, SendReplyInput>,
    'mutationFn'
  >,
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const sendReply: MutationFunction<SendReplyOutput, SendReplyInput> = async (
    input: SendReplyInput,
  ) => {
    if (!authenticated) {
      throw new Error('User must be authenticated to send reply');
    }

    const instance = await getAxiosInstance();

    return instance
      .post('api/assistant/send-reply', input)
      .then((response) => response.data);
  };

  return useMutation<SendReplyOutput, Error, SendReplyInput>(sendReply, {
    ...options,
    onSuccess:
      options?.onSuccess ||
      ((data) => {
        console.log('Reply sent successfully!', data);
      }),
    onError:
      options?.onError ||
      ((error, input) => {
        console.error('Error sending reply:', error, input);
      }),
  });
};

export { useSendReply };
export type { SendReplyInput, SendReplyOutput };
