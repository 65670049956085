import { useAxiosInstance } from '@axios/useAxiosInstance';
import { QueryFunction, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { User } from './useUser';

const useUsers = (
  input: string | Partial<User[]>,
  options?: Omit<
    UseQueryOptions<User[], Error, User[], [string]>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  },
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const fetchUser: QueryFunction<User[], [string]> = async () => {
    const instance = await getAxiosInstance();

    return instance.get('/api/users/findAll').then((response) => response.data);
  };

  const queryKey: [string] = ['users'];

  return useQuery<User[], Error, User[], [string]>(queryKey, fetchUser, {
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
    onSuccess:
      options?.onSuccess ||
      ((data) => {
        if (import.meta.env.VITE_PRINT_SUCCESSFUL_ENDPOINTS === 'true') {
          console.log('Users data fetched successfully!', data);
        }
      }),
    onError:
      options?.onError ||
      ((error) => {
        console.error('Error fetching user data:', error);
      }),
    enabled: options?.enabled && authenticated,
  });
};

export type { User };
export { useUsers };
