import { useAuth0 } from '@auth0/auth0-react';
import {
  useChannels,
  useLastHiddenComments,
  useStripeData,
  useStripeSubscriptionStatus,
  useTasks,
  useUser,
} from '@axios/queries';
import { AppWrapper } from '@components';
import ErrorPage from '@components/error-page/ErrorPage';
import { Task } from '@containers/task/Task';
import { TaskSkeleton } from '@containers/task/TasksSkeleton';
import { useEffect, useRef, useState } from 'react';

const TaskRoute = () => {
  const {
    isAuthenticated,
    isLoading: isLoadingAuth,
    loginWithRedirect,
    user,
  } = useAuth0();

  const { data: stripeData, isLoading: isLoadingStripeData } = useStripeData();

  // const { data: stripeStatus, isLoading: isLoadingStripeStatus } =
  //   useStripeSubscriptionStatus();

  const { data: userData, isLoading: isLoadingUser } = useUser(
    {
      user_id: user?.sub,
    },
    { enabled: !!stripeData?.hasAccess },
  );

  const { data: initialTasks, isLoading: isLoadingTasks } = useTasks(
    {},
    {
      enabled: !!stripeData?.hasAccess && !!userData?.fine_tune_model,
      onSuccess: (newTasks) => {
        setTasks(newTasks);
      },
    },
  );

  const { data: initialHiddenComments, isLoading: isLoadingHiddenComments } =
    useLastHiddenComments(
      {},
      {
        enabled: !!stripeData?.hasAccess && !!userData?.fine_tune_model,
        onSuccess: (newTasks) => {
          setHiddenComments(newTasks);
        },
      },
    );

  const { data: channels, isLoading: isLoadingChannels } = useChannels(
    {
      user_id: user?.sub,
    },
    { enabled: !!stripeData?.hasAccess && !!userData?.fine_tune_model },
  );

  const [tasks, setTasks] = useState(initialTasks);
  const [hiddenComments, setHiddenComments] = useState(initialHiddenComments);

  const isLoading =
    isLoadingTasks ||
    !tasks ||
    isLoadingHiddenComments ||
    !hiddenComments ||
    isLoadingAuth ||
    isLoadingChannels ||
    isLoadingUser ||
    isLoadingStripeData;

  return (
    <AppWrapper
      activePath="/task"
      isLoading={isLoading}
      isLoadingAuth={isLoadingAuth}
      isAuthenticated={isAuthenticated}
      loginWithRedirect={loginWithRedirect}
      user={user}
      loadingSkeleton={<TaskSkeleton />}
    >
      <Task
        tasks={tasks}
        channels={channels}
        user={userData}
        stripeData={stripeData}
        recentHiddenComments={hiddenComments}
      />
    </AppWrapper>
  );
};

export default TaskRoute;
export const Catch = () => {
  return <ErrorPage />;
};
