// import { OnboardingWizard } from '@containers';
import { useAuth0 } from '@auth0/auth0-react';
import { useChannels, useNangoConnections, useStripeData, useUser } from '@axios/queries';
import { AppWrapper, ScreenLoader } from '@components';
import ErrorPage from '@components/error-page/ErrorPage';
import ErrorMobile from '@components/error-page-mobile/ErrorMobile';
import LoginPage from '@components/login-page/LoginPage';
import channelsData from '@components/onboarding/connect-channels/connectChannel.config';
import { Dashboard, Onboarding } from '@containers';
import { Crisp } from 'crisp-sdk-web';
import { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

const MOBILE_SUPPORTED = false;

declare global {
  interface Window {
    rewardful: (command: string, options?: any) => void;
    Rewardful?: {
      referral?: string;
      coupon?: any;
      // Add more properties of Rewardful object here as needed
    };
  }
}

const OnboardingRoute = () => {
  const {
    isAuthenticated,
    isLoading: isLoadingAuth,
    loginWithRedirect,
    user,
  } = useAuth0();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const { data: userData, isLoading: isLoadingUser } = useUser(user?.sub);
  const { data: channels, isLoading: isLoadingChannels } = useChannels(
    {
      user_id: user?.sub,
    },
    {
      enabled: !!userData,
    },
  );

  const { data: nangoConnections, isLoading: isLoadingNangoConnections } =
    useNangoConnections(user?.sub);
  // const { data: stripeData, isLoading: isLoadingStripeData } = useStripeData();

  const [connectedChannels, setConnectedChannels] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (
      isLoadingNangoConnections ||
      !nangoConnections ||
      !channels ||
      isLoadingChannels
      // || isLoadingStripeData
    ) {
      return;
    }

    const newConnectedChannels = channels.reduce((acc, channel) => {
      const isConnected = nangoConnections.some(
        (connection) =>
          connection.provider_config_key === channelsData[channel.type].integrationId,
      );
      acc[channel.type] = isConnected;
      return acc;
    }, {});
    setConnectedChannels(newConnectedChannels);
  }, [
    nangoConnections,
    isLoadingNangoConnections,
    channels,
    isLoadingChannels,
    // isLoadingStripeData,
  ]);

  useEffect(() => {
    window?.rewardful('ready', function () {
      if (window?.Rewardful?.referral) {
        console.log('Current referral ID: ', window?.Rewardful?.referral);
        // Optionally, store the referral ID in component state or context for later use
      }
    });
  });

  useEffect(() => {
    // if (
    //   isMobile &&
    //   !MOBILE_SUPPORTED &&
    //   import.meta.env.VITE_NODE_ENV !== 'development'
    // ) {
    //   return;
    // }

    if (!isLoadingAuth && !isAuthenticated) {
      return;
    }

    if (
      !isLoadingUser &&
      !isLoadingChannels &&
      !isLoadingNangoConnections &&
      user
      // &&
      // !isLoadingStripeData
    ) {
      setIsLoading(false); // Show onboarding

      if (
        userData?.onboarding_done &&
        channels?.length &&
        // stripeData?.subscription_id &&
        (userData?.fine_tune_job || userData?.fine_tune_model)
      ) {
        navigate('/'); // Navigate to dashboard
      } else {
        setIsLoading(false); // Show onboarding
      }
    }
  }, [
    isAuthenticated,
    isLoadingAuth,
    isLoadingUser,
    isLoadingChannels,
    user,
    userData,
    channels,
    navigate,
    loginWithRedirect,
    isLoadingNangoConnections,
    // isLoadingStripeData,
    // stripeData?.subscription_id,
  ]);

  useEffect(() => {
    if (!isLoading && userData && isAuthenticated) {
      Crisp.configure('6a975e2b-e56f-4161-b6ec-e3e13a870ebc');

      Crisp.setTokenId(userData?.user_id);
      Crisp.user.setEmail(userData?.email);
      Crisp.user.setNickname(userData?.name);
      Crisp.setZIndex(999999);

      Crisp.setHideOnMobile(false);
    } else {
      Crisp.setZIndex(-10);
    }
  }, [isAuthenticated, isLoading, userData]);

  // if (isMobile && !MOBILE_SUPPORTED && import.meta.env.VITE_NODE_ENV !== 'development') {
  //   return <ErrorMobile />;
  // }

  if (!isLoadingAuth && !isAuthenticated) {
    return <LoginPage loginWithRedirect={loginWithRedirect} />;
  }

  if (isLoading) {
    return <ScreenLoader />;
  }

  return (
    <Onboarding
      referral_id={window?.Rewardful?.referral}
      user={userData}
      connectedChannels={connectedChannels}
      // stripeData={stripeData}
    />
  );
};

export default OnboardingRoute;
export const Catch = () => {
  return <ErrorPage />;
};
