/* eslint-disable react/no-unescaped-entities */
import { useAuth0 } from '@auth0/auth0-react';
import { CircleComponent } from '@components/circle-component/CircleComponent';
import { LogoTextSVG } from '@images';
import errorIcon from '@images/error_icon.png';
import { useNavigate } from '@router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ErrorPage.module.scss';

interface ErrorPageProps {
  title?: string; // Optional custom title
  message?: string; // Optional custom error message
  errorCode?: string | number; // Optional error code (e.g., 404)
}

const ErrorPage: React.FC<ErrorPageProps> = ({ title, message, errorCode }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth0();
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     window.location.href = '/';
  //   }, 10000);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [navigate]);

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <div className={styles.buttonLogo} onClick={() => navigate(`/`)}>
          <LogoTextSVG width="90px" />
        </div>
      </header>

      <div className={styles.mainContainer}>
        <img src={errorIcon} alt={''} className={styles.icon} />

        <div className={styles.title}>
          {errorCode && title
            ? `Error ${errorCode} - ${title}`
            : title || t('error.defaultTitle')}
        </div>
        <div className={styles.description}>{message || t('error.defaultMessage')}</div>

        <button className={styles.button} onClick={() => navigate('/')}>
          {t('returnHomePage')}
        </button>
      </div>

      <footer className={styles.footer}>
        <span className={styles.footerText}>&copy; 2024 NSWR LTD.</span>
        <button onClick={() => logout()} className={styles.logoutButton}>
          {t('logout')}
        </button>
        {/* TODO <div>FR</div> Language switcher */}
      </footer>
    </div>
  );
};

export default ErrorPage;
