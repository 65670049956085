import { Comment, Reply } from '@axios/queries';
import { CommentComponent } from '@components/comment/CommentComponent';
import ChevronLeft from '@images/chevron-left.svg';
import ChevronRight from '@images/chevron-right.svg';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './TaskWidget.module.scss';

type TaskWidgetProps = {
  tasks: Comment[];
};

const TaskWidget = ({ tasks }: TaskWidgetProps) => {
  const { t } = useTranslation();

  const [itemIndex, setItemIndex] = useState(0);

  const [comment, setComment] = useState(tasks[itemIndex]);
  const [channel, setChannel] = useState(tasks[itemIndex]?.channel);
  const [post, setPost] = useState(tasks[itemIndex]?.post);

  const navigate = useNavigate();

  const handleNext = () => setItemIndex((current) => (current + 1) % tasks.length);
  const handlePrev = () =>
    setItemIndex((current) => (current - 1 + tasks.length) % tasks.length);

  useEffect(() => {
    setComment(tasks[itemIndex]);
    setChannel(tasks[itemIndex]?.channel);
    setPost(tasks[itemIndex]?.post);
  }, [itemIndex]);

  return (
    <>
      <div className={styles.titleContainer}>
        <div className={styles.titleAndButtons}>
          <p className={styles.title}>{t('dashboard.taskWidget.title')}</p>

          {tasks?.length > 0 && (
            <div className={styles.navigationButtons}>
              <button onClick={handlePrev} className={styles.navButton}>
                <img src={ChevronLeft} alt="" />
              </button>
              <button onClick={handleNext} className={styles.navButton}>
                <img src={ChevronRight} alt="" />
              </button>
            </div>
          )}
        </div>
        <div className={styles.valueContainer}>
          <span className={styles.value}>
            {tasks?.length} {t('dashboard.taskWidget.comment')}
            {tasks?.length == 1 ? '' : 's'}
          </span>
        </div>
      </div>

      {tasks?.length > 0 && (
        <div className={styles.taskContainer} onClick={() => navigate(`/task`)}>
          <CommentComponent comment={comment} channel={channel} post={post} />

          {/* TODO: Ref to the task */}
        </div>
      )}
    </>
  );
};

export default TaskWidget;
