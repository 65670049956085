// src/redux/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AdminState = {
  isAdmin: boolean;
  userSearch: string;
  // Add other user properties here
};

const initialState: AdminState = {
  isAdmin: false,
  userSearch: null,
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setIsAdmin: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
    },
    setUserSearch: (state, action: PayloadAction<string>) => {
      state.userSearch = action.payload;
    },
  },
});

export default adminSlice;
export const { setIsAdmin, setUserSearch } = adminSlice.actions;
