/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import '@mantine/charts/styles.css';
import '@mantine/core/styles.css';

import { useAuth0 } from '@auth0/auth0-react';
import { DailyStat, DailyStats, User, UsersActivity } from '@axios/queries';
import { LanguageSwitcher } from '@components';
import { PLANS } from '@components/onboarding/plan.config';
import { LogoTextSVG } from '@images';
import ArrowDown from '@images/arrow-down-s-line.svg';
import calendarIcon from '@images/calendar_icon.svg';
import globalIcon from '@images/global-line.svg';
import {
  Button,
  CheckIcon,
  Combobox,
  Group,
  Input,
  Menu,
  Pill,
  PillsInput,
  Select,
  Table,
  TextInput,
  Tooltip,
  useCombobox,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useAdminStore } from '@redux/store';
import { formatDate } from '@utils';
import Cookies from 'js-cookie';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// import { useNavigate } from 'react-router-dom';
import styles from './Admin.module.scss';

type AdminProps = {
  user: User;
  users: User[];
  userActivities: UsersActivity;
};

const Admin = ({ user, users, userActivities }: AdminProps) => {
  const { t } = useTranslation();
  const [toggleLanguageOpened, setToggleLanguageOpened] = useState(false);

  const columnDefinitions = [
    {
      key: 'email',
      label: 'Email',
      visible: true,
      render: (value) => value,
      sortable: true,
    },
    {
      key: 'name',
      label: 'Name',
      visible: true,
      render: (value) => value,
      sortable: true,
    },
    {
      key: 'user_id',
      label: 'User ID',
      visible: false,
      render: (value) => value,
      sortable: true,
    },
    {
      key: 'created_at',
      label: 'Created Date',
      visible: true,
      render: (value) =>
        value ? (
          <Tooltip
            openDelay={500}
            label={value}
            position="top"
            color="#667085"
            styles={{
              tooltip: {
                color: '#FFFFFF', // Custom text color
              },
            }}
          >
            <div>{formatDate(new Date(value), 'fr')}</div>
          </Tooltip>
        ) : (
          '-'
        ),
      sortable: true,
    },
    {
      key: 'last_login',
      label: 'Last Login',
      visible: true,
      sortable: true,
      render: (value) =>
        value ? (
          <Tooltip
            openDelay={500}
            label={value}
            position="top"
            color="#667085"
            styles={{
              tooltip: {
                color: '#FFFFFF', // Custom text color
              },
            }}
          >
            <div>{formatDate(new Date(value), 'fr')}</div>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      key: 'channels',
      label: 'Channels',
      visible: true,
      sortable: true,
      render: (value) => (value && value.length > 0 ? value.join(', ') : '-'),
      sortFunction: (a, b, direction) => {
        const lengthA = a.channels ? a.channels.length : 0;
        const lengthB = b.channels ? b.channels.length : 0;
        return direction === 'ascending' ? lengthA - lengthB : lengthB - lengthA;
      },
    },

    { key: 'fine_tune_model', label: 'Fine Tune Model', visible: false, sortable: true },
    {
      key: 'fine_tune_dataset',
      label: 'Fine Tune Dataset',
      visible: false,
      sortable: true,
    },
    { key: 'fine_tune_job', label: 'Fine Tune Job', visible: false, sortable: true },
    {
      key: 'fine_tune_date',
      label: 'Fine Tune Date',
      visible: false,
      sortable: true,
      render: (value) =>
        value ? (
          <Tooltip
            openDelay={500}
            label={value}
            position="top"
            color="#667085"
            styles={{
              tooltip: {
                color: '#FFFFFF', // Custom text color
              },
            }}
          >
            <div>{formatDate(new Date(value), 'fr')}</div>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      key: 'login',
      label: '# Logins',
      visible: true,
      sortable: true,
      render: (value) => value ?? '-',
      calculateSum: (dailyStats: DailyStats, dateRange) => {
        let [startDate, endDate] = dateRange;

        // Ensure startDate and endDate are set. If not, default them to cover all possible dates
        startDate = startDate || new Date(-8640000000000000); // Very past date
        endDate = endDate || new Date(8640000000000000); // Very future date

        // Adjust endDate to include the end of the day for the same-day range
        endDate = new Date(endDate);

        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);

        let sum = 0;
        Object.entries(dailyStats).forEach(([date, stats]) => {
          const statDate = new Date(date);
          if (statDate >= startDate && statDate <= endDate) {
            sum += stats.login ? 1 : 0;
          }
        });
        return sum;
      },
    },
    {
      key: 'sendReply',
      label: '# Reply Sent',
      visible: true,
      sortable: true,
      render: (value) => value ?? '-',
      calculateSum: (dailyStats: DailyStats, dateRange) => {
        let [startDate, endDate] = dateRange;

        // Ensure startDate and endDate are set. If not, default them to cover all possible dates
        startDate = startDate || new Date(-8640000000000000); // Very past date
        endDate = endDate || new Date(8640000000000000); // Very future date

        // Adjust endDate to include the end of the day for the same-day range
        endDate = new Date(endDate);
        endDate.setHours(23, 59, 59, 999);

        let sum = 0;
        Object.entries(dailyStats).forEach(([date, stats]) => {
          const statDate = new Date(date);
          if (statDate >= startDate && statDate <= endDate) {
            sum += stats.sendReply;
          }
        });
        return sum;
      },
    },
    {
      key: 'hideComment',
      label: '# Hidden Comment',
      visible: true,
      sortable: true,
      render: (value) => value ?? '-',
      calculateSum: (dailyStats: DailyStats, dateRange) => {
        let [startDate, endDate] = dateRange;

        // Ensure startDate and endDate are set. If not, default them to cover all possible dates
        startDate = startDate || new Date(-8640000000000000); // Very past date
        endDate = endDate || new Date(8640000000000000); // Very future date

        // Adjust endDate to include the end of the day for the same-day range
        endDate = new Date(endDate);
        endDate.setHours(23, 59, 59, 999);

        let sum = 0;
        Object.entries(dailyStats).forEach(([date, stats]) => {
          const statDate = new Date(date);
          if (statDate >= startDate && statDate <= endDate) {
            sum += stats.hideComment;
          }
        });
        return sum;
      },
    },
    {
      key: 'likeComment',
      label: '# Liked Comment',
      visible: true,
      sortable: true,
      render: (value) => value ?? '-',
      calculateSum: (dailyStats: DailyStats, dateRange) => {
        let [startDate, endDate] = dateRange;

        // Ensure startDate and endDate are set. If not, default them to cover all possible dates
        startDate = startDate || new Date(-8640000000000000); // Very past date
        endDate = endDate || new Date(8640000000000000); // Very future date

        // Adjust endDate to include the end of the day for the same-day range
        endDate = new Date(endDate);
        endDate.setHours(23, 59, 59, 999);

        let sum = 0;
        Object.entries(dailyStats).forEach(([date, stats]) => {
          const statDate = new Date(date);
          if (statDate >= startDate && statDate <= endDate) {
            sum += stats.likeComment;
          }
        });
        return sum;
      },
    },
    {
      key: 'skipTask',
      label: '# Skipped Tasks',
      visible: true,
      sortable: true,
      render: (value) => value ?? '-',
      calculateSum: (dailyStats: DailyStats, dateRange) => {
        let [startDate, endDate] = dateRange;

        // Ensure startDate and endDate are set. If not, default them to cover all possible dates
        startDate = startDate || new Date(-8640000000000000); // Very past date
        endDate = endDate || new Date(8640000000000000); // Very future date

        // Adjust endDate to include the end of the day for the same-day range
        endDate = new Date(endDate);
        endDate.setHours(23, 59, 59, 999);

        let sum = 0;
        Object.entries(dailyStats).forEach(([date, stats]) => {
          const statDate = new Date(date);
          if (statDate >= startDate && statDate <= endDate) {
            sum += stats.skipTask;
          }
        });
        return sum;
      },
    },
    {
      key: 'numberOfComments',
      label: '# Comments',
      visible: true,
      sortable: true,
      render: (value) => value ?? '-',
      calculateSum: (dailyStats: DailyStats, dateRange) => {
        let [startDate, endDate] = dateRange;

        // Ensure startDate and endDate are set. If not, default them to cover all possible dates
        startDate = startDate || new Date(-8640000000000000); // Very past date
        endDate = endDate || new Date(8640000000000000); // Very future date

        // Adjust endDate to include the end of the day for the same-day range
        endDate = new Date(endDate);
        endDate.setHours(23, 59, 59, 999);

        let sum = 0;
        Object.entries(dailyStats).forEach(([date, stats]) => {
          const statDate = new Date(date);
          if (statDate >= startDate && statDate <= endDate) {
            sum += stats.numberOfComments;
          }
        });
        return sum;
      },
    },
    {
      key: 'numberOfTasks',
      label: '# Tasks',
      visible: true,
      sortable: true,
      render: (value) => value ?? '-',
      calculateSum: (dailyStats: DailyStats, dateRange) => {
        let [startDate, endDate] = dateRange;

        // Ensure startDate and endDate are set. If not, default them to cover all possible dates
        startDate = startDate || new Date(-8640000000000000); // Very past date
        endDate = endDate || new Date(8640000000000000); // Very future date

        // Adjust endDate to include the end of the day for the same-day range
        endDate = new Date(endDate);
        endDate.setHours(23, 59, 59, 999);

        let sum = 0;
        Object.entries(dailyStats).forEach(([date, stats]) => {
          const statDate = new Date(date);
          if (statDate >= startDate && statDate <= endDate) {
            sum += stats.numberOfTasks;
          }
        });
        return sum;
      },
    },
    {
      key: 'numberOfReplies',
      label: '# Replies',
      visible: false,
      sortable: true,
      render: (value) => value ?? '-',
      calculateSum: (dailyStats: DailyStats, dateRange) => {
        let [startDate, endDate] = dateRange;

        // Ensure startDate and endDate are set. If not, default them to cover all possible dates
        startDate = startDate || new Date(-8640000000000000); // Very past date
        endDate = endDate || new Date(8640000000000000); // Very future date

        // Adjust endDate to include the end of the day for the same-day range
        endDate = new Date(endDate);
        endDate.setHours(23, 59, 59, 999);

        let sum = 0;
        Object.entries(dailyStats).forEach(([date, stats]) => {
          const statDate = new Date(date);
          if (statDate >= startDate && statDate <= endDate) {
            sum += stats.numberOfReplies;
          }
        });
        return sum;
      },
    },
    {
      key: 'numberOfRepliesByElon',
      label: '# Replies By Elon',
      visible: false,
      sortable: true,
      render: (value) => value ?? '-',
      calculateSum: (dailyStats: DailyStats, dateRange) => {
        let [startDate, endDate] = dateRange;

        // Ensure startDate and endDate are set. If not, default them to cover all possible dates
        startDate = startDate || new Date(-8640000000000000); // Very past date
        endDate = endDate || new Date(8640000000000000); // Very future date

        // Adjust endDate to include the end of the day for the same-day range
        endDate = new Date(endDate);
        endDate.setHours(23, 59, 59, 999);

        let sum = 0;
        Object.entries(dailyStats).forEach(([date, stats]) => {
          const statDate = new Date(date);
          if (statDate >= startDate && statDate <= endDate) {
            sum += stats.numberOfRepliesByElon;
          }
        });
        return sum;
      },
    },
    {
      key: 'tasksDone',
      label: '# Tasks Done',
      visible: false,
      sortable: true,
      render: (value) => value ?? '-',
      calculateSum: (dailyStats: DailyStats, dateRange) => {
        let [startDate, endDate] = dateRange;

        // Ensure startDate and endDate are set. If not, default them to cover all possible dates
        startDate = startDate || new Date(-8640000000000000); // Very past date
        endDate = endDate || new Date(8640000000000000); // Very future date

        // Adjust endDate to include the end of the day for the same-day range
        endDate = new Date(endDate);
        endDate.setHours(23, 59, 59, 999);

        let sum = 0;
        Object.entries(dailyStats).forEach(([date, stats]) => {
          const statDate = new Date(date);
          if (statDate >= startDate && statDate <= endDate) {
            sum += stats.tasksDone;
          }
        });
        return sum;
      },
    },
    {
      key: 'auto_hide',
      label: 'Auto Hide',
      visible: true,
      sortable: true,
      render: (value) => (value ? 'Yes' : 'No'),
    },
    {
      key: 'auto_like',
      label: 'Auto Like',
      visible: true,
      sortable: true,
      render: (value) => (value ? 'Yes' : 'No'),
    },
    {
      key: 'tokens_left',
      label: 'Tokens Left',
      visible: false,
      sortable: true,
    },
    {
      key: 'totalCostForUser',
      label: 'Cost',
      visible: true,
      sortable: true,
      render: (value) =>
        value ? (
          <Tooltip
            openDelay={500}
            label={value}
            position="top"
            color="#667085"
            styles={{
              tooltip: {
                color: '#FFFFFF', // Custom text color
              },
            }}
          >
            <div>{value.toFixed(2)} €</div>
          </Tooltip>
        ) : (
          '0.00 €'
        ),
      calculateSum: (dailyStats: DailyStats, dateRange) => {
        let [startDate, endDate] = dateRange;

        // Ensure startDate and endDate are set. If not, default them to cover all possible dates
        startDate = startDate || new Date(-8640000000000000); // Very past date
        endDate = endDate || new Date(8640000000000000); // Very future date

        // Adjust endDate to include the end of the day for the same-day range
        endDate = new Date(endDate);
        endDate.setHours(23, 59, 59, 999);

        let sum = 0;
        Object.entries(dailyStats).forEach(([date, stats]) => {
          const statDate = new Date(date);
          if (statDate >= startDate && statDate <= endDate) {
            sum += stats.totalDailyCost;
          }
        });
        return sum;
      },
    },

    {
      key: 'subscription_plan',
      label: 'Subscription Plan',
      visible: true,
      sortable: true,
      render: (value) => (value ? t(PLANS.find((p) => p.id == value)?.name) ?? '-' : '-'),
    },
    {
      key: 'billing_cycle',
      label: 'Billing Cycle',
      visible: false,
      sortable: true,
      render: (value) => value ?? '-',
    },

    {
      key: 'renewal_date',
      label: 'Renewal Date',
      visible: false,
      sortable: true,
      render: (value) =>
        value ? (
          <Tooltip
            openDelay={500}
            label={value}
            position="top"
            color="#667085"
            styles={{
              tooltip: {
                color: '#FFFFFF', // Custom text color
              },
            }}
          >
            <div>{formatDate(new Date(value), 'fr')}</div>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      key: 'subscription_id',
      label: 'Subscription Id',
      visible: false,
      sortable: true,
      render: (value) => value ?? '-',
    },

    {
      key: 'trial_end',
      label: 'Trial End',
      visible: true,
      sortable: true,
      render: (value) =>
        value ? (
          <Tooltip
            openDelay={500}
            label={value}
            position="top"
            color="#667085"
            styles={{
              tooltip: {
                color: '#FFFFFF', // Custom text color
              },
            }}
          >
            <div>{formatDate(new Date(value), 'fr')}</div>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      key: 'cancel_at',
      label: 'Canceled At',
      visible: false,
      sortable: true,
      render: (value) =>
        value ? (
          <Tooltip
            openDelay={500}
            label={value}
            position="top"
            color="#667085"
            styles={{
              tooltip: {
                color: '#FFFFFF', // Custom text color
              },
            }}
          >
            <div>{formatDate(new Date(value), 'fr')}</div>
          </Tooltip>
        ) : (
          '-'
        ),
    },

    {
      key: 'default_payment_method',
      label: 'Payment Method',
      visible: false,
      sortable: true,
      render: (value) => (value ? 'Yes' : 'No'),
    },
    {
      key: 'subscription_status',
      label: 'Subscription Status',
      visible: true,
      sortable: true,
      render: (value) => value ?? '-',
    },
    {
      key: 'hasAccess',
      label: 'Has Access',
      visible: false,
      render: (value) => (value ? 'Yes' : 'No'),
      sortable: true,
    },
  ];

  const MAX_DISPLAYED_VALUES = 2;

  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState('');

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 31);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    new Date(),
    new Date(),
  ]);

  const { isAuthenticated, logout } = useAuth0();

  // Initialize visible columns from column definitions
  const defaultVisibleColumns = columnDefinitions
    .filter((col) => col.visible)
    .map((col) => col.key);

  // State to manage visible columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>(defaultVisibleColumns);

  // Accessing actions
  const setUserId = useAdminStore((state) => state.setUserId);

  const usersActivitiesData = useMemo(() => {
    if (user?.is_admin && userActivities) {
      return userActivities.userActivitiesSummaries
        ?.filter(
          (u) =>
            (!u.email?.includes('demo') &&
              !u.email?.includes('test') &&
              !u.email?.includes('nswr')) ||
            u.email == user.email,
          // &&
          // u.onboarding_done &&
          // u.subscription_plan,
        )
        .map((u) => u);
    }
  }, [userActivities]);

  const filteredData = usersActivitiesData?.filter((user) => {
    // Text search filter
    const matchesSearchQuery =
      searchQuery.length === 0 ||
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (user.user_id.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (filter ? user.channels.includes(filter) : true));

    return matchesSearchQuery;
  });

  const filteredAndCalculatedData = useMemo(() => {
    return filteredData?.map((user) => {
      // Recalculate each stat for the user based on dateRange
      const recalculatedStats = {};
      columnDefinitions.forEach((column) => {
        if (column.calculateSum) {
          recalculatedStats[column.key] = column.calculateSum(user.dailyStats, dateRange);
        }
      });
      return { ...user, ...recalculatedStats };
    });
  }, [filteredData, dateRange]);

  // // Inside your Admin component
  // const handleChange = (selectedUserId) => {
  //   setUserId(selectedUserId); // Update the store with the selected user ID
  // };

  // Inside your Admin component
  const handleChange = (userId) => {
    // Construct a URL with the user ID as a parameter
    const userAccountUrl = `${window.location.origin}/?user_id=${userId}`;
    window.open(userAccountUrl, '_blank');
  };

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const [value, setValue] = useState<string[]>(defaultVisibleColumns);

  useEffect(() => {
    const savedColumns = Cookies.get('admin-columns');
    if (savedColumns) {
      setVisibleColumns(JSON.parse(savedColumns));
    }
  }, []);

  const handleValueSelect = (val: string) => {
    setVisibleColumns((currentVisibleColumns) => {
      const updatedColumns = currentVisibleColumns.includes(val)
        ? currentVisibleColumns.filter((column) => column !== val)
        : [...currentVisibleColumns, val];

      // Save to cookies
      Cookies.set('admin-columns', JSON.stringify(updatedColumns), { expires: 30 });

      return updatedColumns;
    });

    // Update the value state for Combobox selection
    setValue((current) =>
      current.includes(val) ? current.filter((v) => v !== val) : [...current, val],
    );
  };

  const handleValueRemove = (val: string) => {
    setVisibleColumns((currentVisibleColumns) => {
      const updatedColumns = currentVisibleColumns.filter((column) => column !== val);

      // Save to cookies
      Cookies.set('admin-columns', JSON.stringify(updatedColumns), { expires: 30 });

      return updatedColumns;
    });

    // Also update the value state if necessary
    setValue((current) => current.filter((v) => v !== val));
  };

  // Fonction pour ouvrir le portail client Stripe dans un nouvel onglet
  const handleManageSubscriptionClick = (customer_portal: string) => {
    window.open(customer_portal, '_blank');
  };

  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    const newSortConfig = { key, direction };

    // Update state
    setSortConfig(newSortConfig);

    // Save to cookies
    Cookies.set('admin-sort-config', JSON.stringify(newSortConfig), { expires: 30 });
  };

  useEffect(() => {
    const savedSortConfig = Cookies.get('admin-sort-config');
    if (savedSortConfig) {
      setSortConfig(JSON.parse(savedSortConfig));
    }
  }, []);

  const sortedData = useMemo(() => {
    if (!sortConfig.key) {
      return filteredAndCalculatedData;
    }
    return filteredAndCalculatedData
      ? [...filteredAndCalculatedData].sort((a, b) => {
          // Find the column definition based on the sortConfig key
          const column = columnDefinitions.find((c) => c.key === sortConfig.key);

          // Check for null values and sort them to the end
          const aValue = a[sortConfig.key];
          const bValue = b[sortConfig.key];
          const isANull = aValue === null || aValue === undefined;
          const isBNull = bValue === null || bValue === undefined;

          if (isANull && isBNull) return 0; // Both are null, keep original order
          if (isANull) return 1; // Only A is null, sort it to the end
          if (isBNull) return -1; // Only B is null, sort it to the end

          // Use the custom sortFunction if it exists
          if (column && column.sortFunction) {
            return column.sortFunction(a, b, sortConfig.direction);
          }

          // Default sorting logic for non-null values
          if (aValue < bValue) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        })
      : [];
  }, [filteredAndCalculatedData, sortConfig]);

  const values = value
    .slice(
      0,
      MAX_DISPLAYED_VALUES === value.length
        ? MAX_DISPLAYED_VALUES
        : MAX_DISPLAYED_VALUES - 1,
    )
    .map((item) => (
      <Pill key={item} withRemoveButton onRemove={() => handleValueRemove(item)}>
        {item}
      </Pill>
    ));

  const options = columnDefinitions.map((item) => (
    <Combobox.Option value={item.key} key={item.key} active={value.includes(item.key)}>
      <Group gap="sm">
        {value.includes(item.key) ? <CheckIcon size={12} /> : null}
        <span>{item.key}</span>
      </Group>
    </Combobox.Option>
  ));

  return (
    <div className={styles.root}>
      <header className={styles.headerContainer}>
        <LogoTextSVG width="90px" />
      </header>

      <div className={styles.rootContainer}>
        <div className={styles.container}>
          <div className={styles.filtersContainer}>
            <TextInput
              placeholder="Search by name, email, or user ID"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ flexGrow: 1 }}
              radius="md"
              size="md"
              styles={{
                label: {
                  marginBottom: '12px',
                  fontFamily: 'Plus Jakarta Sans, sans-serif',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  letterSpacing: '-0.006em',
                  textAlign: 'left',
                  color: '#0A0D14',
                },
                input: {
                  borderRadius: '10px',
                  gap: '8px',
                  width: '100%',
                  backgroundColor: '#FFFFFF',
                  border: '1px solid #E2E4E9',
                  boxShadow: '0px 1px 2px 0px #E4E5E73D',
                  fontFamily: 'Plus Jakarta Sans, sans-serif',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  letterSpacing: '-0.006em',
                  textAlign: 'left',
                  color: '#0A0D14',
                },
                description: {
                  lineHeight: '20px',
                },
              }}
            />

            {/* <Select
              placeholder="Filter by channel"
              data={['tiktok', 'facebook', 'instagram']}
              value={filter}
              onChange={setFilter}
              clearable
              radius="md"
              size="md"
              styles={{
                label: {
                  marginBottom: '12px',
                  fontFamily: 'Plus Jakarta Sans, sans-serif',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  letterSpacing: '-0.006em',
                  textAlign: 'left',
                  color: '#0A0D14',
                },
                input: {
                  borderRadius: '10px',
                  gap: '8px',
                  width: '100%',
                  backgroundColor: '#FFFFFF',
                  border: '1px solid #E2E4E9',
                  boxShadow: '0px 1px 2px 0px #E4E5E73D',
                  fontFamily: 'Plus Jakarta Sans, sans-serif',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  letterSpacing: '-0.006em',
                  textAlign: 'left',
                  color: '#0A0D14',
                },
                description: {
                  lineHeight: '20px',
                },
                dropdown: {
                  offset: 12,
                  padding: '10px 6px',
                  borderRadius: '8px',
                  background: '#FFFFFF',
                  boxShadow: '0px 40px 96px -8px #585C5F33',
                },
              }}
            /> */}

            <DatePickerInput
              leftSection={<img src={calendarIcon} alt="" />}
              locale="fr"
              maxDate={new Date()}
              minDate={thirtyDaysAgo}
              maxLevel="year"
              radius="md"
              size="md"
              type="range"
              allowSingleDateInRange
              placeholder={t('From...To...')}
              defaultValue={dateRange}
              value={dateRange}
              onChange={setDateRange}
              weekendDays={[7, 7] as any}
              style={{ width: '300px' }}
              popoverProps={{
                offset: 12,
                position: 'bottom-end',
                styles: {
                  dropdown: {
                    padding: '10px 6px',
                    borderRadius: '8px',
                    background: '#FFFFFF',
                    boxShadow: '0px 40px 96px -8px #585C5F33',
                  },
                },
              }}
              styles={{
                root: {
                  width: '200px',
                },
                label: {
                  marginBottom: '12px',
                  fontFamily: 'Plus Jakarta Sans, sans-serif',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  letterSpacing: '-0.006em',
                  textAlign: 'left',
                  color: '#0A0D14',
                },
                input: {
                  borderRadius: '10px',
                  gap: '8px',
                  width: '100%',
                  backgroundColor: '#FFFFFF',
                  border: '1px solid #E2E4E9',
                  boxShadow: '0px 1px 2px 0px #E4E5E73D',
                  fontFamily: 'Plus Jakarta Sans, sans-serif',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  letterSpacing: '-0.006em',
                  textAlign: 'left',
                  color: '#0A0D14',
                },
                description: {
                  lineHeight: '20px',
                },
              }}
            />

            <Combobox
              size="md"
              store={combobox}
              onOptionSubmit={handleValueSelect}
              withinPortal={false}
            >
              <Combobox.DropdownTarget>
                <PillsInput
                  pointer
                  size="md"
                  onClick={() => combobox.toggleDropdown()}
                  styles={{
                    root: { width: 350 },
                    input: {
                      paddingTop: '9px',
                      borderRadius: '10px',
                      gap: '8px',
                      width: '100%',
                      backgroundColor: '#FFFFFF',
                      border: '1px solid #E2E4E9',
                      boxShadow: '0px 1px 2px 0px #E4E5E73D',
                      fontFamily: 'Plus Jakarta Sans, sans-serif',
                      fontSize: '14px',
                      fontWeight: '600',
                      letterSpacing: '-0.006em',
                      textAlign: 'left',
                      color: '#0A0D14',
                    },
                  }}
                >
                  <Pill.Group size="md">
                    {value.length > 0 ? (
                      <>
                        {values}
                        {value.length > MAX_DISPLAYED_VALUES && (
                          <Pill>+{value.length - (MAX_DISPLAYED_VALUES - 1)} more</Pill>
                        )}
                      </>
                    ) : (
                      <Input.Placeholder>Choose columns</Input.Placeholder>
                    )}

                    <Combobox.EventsTarget>
                      <PillsInput.Field
                        type="hidden"
                        onBlur={() => combobox.closeDropdown()}
                        onKeyDown={(event) => {
                          if (event.key === 'Backspace') {
                            event.preventDefault();
                            handleValueRemove(value[value.length - 1]);
                          }
                        }}
                      />
                    </Combobox.EventsTarget>
                  </Pill.Group>
                </PillsInput>
              </Combobox.DropdownTarget>

              <Combobox.Dropdown
                styles={{
                  dropdown: {
                    offset: 12,
                    padding: '10px 6px',
                    borderRadius: '8px',
                    background: '#FFFFFF',
                    boxShadow: '0px 40px 96px -8px #585C5F33',
                    height: 350,
                    overflow: 'scroll',
                  },
                }}
              >
                <Combobox.Options>{options}</Combobox.Options>
              </Combobox.Dropdown>
            </Combobox>
          </div>

          <div className={styles.tableContainer}>
            <Table
              className={styles.fixedFirstColumn}
              verticalSpacing="md"
              horizontalSpacing="lg"
              style={{ tableLayout: 'auto' }}
              striped
              highlightOnHover
            >
              <Table.Thead>
                <Table.Tr className={styles.tableHead}>
                  {columnDefinitions.map(
                    (column, index) =>
                      visibleColumns.includes(column.key) && (
                        <Table.Th
                          key={column.key}
                          style={{ cursor: column.sortable ? 'pointer' : 'default' }}
                          onClick={() => column.sortable && handleSort(column.key)}
                        >
                          {column.label}
                          {sortConfig.key === column.key && (
                            <span style={{ marginLeft: '8px' }}>
                              {sortConfig.direction === 'ascending' ? '🔼' : '🔽'}
                            </span>
                          )}
                        </Table.Th>
                      ),
                  )}
                  <Table.Th key={'account'}></Table.Th>
                  <Table.Th key={'customerPortal'}></Table.Th>
                </Table.Tr>
              </Table.Thead>

              <tbody>
                {sortedData.map((user, index) => (
                  <Table.Tr
                    key={user.user_id}
                    className={index % 2 === 0 ? styles.evenRow : styles.oddRow}
                  >
                    {columnDefinitions.map(
                      (column) =>
                        visibleColumns.includes(column.key) && (
                          <Table.Td
                            key={`${user.user_id}-${column.key}`}
                            className={styles.tableCell}
                          >
                            {column.render
                              ? column.render(user[column.key])
                              : user[column.key]}
                          </Table.Td>
                        ),
                    )}

                    <Table.Td className={styles.tableCell}>
                      <div className={styles.buttonContainer}>
                        <button
                          className={styles.button}
                          onClick={() => handleChange(user.user_id)}
                        >
                          Account
                        </button>

                        <button
                          className={styles.buttonStripe}
                          style={{ marginLeft: 12 }}
                          onClick={() =>
                            handleManageSubscriptionClick(user.customer_portal)
                          }
                        >
                          Stripe User Portal
                        </button>
                      </div>
                    </Table.Td>
                  </Table.Tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <footer className={styles.footer}>
        <div
          style={{
            display: 'flex',
            gap: '24px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span className={styles.footerText}>&copy; 2024 NSWR LTD.</span>
          <div className={styles.dividerVertical}></div>

          <LanguageSwitcher user={user} />
        </div>

        <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
          <button onClick={() => logout()} className={styles.logoutButton}>
            {t('logout')}
          </button>
        </div>
      </footer>
    </div>
  );
};

export { Admin };
