/* eslint-disable max-len */
import { useHideComment, useLikeComment } from '@axios/mutations';
import { Comment } from '@axios/queries';
import ImageLoader from '@components/image-loader/ImageLoader';
import Dots from '@images/dots-horizontal.svg';
import Eye from '@images/eye-line.svg';
import EyeOff from '@images/eye-off-line.svg';
import LikeIcon from '@images/like.svg';
import LikeEmptyIcon from '@images/like_empty.svg';
import loaderBlueAnimation from '@images/LoaderBlue.json';
import { Button, Menu, rem, Text, Tooltip } from '@mantine/core';
import { formatTimeToNowShort } from '@utils/formatTimeToNowShort';
import Lottie from 'lottie-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './CommentHeader.module.scss';

type CommentHeaderProps = {
  interaction: Comment;
  selectedRef: React.RefObject<HTMLDivElement>;
  selectedId: string;
  onHide: (interaction: Comment) => void;
  onLike: (interaction: Comment) => void;
};

const CommentHeader = ({
  interaction,
  selectedRef,
  selectedId,
  onHide,
  onLike,
}: CommentHeaderProps) => {
  const isSelectedInteraction = interaction._id === selectedId;
  const [toggleMenuOpened, setToggleMenuOpened] = useState(false);
  const { t } = useTranslation();

  const [hidden, setHidden] = useState(interaction.hidden?.status);
  const [loadingHidding, setLoadingHidding] = useState(false);

  const [liked, setLiked] = useState(interaction.liked?.status);
  const [loadingLiking, setLoadingLiking] = useState(false);

  const { mutateAsync: hideComment, isLoading } = useHideComment();

  const { mutateAsync: likeComment } = useLikeComment();

  // Utilize the formatTimeToNow utility function for the timestamp
  const formattedTimestamp = useMemo(
    () => formatTimeToNowShort(interaction?.timestamp),
    [interaction?.timestamp],
  );

  const handleHideComment = async () => {
    setLoadingHidding(true);
    await hideComment({
      comment_id: interaction.comment_id,
      channel_id: interaction.channel_id,
      hide: !interaction.hidden?.status,
    }).then((reply) => {
      interaction.hidden = {
        status: !interaction.hidden?.status,
        timestamp: new Date().toISOString(),
      };

      setHidden(!hidden);
      setLoadingHidding(false);
    });

    onHide(interaction);
  };

  const handleLikeComment = async () => {
    setLoadingLiking(true);

    setLiked(!liked);

    await likeComment({
      comment_id: interaction.comment_id,
      channel_id: interaction.channel_id,
      like: !interaction.liked?.status,
    }).then(() => {
      interaction.liked = {
        status: !interaction.liked?.status,
        timestamp: new Date().toISOString(),
      };

      setLoadingLiking(false);
    });

    onLike(interaction);
  };

  useEffect(() => {
    setLiked(interaction.liked?.status);
  }, [interaction]);

  useEffect(() => {
    setHidden(interaction.hidden?.status);
  }, [hidden, setHidden, interaction]);

  return (
    <div
      className={`${styles.interactionItem}`}
      style={hidden ? { padding: '30px 12px 12px 12px' } : {}}
      ref={isSelectedInteraction ? selectedRef : null}
    >
      <div className={styles.contentContainer}>
        <ImageLoader
          className={styles.userAvatar}
          width={'48px'}
          height={'48px'}
          borderRadius={'50%'}
          src={
            interaction.profilePictureUrl ??
            `https://api.dicebear.com/7.x/micah/svg?seed=${
              interaction.username || interaction.comment_id
            }&flip=true&radius=50&baseColor=ac6651,f9c9b6,transparent&eyeShadowColor=d2eff3,e0ddff,ffeba4,ffedef,ffffff,transparent&backgroundColor=ffdfbf,d1d4f9,c0aede,b6e3f4,ffd5dc`
            // `https://api.dicebear.com/7.x/initials/svg?seed=${interaction.username}&radius=50&backgroundColor=00897b,00acc1,039be5,1e88e5,3949ab,43a047,5e35b1,7cb342,8e24aa,c0ca33,d81b60,e53935,f4511e,fb8c00,fdd835,ffb300,ffd5dc,ffdfbf&fontFamily=Arial&chars=1`
            // `https://api.dicebear.com/7.x/avataaars-neutral/svg?seed=${interaction.username}&radius=50&backgroundColor=d08b5b,edb98a,f8d25c,fd9841,ffdbb4,c0aede,b6e3f4,ffd5dc,ffdfbf,d1d4f9,ae5d29&eyebrows=default,defaultNatural,flatNatural,frownNatural,raisedExcited,raisedExcitedNatural,upDown,upDownNatural&eyes=closed,default,eyeRoll,happy,hearts,side,squint,surprised,wink,winkWacky&mouth=default,disbelief,eating,grimace,sad,screamOpen,serious,smile,tongue,twinkle,concerned`
          }
          src2={`https://api.dicebear.com/7.x/micah/svg?seed=${
            interaction.username || interaction.comment_id
          }&flip=true&radius=50&baseColor=ac6651,f9c9b6,transparent&eyeShadowColor=d2eff3,e0ddff,ffeba4,ffedef,ffffff,transparent&backgroundColor=ffdfbf,d1d4f9,c0aede,b6e3f4,ffd5dc`}
          alt=""
        />

        <div className={styles.content}>
          {interaction.username && (
            <div className={styles.containerCommentDetails}>
              <div className={styles.username}>@{interaction.username}</div>

              <span className={styles.date}>
                {/* {formatDistanceToNow(new Date(interaction.timestamp), {
                addSuffix: true,
              })} */}
                {formattedTimestamp}
              </span>
            </div>
          )}

          <div className={styles.containerHidden}>
            {hidden && <div className={styles.hiddenLabel}>{t('hiddenComment')}</div>}

            {interaction.channel?.type != 'instagram' &&
              !interaction.post?.mediaType.includes('ADS') && (
                <button
                  onClick={handleLikeComment}
                  disabled={loadingLiking}
                  className={styles.likedButton}
                >
                  <img
                    style={{ width: '20px', height: '20px' }}
                    src={liked ? LikeIcon : LikeEmptyIcon}
                    alt=""
                  />
                </button>
              )}

            <Tooltip
              openDelay={500}
              label={hidden ? t('showComment') : t('hideComment')}
              position="top"
              color="#667085"
              styles={{
                tooltip: {
                  color: '#FFFFFF', // Custom text color
                },
              }}
            >
              <button
                onClick={handleHideComment}
                disabled={loadingHidding}
                className={styles.hiddenButton}
              >
                {loadingHidding ? (
                  <Lottie
                    animationData={loaderBlueAnimation}
                    loop={true}
                    style={{
                      width: 18,
                      height: 18,
                    }}
                  />
                ) : (
                  <img
                    style={{ width: '20px', height: '20px' }}
                    src={hidden ? Eye : EyeOff}
                    alt=""
                  />
                )}
              </button>
            </Tooltip>

            {/* <Menu
              opened={toggleMenuOpened}
              onChange={setToggleMenuOpened}
              shadow="md"
              position="bottom-end"
              offset={2}
              styles={{
                dropdown: {
                  padding: '10px 6px',
                  borderRadius: '8px',
                  background: '#FFFFFF',
                  boxShadow: '0px 40px 96px -8px #585C5F33',
                },
                label: {
                  marginBottom: '6px',
                  fontFamily: 'Plus Jakarta Sans, sans-serif',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  letterSpacing: '-0.006em',
                  textAlign: 'left',
                  color: '#525866',
                },
                item: {
                  // marginTop: '6px',
                  fontFamily: 'Plus Jakarta Sans, sans-serif',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '-0.006em',
                  textAlign: 'left',
                },
              }}
            >
              <Menu.Target>
                <button
                  onClick={() => setToggleMenuOpened((o) => !o)}
                  className={styles.settingsButton}
                  disabled={loadingHidding}
                >
                  {loadingHidding ? (
                    <Lottie
                      animationData={loaderBlueAnimation}
                      loop={true}
                      style={{
                        width: 18,
                        height: 18,
                        marginLeft: '10px',
                      }}
                    />
                  ) : (
                    <img src={Dots} alt="" width={18} />
                  )}
                </button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  color="#101828"
                  leftSection={
                    <img
                      style={{ width: '20px', height: '20px' }}
                      src={hidden ? Eye : EyeOff}
                      alt=""
                    />
                  }
                  onClick={handleHideComment}
                >
                  {hidden ? t('showComment') : t('hideComment')}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu> */}
          </div>
          <div className={styles.textComment}>
            {interaction?.text?.split('\n').map((line, index, array) => (
              <React.Fragment key={index}>
                {line}
                {index < array.length - 1 && <br />}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { CommentHeader };
