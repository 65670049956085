import { useAxiosInstance } from '@axios/useAxiosInstance';
import { QueryFunction, useQuery, UseQueryOptions } from '@tanstack/react-query';

type StripeSubscriptionStatus = {
  hasAccess: boolean;
  message: string;
};

const useStripeSubscriptionStatus = (
  options?: Omit<
    UseQueryOptions<
      StripeSubscriptionStatus,
      Error,
      StripeSubscriptionStatus,
      [string, string]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  },
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const fetchStripeSubscriptionStatus: QueryFunction<
    StripeSubscriptionStatus,
    [string, string]
  > = async () => {
    const instance = await getAxiosInstance();

    return instance
      .get('/api/stripe/stripe-subscription-status')
      .then((response) => response.data);
  };

  const queryKey: [string, string] = ['stripeSubscriptionStatus', ''];

  return useQuery<
    StripeSubscriptionStatus,
    Error,
    StripeSubscriptionStatus,
    [string, string]
  >(queryKey, fetchStripeSubscriptionStatus, {
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    ...options,
    onSuccess:
      options?.onSuccess ||
      ((data) => {
        if (import.meta.env.VITE_PRINT_SUCCESSFUL_ENDPOINTS === 'true') {
          console.log('StripeSubscriptionStatus data fetched successfully!', data);
        }
      }),
    onError:
      options?.onError ||
      ((error) => {
        console.error('Error fetching StripeSubscriptionStatus:', error);
      }),

    enabled: options?.enabled && authenticated,
  });
};

export type { StripeSubscriptionStatus };
export { useStripeSubscriptionStatus };
