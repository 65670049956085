import { History, Message } from '@axios/queries';
import { useAxiosInstance } from '@axios/useAxiosInstance';
import { MutationFunction, useMutation, UseMutationOptions } from '@tanstack/react-query';

// Define the input type for creating a subscription
type ChatWithElonInput = {
  messages: Message[];
};

// Update the return type for the subscription according to your new endpoint
type ChatWithElonOutput = {
  instruction: string;
  message: string;
  action: 'instruction' | 'discussion';
};

const useChatWithElon = (
  options?: Omit<
    UseMutationOptions<ChatWithElonOutput, Error, ChatWithElonInput>,
    'mutationFn'
  >,
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  // Mutation function to create a subscription
  const chatWithElon: MutationFunction<ChatWithElonOutput, ChatWithElonInput> = async (
    input: ChatWithElonInput,
  ) => {
    console.log('INPUT:', input);
    if (!authenticated) {
      throw new Error('User must be authenticated to update message');
    }

    const instance = await getAxiosInstance();
    return instance
      .post('/api/assistant/chat-with-elon', input)
      .then((response) => response.data);
  };

  // Use the mutation with React Query
  return useMutation<ChatWithElonOutput, Error, ChatWithElonInput>(chatWithElon, {
    ...options,
    onSuccess:
      options?.onSuccess ||
      ((data) => {
        if (import.meta.env.VITE_PRINT_SUCCESSFUL_ENDPOINTS === 'true') {
          console.log('Update message successfully created!', data);
        }
      }),
    onError:
      options?.onError ||
      ((error, input) => {
        console.error('Error creating update message:', input, error);
      }),
  });
};

export { useChatWithElon };
export type { ChatWithElonInput, ChatWithElonOutput };
