/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import { useNewChannel } from '@axios/mutations';
import {
  FacebookPage,
  InstagramAccount,
  useChannels,
  useFacebookPages,
  useInstagramAccounts,
  useNangoConnections,
  useTiktokAccounts,
} from '@axios/queries';
import { ScreenLoader } from '@components/screen-loader/ScreenLoader';
import { useNango } from '@hooks';
import arrowRight from '@images/arrow-right-s-line.svg';
// import backButton from '@images/back_btn.svg';
import checkFill from '@images/check-fill.svg';
import InstagramSVG from '@images/instagram.svg';
import loaderAnimation from '@images/loader.json';
import loaderBlueAnimation from '@images/LoaderBlue.json';
import Nango from '@nangohq/frontend';
import { Crisp } from 'crisp-sdk-web';
import Lottie from 'lottie-react';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import ChoosePage from './choose-page/ChoosePage';
import channelsData from './connectChannel.config';
import styles from './ConnectChannels.module.scss';

interface ConnectChannelsProps {
  onStepChange: (nextStep: number) => void;
  selectedChannels: string[];
  alreadyConnectedChannels: Record<string, boolean>;
  user_id: string;
}

const ConnectChannels: React.FC<ConnectChannelsProps> = ({
  onStepChange,
  selectedChannels,
  user_id,
  alreadyConnectedChannels,
}) => {
  const publicKey = import.meta.env.VITE_NANGO_PUBLIC_KEY;

  const { t } = useTranslation();

  const [isLoadingConfirmButton, setIsLoadingConfirmButton] = useState(false);

  const [showLoader, setShowLoader] = useState(false); // Add this line to manage loader visibility
  const [allowToGo, setAllowToGo] = useState(
    alreadyConnectedChannels && Object.keys(alreadyConnectedChannels).length > 0,
  );
  const [confirmClicked, setConfirmClicked] = useState(false);

  const [connectedChannels, setConnectedChannels] = useState<Record<string, boolean>>(
    alreadyConnectedChannels,
  );
  const [showChoosePage, setShowChoosePage] = useState(null);
  const [isLoadingConnection, setIsLoadingConnection] = useState<Record<string, boolean>>(
    {},
  ); // Change to track loading state by channel

  const { connectWithNango } = useNango();
  const { data: nangoConnections, isLoading: isLoadingNangoConnections } =
    useNangoConnections(user_id);

  const { mutateAsync: newChannel } = useNewChannel();

  const {
    data: instagramPages,
    isLoading: isLoadingInstagramAccounts,
    refetch: fetchInstagramAccounts,
  } = useInstagramAccounts(user_id, { enabled: false });

  const {
    data: facebookPages,
    isLoading: isLoadingFacebookPages,
    refetch: fetchFacebookPages,
  } = useFacebookPages(user_id, { enabled: false });

  const {
    data: tiktokAccounts,
    isLoading: isLoadingTiktokAccounts,
    refetch: fetchTiktokAccounts,
  } = useTiktokAccounts(user_id, { enabled: false });

  const {
    data: channels,
    isLoading: isLoadingchannels,
    refetch: fetchChannels,
  } = useChannels({ user_id: user_id });

  useEffect(() => {
    const lastExecution = localStorage.getItem('helpConnectMessage');
    const now = new Date();

    if (!lastExecution || now.getTime() - new Date(lastExecution).getTime() > 86400000) {
      // 86400000 milliseconds in a day
      const timer = setTimeout(() => {
        console.log('PUSH EVENT');
        Crisp.message.showText(t('helpConnectMessage'));
        // Crisp.session.pushEvent('connect-channel');
        // Crisp.trigger.run('connect-channel');

        // Update last execution date in local storage
        localStorage.setItem('helpConnectMessage', now.toISOString());
      }, 20000); // 10000 milliseconds = 10 seconds

      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
  }, []); // Dependency array remains empty, so this effect runs only once after the initial render

  useEffect(() => {
    void fetchChannels();
    if (
      isLoadingNangoConnections ||
      !nangoConnections ||
      !channels ||
      isLoadingchannels
    ) {
      return;
    }
    const newConnectedChannels = channels.reduce((acc, channel) => {
      const isConnected = nangoConnections.some(
        (connection) =>
          connection.provider_config_key === channelsData[channel.type].integrationId,
      );
      acc[channel.type] = isConnected;
      return acc;
    }, {});
    setConnectedChannels(newConnectedChannels);
  }, [
    nangoConnections,
    isLoadingNangoConnections,
    channels,
    isLoadingchannels,
    fetchChannels,
  ]);

  const requiresAdditionalSteps = (channelKey) => {
    // Define logic to determine if a channel requires additional steps
    return channelKey === 'instagram' || channelKey === 'facebook';
  };

  const handleConnect = useCallback(
    async (channelKey) => {
      setIsLoadingConnection((prev) => ({ ...prev, [channelKey]: true })); // Set loading true for specific channel

      try {
        // if (isMobile) {
        //   window.open(
        //     `https://app.nango.dev/auth-link?public_key=${publicKey}&integration_unique_key=${channelsData[channelKey].integrationId}&connection_id=${user_id}`,
        //   );
        // } else {
        await connectWithNango(channelsData[channelKey].integrationId, user_id);
        // }

        // if (channelKey == 'facebook' || channelKey == 'instagram') {
        //   // Call Facebook login logic
        //   loginWithFacebook(
        //     async (accessToken) => {
        //       console.log('Facebook Access Token:', accessToken);
        //       // Proceed with fetching Facebook pages or other logic
        //       await fetchFacebookPages().then(() => {
        //         setShowChoosePage(channelKey);
        //       });
        //       setIsLoadingConnection((prev) => ({ ...prev, [channelKey]: false }));
        //       setConnectedChannels((prev) => ({ ...prev, [channelKey]: true }));
        //       setAllowToGo(true);
        //     },
        //     () => {
        //       // Error handling
        //       console.error('Facebook login failed');
        //       setIsLoadingConnection((prev) => ({ ...prev, [channelKey]: false }));
        //     },
        //   );
        //   return;
        // }

        // After successful connection
        if (requiresAdditionalSteps(channelKey)) {
          if (channelKey == 'instagram') {
            await fetchInstagramAccounts().then(() => {
              setShowChoosePage('instagram');
            });
          } else if (channelKey == 'facebook') {
            await fetchFacebookPages().then(() => {
              setShowChoosePage('facebook');
            });
          }
        } else {
          if (channelKey == 'tiktok') {
            await fetchTiktokAccounts().then(async (data) => {
              console.log('data:', data);
              // Logic to update the database with the selected page
              await newChannel({
                channel_id: data?.data?.business_id,
                type: 'tiktok',
              });
            });
          }

          setIsLoadingConnection((prev) => ({ ...prev, [channelKey]: false })); // Set loading false for specific channel
          setConnectedChannels((prev) => ({ ...prev, [channelKey]: true }));
          setAllowToGo(true);
        }
      } catch (error) {
        console.error(`Error connecting to ${channelKey}:`, error);
      } finally {
        setIsLoadingConnection((prev) => ({ ...prev, [channelKey]: false })); // Set loading false for specific channel
      }
    },
    [
      connectWithNango,
      user_id,
      fetchInstagramAccounts,
      fetchFacebookPages,
      fetchTiktokAccounts,
      newChannel,
    ],
  );

  const handlePageConfirm = useCallback(
    async (page: InstagramAccount | FacebookPage) => {
      try {
        // Logic to update the database with the selected page
        await newChannel({
          channel_id: page.id,
          type: showChoosePage,
        });
        setConnectedChannels((prev) => ({ ...prev, [`${showChoosePage}`]: true }));
        setAllowToGo(true);
      } catch (error) {
        console.error();
      } finally {
        // Update the loading state based on the type of page
        if (showChoosePage === 'instagram') {
          setIsLoadingConnection({ instagram: false });
        } else if (showChoosePage === 'facebook') {
          setIsLoadingConnection({ facebook: false });
        }

        setShowChoosePage(null);
      }
    },
    [newChannel, showChoosePage],
  );

  const handlePageCancel = useCallback(() => {
    // Logic to cancel the Nango connection
    console.log('Cancelling Nango connection');
    if (showChoosePage === 'instagram') {
      setIsLoadingConnection({ instagram: false }); // Set loading false for specific channel
    } else if (showChoosePage === 'facebook') {
      setIsLoadingConnection({ facebook: false }); // Set loading false for specific channel
    }
    // Call a function or API to cancel the Nango connection
    // TODO delete? connection in Nango -- not sure with the current logic (si pas ds la db, demande de se reconnecter)
    setShowChoosePage(null);
  }, []);

  const isAnyChannelConnected = Object.values(connectedChannels).some((status) => status);

  const handleConfirm = () => {
    if (isAnyChannelConnected) {
      setShowLoader(true); // Show the loader
      setConfirmClicked(true);
    }
  };

  const openCrisp = () => {
    // Crisp.message.send('text', t('needHelpToConnect'));
    Crisp.chat.open();
  };

  useEffect(() => {
    let isComponentMounted = true;

    console.log('ALLOW TO GO:', allowToGo);
    console.log('confirmClicked:', confirmClicked);

    // This effect reacts to changes in both allowToGo and confirmClicked.
    if (allowToGo && confirmClicked) {
      setConfirmClicked(false); // Reset confirmClicked state for potential future use
      onStepChange(5); // Move to step 5 immediately

      // Optionally delay hiding the loader until after a short timeout
      // to ensure the page transition starts first.
      setTimeout(() => {
        if (isComponentMounted) {
          setShowLoader(false);
          setConfirmClicked(false); // Reset confirmClicked state if needed.
        }
      }, 500); // This timeout is just a short delay to ensure any state updates happen after navigation if at all.
    }

    return () => {
      isComponentMounted = false; // Ensures no state updates if component unmounts.
    };
  }, [allowToGo, confirmClicked]);

  // if (showLoader) {
  //   return <ScreenLoader message={t('loader.fineTuneModel')} />;
  // }

  if (showChoosePage && (!isLoadingInstagramAccounts || !isLoadingFacebookPages)) {
    return (
      <ChoosePage
        networkAccounts={showChoosePage === 'instagram' ? instagramPages : facebookPages}
        onConfirmPage={handlePageConfirm}
        onCancel={handlePageCancel}
        channel={showChoosePage}
      />
    );
  }

  return (
    <div className={styles.connectChannelsContainer}>
      {/* <button onClick={handleReturn} className={styles.returnButton}>
        <img src={backButton} alt="" />
      </button> */}

      <div className={styles.titleContainer}>
        <h2 className={styles.title}>{t('onboarding.connectChannel.title')}</h2>
        <div className={styles.description}>
          {t('onboarding.connectChannel.description')}

          <button onClick={openCrisp} className={styles.descriptionHelp}>
            {t('onboarding.connectChannel.description 2')}
          </button>
        </div>
      </div>

      <div className={styles.channelContainer}>
        {/* <p className={styles.chooseNetwork}>
          {t('onboarding.connectChannel.chooseNetwork')}
        </p> */}

        <div className={styles.connectionButtonsContainer}>
          {selectedChannels.map((channel) => (
            <button
              key={channel}
              className={styles.connectionButton}
              onClick={async () => {
                await handleConnect(channel);
              }}
              disabled={connectedChannels[channel]}
            >
              <img
                src={channelsData[channel].image}
                alt={''}
                className={styles.channelLogo}
              />
              <div className={styles.channelInfoContainer}>
                <div className={styles.channelText}>
                  <h3 className={styles.channelTitle}>{channelsData[channel].name}</h3>
                  {t(channelsData[channel].description) &&
                    !isLoadingConnection[channel] && (
                      <p className={styles.channelDescription}>
                        {t(channelsData[channel].description)}{' '}
                        {channelsData[channel].urlTuto && '('}
                        {channelsData[channel].urlTuto && (
                          <a
                            href={channelsData[channel].urlTuto}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.tutorialLink}
                            onClick={(e) => e.stopPropagation()} // Empêcher l'événement de remonter
                          >
                            {t('onboarding.connectChannel.knowMore')}
                          </a>
                        )}
                        {channelsData[channel].urlTuto && ')'}
                      </p>
                    )}

                  {isLoadingConnection[channel] && (
                    <p className={styles.channelDescription}>
                      {t('connectChannel.popupHelped')}
                    </p>
                  )}
                </div>

                {isLoadingConnection[channel] ? (
                  <Lottie
                    className={styles.checkFill}
                    animationData={loaderBlueAnimation}
                    loop={true}
                    style={{ width: 16, height: 16, flexShrink: 0 }}
                  />
                ) : connectedChannels[channel] ? (
                  connectedChannels[channel] && (
                    <img src={checkFill} alt="" className={styles.checkFill} />
                  )
                ) : (
                  <img src={arrowRight} alt="" className={styles.arrowRight} />
                )}
              </div>
            </button>
          ))}
        </div>
      </div>

      <button
        onClick={() => {
          setIsLoadingConfirmButton(true);
          handleConfirm();
        }}
        className={styles.confirmButton}
        disabled={!isAnyChannelConnected}
      >
        {t('onboarding.connectChannel.Continue')}
        {isLoadingConfirmButton && (
          <Lottie
            animationData={loaderAnimation}
            loop={true}
            style={{ width: 24, height: 24, marginLeft: '8px' }}
          />
        )}
      </button>
    </div>
  );
};

export default ConnectChannels;
