import { useAuth0 } from '@auth0/auth0-react';
import loaderAnimation from '@images/loader.json';
// import animationData from '@images/LoaderElon.json';
import animationData from '@images/loaderWithoutShadow.json';
import Lottie from 'lottie-react';
import lottie from 'lottie-web';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ScreenLoader.module.scss';

type screenLoaderProps = {
  message?: string;
};

const ScreenLoader = ({ message }: screenLoaderProps) => {
  const animationContainer = useRef<HTMLDivElement>(null);
  const { isAuthenticated, logout } = useAuth0();
  const { t } = useTranslation();

  useEffect(() => {
    if (!animationContainer.current) {
      return;
    }

    const animation = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    });

    const handleAnimationLoaded = () => {
      const svgElement = animationContainer.current?.querySelector('svg');
      if (svgElement) {
        svgElement.removeAttribute('preserveAspectRatio');
        svgElement.setAttribute('preserveAspectRatio', 'xMidYMid slice');
      }
    };

    // Correctly add the event listener
    animation.addEventListener('DOMLoaded', handleAnimationLoaded);

    // Schedule the page refresh after 5 seconds
    const timeoutId = setTimeout(() => {
      window.location.reload();
    }, 60000);

    // Cleanup function
    return () => {
      // Remove the event listener if the animation object exists and has the method available
      if (animation && typeof animation.removeEventListener === 'function') {
        animation.removeEventListener('DOMLoaded', handleAnimationLoaded);
      }
      animation.destroy(); // This is fine as destroy method exists on the animation object

      // Clear the timeout to prevent it from executing if the component unmounts
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.message}>
        {message ?? t('loader.defaultMessage')}
        <Lottie
          animationData={loaderAnimation}
          loop={true}
          style={{ width: 24, height: 24, marginLeft: '10px', marginTop: '0px' }}
        />
      </div>
      <button onClick={() => logout()} className={styles.logoutButton}>
        {t('logout')}
      </button>
      <div className={styles.root} ref={animationContainer}></div>
    </div>
  );
};

export { ScreenLoader };
