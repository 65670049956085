import { User } from '@axios/queries';
import { useAxiosInstance } from '@axios/useAxiosInstance';
import { MutationFunction, useMutation, UseMutationOptions } from '@tanstack/react-query';

type CompleteOnboardingInput = {
  user_id?: string;
};

type CompleteOnboardingOutput = {
  fineTuneJob: boolean;
};

const useCompleteOnboarding = (
  options?: Omit<
    UseMutationOptions<CompleteOnboardingOutput, Error, CompleteOnboardingInput>,
    'mutationFn'
  >,
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const completeOnboarding: MutationFunction<
    CompleteOnboardingOutput,
    CompleteOnboardingInput
  > = async (input: CompleteOnboardingInput) => {
    if (!authenticated) {
      return;
    }

    const instance = await getAxiosInstance();

    return instance
      .post('/api/assistant/onboarding-done', input)
      .then((response) => response.data);
  };

  return useMutation<CompleteOnboardingOutput, Error, CompleteOnboardingInput>(
    completeOnboarding,
    {
      ...options,
      onSuccess:
        options?.onSuccess ||
        ((data) => {
          if (import.meta.env.VITE_PRINT_SUCCESSFUL_ENDPOINTS === 'true') {
            console.log('User Complete onboarding successfully!', data);
          }
        }),
      onError:
        options?.onError ||
        ((input, error) => {
          console.error('Error completing onboarding User:', input, error);
        }),
    },
  );
};

export { useCompleteOnboarding };
