import { useAxiosInstance } from '@axios/useAxiosInstance';
import { QueryFunction, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { User } from './useUser';

type DailyStat = {
  login: boolean;
  hideComment: number;
  sendReply: number;
  likeComment: number;
  skipTask: number;
  numberOfComments: number;
  numberOfTasks: number;
  numberOfReplies: number;
  numberOfRepliesByElon: number;
  tasksDone: number;
  costs: Cost[];
  totalDailyCost: number;
  costDetailsByAction: { [action: string]: Cost };
};

type DailyStats = {
  [date: string]: DailyStat;
};

type Action =
  | 'generateReply'
  | 'updateReply-1'
  | 'updateReply-2'
  | 'chatWithElon'
  | 'evaluateCommentRelevance'
  | 'evaluateCommentForReplyOrIgnore'
  | 'evaluateReplyForUseOrIgnore'
  | 'transcribeVideo'
  | 'createFineTuneModel'
  | 'classifyComment'
  | 'generateContextSummary'
  | 'evaluateSocialMediaInteraction'
  | 'classifySocialMediaUser';

type Cost = {
  _id: string;
  user_id: string;
  action: Action;
  details?: string;
  cost: number;
  timestamp: Date;
};

type Costs = {
  [date: string]: Cost;
};

type UsersActivity = {
  userActivitiesSummaries: {
    user_id: string;
    name: string;
    email: string;
    last_login: string;
    channels: string[];
    fine_tune_model: string;
    fine_tune_dataset: number;
    fine_tune_date: string;
    fine_tune_job: string;
    auto_hide: boolean;
    auto_like: boolean;
    dailyStats: DailyStats;
    totalCostPerAction: { [action: string]: number };
    totalCostForUser: number;
    customer_portal: string;
    renewal_date: string;
    subscription_id: string;
    trial_end: string;
    cancel_at: string;
    default_payment_method: boolean;
    subscription_status: string;
    hasAccess: boolean;
    created_at: string;
    tokens_left: number;
  }[];
  grandTotalCost: number;
  globalActionSummary: { [action: string]: number };
};

const useUserActivities = (
  user_id: string,
  options?: Omit<
    UseQueryOptions<UsersActivity, Error, UsersActivity, [string]>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  },
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const fetchUserActivities: QueryFunction<UsersActivity, [string]> = async () => {
    const instance = await getAxiosInstance();

    return instance
      .get(`/api/admin/user-activities?${user_id ? `user_id=${user_id}` : ''}`)
      .then((response) => response.data);
  };

  const queryKey: [string] = ['user-activities'];

  return useQuery<UsersActivity, Error, UsersActivity, [string]>(
    queryKey,
    fetchUserActivities,
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options,
      onSuccess:
        options?.onSuccess ||
        ((data) => {
          if (import.meta.env.VITE_PRINT_SUCCESSFUL_ENDPOINTS === 'true') {
            console.log('UserActivities data fetched successfully!', data);
          }
        }),
      onError:
        options?.onError ||
        ((error) => {
          console.error('Error fetching user data:', error);
        }),
      enabled: options?.enabled && authenticated,
    },
  );
};
export type { Action, Cost, Costs, DailyStat, DailyStats, UsersActivity };
export { useUserActivities };
