import { checkDataExists } from './checkDataExists';

const formatNumber = (num: number) => {
  if (!num) {
    return 0;
  }
  // Convert number to string to work with its characters
  const numStr = num.toString();

  if (num < 1000) {
    // If the number is less than 1000, return it as is
    return numStr;
  } else if (num >= 1000 && num < 1000000) {
    // If the number is in the thousands, format it with "K"
    const thousands = numStr.slice(0, -3); // Get all but the last three digits
    const remainder = numStr.slice(-3, -2); // Get the hundred's place for partial thousand
    return `${thousands}K${remainder}`;
  } else {
    // If the number is in the millions, format it with "M"
    const millions = numStr.slice(0, -6); // Get all but the last six digits
    const remainder = numStr.slice(-6, -5); // Get the hundred thousand's place for partial million
    return `${millions}M${remainder}`;
  }
};

export { formatNumber };
