import { useAxiosInstance } from '@axios/useAxiosInstance';
import { QueryFunction, useQuery, UseQueryOptions } from '@tanstack/react-query';

type InstagramAccount = {
  name: string;
  followers_count: number;
  follows_count: number;
  biography: string;
  profile_picture_url: string;
  username: string;
  media_count: number;
  website: string;
  id: string;
};

const useInstagramAccounts = (
  user_id: string,
  options?: Omit<
    UseQueryOptions<InstagramAccount[], Error, InstagramAccount[], [string, string]>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  },
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const fetchInstagramAccounts: QueryFunction<
    InstagramAccount[],
    [string, string]
  > = async () => {
    const instance = await getAxiosInstance();

    return instance
      .get('api/instagram/accounts', { params: { user_id } })
      .then((response) => response.data);
  };

  return useQuery<InstagramAccount[], Error, InstagramAccount[], [string, string]>(
    ['instagram-accounts', user_id],
    fetchInstagramAccounts,
    {
      ...options,
      onSuccess:
        options?.onSuccess ||
        ((data) => {
          console.log('Instagram Accounts fetched successfully!', data);
        }),
      onError:
        options?.onError ||
        ((error) => {
          console.error('Error fetching Instagram Accounts:', error);
        }),
    },
  );
};

export { useInstagramAccounts };
export type { InstagramAccount };
