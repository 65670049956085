import { StripeData, User } from '@axios/queries';
import ArrowIcon from '@images/arrow-right-s-line-blue.svg'; // Update with your actual import path
import { formatDate } from '@utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ChoosePlanPopup from '../choose-plan-popup/ChoosePlanPopup';
import styles from './SubscriptionComponent.module.scss';

interface SubscriptionComponentProps {
  stripeData: StripeData;
  user: User;
}

const SubscriptionComponent = ({ stripeData, user }: SubscriptionComponentProps) => {
  const { t } = useTranslation();

  console.log('STRIPE DATA:', stripeData);
  // Fonction pour ouvrir le portail client Stripe dans un nouvel onglet
  const handleManageSubscriptionClick = () => {
    window.open(stripeData.customer_portal, '_blank');
  };

  const today = new Date(); // Today's date
  const timeDiff = stripeData?.trial_end
    ? new Date(stripeData?.trial_end).getTime() - today.getTime()
    : null; // Difference in milliseconds
  const daysUntilTrialEnd = Math.round(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days

  const [togglePlanSelectionOpened, setTogglePlanSelectionOpened] = useState(false);

  return (
    <div className={styles.root}>
      {togglePlanSelectionOpened && (
        <ChoosePlanPopup
          user={user}
          onClose={() => setTogglePlanSelectionOpened(!togglePlanSelectionOpened)}
        />
      )}

      <div className={styles.section}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{t('setting.subscription.myPlan')}</div>
        </div>
        <div className={styles.contentContainer}>
          {!stripeData?.subscription_id && (
            <div className={styles.value}>
              <b>{`${daysUntilTrialEnd.toString()} ${
                daysUntilTrialEnd > 1 ? t('daysUntilTrialEnd') : t('dayUntilTrialEnd')
              }`}</b>
            </div>
          )}

          <div className={styles.editButton}>
            <button
              onClick={() =>
                stripeData?.subscription_id && stripeData.status != 'canceled'
                  ? handleManageSubscriptionClick()
                  : setTogglePlanSelectionOpened(!togglePlanSelectionOpened)
              }
            >
              {t('setting.subscription.editPlan')}
            </button>
            <img src={ArrowIcon} alt="" />
          </div>
        </div>
      </div>

      <div className={styles.separator} />

      <div className={styles.section}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{t('setting.subscription.billing')}</div>
        </div>

        <div className={styles.contentContainer}>
          <div className={styles.editButton}>
            <button onClick={handleManageSubscriptionClick}>
              {t('setting.subscription.accessBilling')}
            </button>
            <img src={ArrowIcon} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionComponent;
