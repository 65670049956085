/* eslint-disable react/no-unstable-nested-components */
import { Channel } from '@axios/mutations';
import { Comment, Post } from '@axios/queries';
import ImageLoader from '@components/image-loader/ImageLoader';
import commentIcon from '@images/comment_icon.svg';
import facebookSVG from '@images/facebook.svg';
import replyIcon from '@images/green_reply_icon.svg';
import instagramSVG from '@images/instagram.svg';
import likeIcon from '@images/like_icon.svg';
import shareIcon from '@images/share_icon.svg';
import tiktokSVG from '@images/tiktok.svg';
import viewIcon from '@images/view_icon.svg';
import { formatNumber } from '@utils';
import { formatTimeToNowShort } from '@utils/formatTimeToNowShort';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './PostHeader.module.scss';

type PostHeaderProps = {
  channel: Channel;
  post: Post;
};

const PostHeader = ({ channel, post }: PostHeaderProps) => {
  const { profile_picture_url } = channel;
  const navigate = useNavigate();

  const numberOfComments = (post.comments as Comment[])?.filter(
    (c: Comment) => c.username != channel.username && !c.username?.includes(channel.name),
  ).length;

  const CHANNEL_ICONS = {
    facebook: facebookSVG,
    instagram: instagramSVG,
    tiktok: tiktokSVG,
  };

  // Utilize the formatTimeToNow utility function for the timestamp
  const formattedTimestamp = useMemo(
    () => formatTimeToNowShort(post.timestamp),
    [post.timestamp],
  );

  const countReplies = post.repliedCommentsCount;

  return (
    <div className={styles.postHeader}>
      <div className={styles.postInfo}>
        <div
          style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
          className={styles.Hover}
          onClick={() => navigate(`/channels/${channel.channel_id}`)}
        >
          <div className={styles.accountIconContainer}>
            <ImageLoader
              src={profile_picture_url}
              alt=""
              className={styles.accountIcon}
              width={40}
              height={40}
              borderRadius={100}
            />
            <img
              src={CHANNEL_ICONS[channel.type]}
              alt=""
              className={styles.channelIcon}
            />
          </div>

          <p className={styles.username}>@{channel.username}</p>
        </div>

        {!post.caption && (
          <div className={styles.postDetails}>
            <div className={styles.postTimestamp}>{formattedTimestamp}</div>

            <div className={styles.imageWrapper}>
              {post.mediaType?.includes('ADS') && (
                <div className={styles.adsLabel}>AD</div>
              )}
              <a href={post?.permalink} target="_blank" rel="noopener noreferrer">
                <ImageLoader
                  src={post.thumbnail_url}
                  alt=""
                  className={styles.postMedia}
                  width={45}
                  height={45}
                  borderRadius={'8px'}
                />
              </a>
            </div>
          </div>
        )}
      </div>

      {post.caption && (
        <div className={styles.postDetails}>
          <div className={styles.postCaption}>{post.caption}</div>

          <div className={styles.postTimestamp}>{formattedTimestamp}</div>

          <div className={styles.imageWrapper}>
            {post.mediaType?.includes('ADS') && <div className={styles.adsLabel}>AD</div>}
            <a href={post?.permalink} target="_blank" rel="noopener noreferrer">
              <ImageLoader
                src={post.thumbnail_url}
                alt=""
                className={styles.postMedia}
                width={45}
                height={45}
                borderRadius={'8px'}
              />
            </a>
          </div>
        </div>
      )}

      <div className={styles.postMetrics}>
        <div className={styles.metricItem}>
          <img src={replyIcon} alt="" />
          <div>{formatNumber(countReplies)}</div>
        </div>

        <div className={styles.metricItem}>
          <img src={commentIcon} alt="" />
          <div>{formatNumber(numberOfComments)}</div>
        </div>

        <div className={styles.metricItem}>
          <img src={likeIcon} alt="" />
          <div>{formatNumber(post.likeCount)}</div>
        </div>

        <div className={styles.metricItem}>
          <img src={viewIcon} alt="" />
          <div>{post.views ? formatNumber(post.views) : '-'}</div>
        </div>
        {/* 
        <div className={styles.metricItem}>
          <img src={shareIcon} alt="" />
          <div>{post.shares ?? '-'}</div>
        </div> */}
      </div>
    </div>
  );
};

export { PostHeader };
