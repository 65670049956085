import { Channel } from '@axios/mutations';
import { Comment, Reply } from '@axios/queries';
import React, { useEffect, useRef, useState } from 'react';

import styles from './RepliesList.module.scss';
import ReplyComponent from './reply/ReplyComponent';

type RepliesListProps = {
  interaction: Comment | null;
  channel: Channel;
  selectedRef: React.RefObject<HTMLDivElement>;
  selectedId: string;
};

const RepliesList = ({
  interaction,
  channel,
  selectedRef,
  selectedId,
}: RepliesListProps) => {
  const [localReplies, setLocalReplies] = useState<Reply[]>([]);

  useEffect(() => {
    // Ensure interaction and interaction.replies are defined
    if (interaction && interaction.replies) {
      const repliesToUpdate = [...interaction.replies] as Reply[];

      // Check if interaction.reply needs to be added
      if (
        interaction.reply &&
        !interaction.replies.some((r) => r.reply_id === interaction.reply?.reply_id)
      ) {
        repliesToUpdate.push(interaction.reply);
      }

      // Deduplicate replies considering both reply_id and username-text with debugging
      const keyMap = new Map();

      const uniqueReplies = Array.from(
        repliesToUpdate.reduce((acc, reply) => {
          const username =
            reply.username == channel.name ? channel.username : reply.username;
          // Construct a key based on available information
          const key = `user-text-${username}-${reply.text.trim()}`;

          // Debugging: log keys to ensure they are unique as expected
          if (keyMap.has(key)) {
            console.log('Duplicate key found:', key, 'for reply', reply);
          } else {
            console.log('Adding key:', key, 'for reply', reply);
            keyMap.set(key, true);
            acc.set(key, reply);
          }

          return acc;
        }, new Map()),
      ).map(([key, reply]) => reply);

      // Log the final list of unique replies
      console.log('Unique replies:', uniqueReplies);

      // Sort the unique replies
      const sortedReplies = uniqueReplies.sort((a, b) => {
        if (a.timestamp === null && b.timestamp !== null) return 1;
        if (a.timestamp !== null && b.timestamp === null) return -1;
        if (a.timestamp === null && b.timestamp === null) return 0;
        return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
      });

      // Update localReplies with sorted, unique replies
      setLocalReplies(sortedReplies);
    }
  }, [interaction]);

  if (localReplies && localReplies.length > 0) {
    // Render using sortedReplies
    return (
      <div className={styles.root}>
        {localReplies.map((reply) => (
          <ReplyComponent
            key={reply._id}
            channel={channel}
            reply={reply}
            interaction_selected={interaction}
            selectedRef={selectedRef}
            selectedId={selectedId}
          />
        ))}
      </div>
    );
  }
};

export default RepliesList;
