import { StripeData } from '@axios/queries';
import arrowRight from '@images/arrow-right-line.svg';
import darkElon from '@images/grey_elon.svg';
import { useTranslation } from 'react-i18next';

import styles from './NoAccessComponent.module.scss';

type NoAccessComponentProps = { stripeData: StripeData };

const NoAccessComponent = ({ stripeData }: NoAccessComponentProps) => {
  const { t } = useTranslation();

  // Fonction pour ouvrir le portail client Stripe dans un nouvel onglet
  const handleManageSubscriptionClick = () => {
    window.open(stripeData.customer_portal, '_blank');
  };

  return (
    <div className={styles.root}>
      <div className={styles.contentWrapper}>
        <div className={styles.titleContainer}>
          <img src={darkElon} alt="" width={'100px'} />

          <div>{t('accessRestricted')}</div>
        </div>

        <button onClick={handleManageSubscriptionClick} className={styles.confirmButton}>
          {t('setting.subscription.editPlan')}

          <img src={arrowRight} alt="" className={styles.arrowIcon} />
        </button>
      </div>
    </div>
  );
};

export { NoAccessComponent };
