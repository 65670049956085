import { createContext, useCallback, useContext, useMemo, useState } from 'react';

type UICacheContextType = {
  UICache: { [key: string]: any };
  updateUICache: (key: string, cache: any) => void;
};

const UICacheContext = createContext<UICacheContextType>({
  UICache: {},
  updateUICache: () => {},
});

const useUICache = () => {
  const context = useContext(UICacheContext);
  if (!context) {
    throw new Error('useUICache must be used within a UICacheProvider');
  }
  return context;
};

const UICacheProvider = ({ children }) => {
  const [UICache, setUICache] = useState({});

  const updateUICache = useCallback((key, cache) => {
    setUICache((prevStates) => ({ ...prevStates, [key]: cache }));
  }, []);

  const value = useMemo(() => ({ UICache, updateUICache }), [UICache, updateUICache]);

  return <UICacheContext.Provider value={value}>{children}</UICacheContext.Provider>;
};

export { UICacheProvider, useUICache };
