import { Auth0Provider } from '@auth0/auth0-react';
import { ReactNode } from 'react';

type Auth0ProviderWithNavProps = {
  children: ReactNode;
};

const Auth0ProviderWithNav = ({ children }: Auth0ProviderWithNavProps) => {
  const domain = import.meta.env.VITE_AUTH0_DOMAIN;
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
  const audience = import.meta.env.VITE_AUTH0_AUDIENCE;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: window.location.origin,
        scope: 'openid profile email offline_access',
      }}
      // useRefreshTokens={true}
      cacheLocation={'localstorage'}
    >
      {children}
    </Auth0Provider>
  );
};

export { Auth0ProviderWithNav };
