import { Reply } from '@axios/queries';
import { useAxiosInstance } from '@axios/useAxiosInstance';
import { MutationFunction, useMutation, UseMutationOptions } from '@tanstack/react-query';

type LikeCommentInput = {
  channel_id: string;
  comment_id: string;
  like: boolean;
};

type LikeCommentOutput = {
  reply: Reply;
};

const useLikeComment = (
  options?: Omit<
    UseMutationOptions<LikeCommentOutput, Error, LikeCommentInput>,
    'mutationFn'
  >,
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const likeComment: MutationFunction<LikeCommentOutput, LikeCommentInput> = async (
    input: LikeCommentInput,
  ) => {
    if (!authenticated) {
      throw new Error('User must be authenticated to send reply');
    }

    const instance = await getAxiosInstance();

    return instance
      .post('api/assistant/like-comment', input)
      .then((response) => response.data);
  };

  return useMutation<LikeCommentOutput, Error, LikeCommentInput>(likeComment, {
    ...options,
    onSuccess:
      options?.onSuccess ||
      ((data) => {
        console.log('Liking comment successfully!', data);
      }),
    onError:
      options?.onError ||
      ((error, input) => {
        console.error('Error liking comment:', error, input);
      }),
  });
};

export { useLikeComment };
export type { LikeCommentInput, LikeCommentOutput };
