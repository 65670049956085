import { useAxiosInstance } from '@axios/useAxiosInstance';
import { QueryFunction, useQuery, UseQueryOptions } from '@tanstack/react-query';

type NangoConnection = {
  id: string;
  user_id: string;
  provider_config_key: string;
  created: string;
};

const useNangoConnections = (
  user_id: string,
  providerConfigKey?: string,
  options?: Omit<
    UseQueryOptions<
      NangoConnection[],
      Error,
      NangoConnection[],
      [string, string, string]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  },
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const fetchNangoConnections: QueryFunction<
    NangoConnection[],
    [string, string, string]
  > = async () => {
    const instance = await getAxiosInstance();

    return instance
      .get('api/nango/connections', {
        params: { user_id: user_id, providerConfigKey: providerConfigKey },
      })
      .then((response) => response.data);
  };

  return useQuery<NangoConnection[], Error, NangoConnection[], [string, string, string]>(
    ['nango-connections', user_id, providerConfigKey],
    fetchNangoConnections,
    {
      ...options,
      onSuccess:
        options?.onSuccess ||
        ((data) => {
          console.log('Nango Connections fetched successfully!', data);
        }),
      onError:
        options?.onError ||
        ((error) => {
          console.error('Error fetching Nango Connections:', error);
        }),
    },
  );
};

export { useNangoConnections };
export type { NangoConnection };
