/* eslint-disable max-len */
import { Channel } from '@axios/mutations';
import { Comment, Reply } from '@axios/queries';
import ImageLoader from '@components/image-loader/ImageLoader';
import { formatDistanceToNow } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ReplyComponent.module.scss';

type ReplyComponentProps = {
  reply: Reply;
  interaction_selected: Comment;
  channel: Channel;
  selectedRef: React.RefObject<HTMLDivElement>;
  selectedId: string;
};

const ReplyComponent = ({
  reply,
  interaction_selected,
  channel,
  selectedRef,
  selectedId,
}: ReplyComponentProps) => {
  const { t } = useTranslation();

  const { channel_id, username, name: channel_name, profile_picture_url } = channel;
  const isReplyByUser =
    reply?.username == username || reply?.username?.includes(channel_name);
  const isSelectedInteraction =
    reply?._id === selectedId || interaction_selected?.reply?.reply_id == reply?.reply_id;

  return (
    <div
      className={`${styles.reply} ${isReplyByUser && styles.replyByUser} ${
        isSelectedInteraction ? styles.highlighted : ''
      }`}
      ref={isSelectedInteraction ? selectedRef : null}
    >
      <div className={styles.contentContainer}>
        <div className={`${styles.userAvatar} ${isReplyByUser && styles.replyByUser}`}>
          <ImageLoader
            borderRadius={'50%'}
            width={'48px'}
            height={'48px'}
            src={
              isReplyByUser
                ? profile_picture_url
                : reply?.profilePictureUrl ||
                  `https://api.dicebear.com/7.x/micah/svg?seed=${
                    reply?.username || reply?.reply_id
                  }&flip=true&radius=50&baseColor=ac6651,f9c9b6,transparent&eyeShadowColor=d2eff3,e0ddff,ffeba4,ffedef,ffffff,transparent&backgroundColor=ffdfbf,d1d4f9,c0aede,b6e3f4,ffd5dc`
              // `https://api.dicebear.com/7.x/initials/svg?seed=${reply.username}&radius=50&backgroundColor=00897b,00acc1,039be5,1e88e5,3949ab,43a047,5e35b1,7cb342,8e24aa,c0ca33,d81b60,e53935,f4511e,fb8c00,fdd835,ffb300,ffd5dc,ffdfbf&fontFamily=Arial&chars=1`
              // `https://api.dicebear.com/7.x/avataaars-neutral/svg?seed=${reply.username}&radius=50&backgroundColor=d08b5b,edb98a,f8d25c,fd9841,ffdbb4,c0aede,b6e3f4,ffd5dc,ffdfbf,d1d4f9,ae5d29&eyebrows=default,defaultNatural,flatNatural,frownNatural,raisedExcited,raisedExcitedNatural,upDown,upDownNatural&eyes=closed,default,eyeRoll,happy,hearts,side,squint,surprised,wink,winkWacky&mouth=default,disbelief,eating,grimace,sad,screamOpen,serious,smile,tongue,twinkle,concerned`
            }
            src2={`https://api.dicebear.com/7.x/micah/svg?seed=${
              reply?.username || reply?.reply_id
            }&flip=true&radius=50&baseColor=ac6651,f9c9b6,transparent&eyeShadowColor=d2eff3,e0ddff,ffeba4,ffedef,ffffff,transparent&backgroundColor=ffdfbf,d1d4f9,c0aede,b6e3f4,ffd5dc`}
            alt=""
            className={styles.userAvatar}
          />

          {/* <div className={styles.initialCircle}>{getInitial(reply.username)}</div> */}
        </div>

        <div className={styles.content}>
          {reply.username && (
            <div className={styles.containerCommentDetails}>
              <div
                className={`${styles.username} ${isReplyByUser && styles.replyByUser}`}
              >
                {isReplyByUser}@{reply.username}
              </div>

              <span className={styles.date}>
                {reply.timestamp && reply.timestamp != 'ERROR'
                  ? formatDistanceToNow(new Date(reply.timestamp), {
                      addSuffix: true,
                    })
                  : t('sending')}
              </span>
            </div>
          )}
          <div className={`${styles.textComment} ${isReplyByUser && styles.replyByUser}`}>
            {reply?.text?.split('\n').map((line, index, array) => (
              <React.Fragment key={index}>
                {line}
                {index < array.length - 1 && <br />}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplyComponent;
