import { Reply } from '@axios/queries';
import { useAxiosInstance } from '@axios/useAxiosInstance';
import { MutationFunction, useMutation, UseMutationOptions } from '@tanstack/react-query';

type SkipCommentInput = {
  comment_id: string;
};

type SkipCommentOutput = {
  reply: Reply;
};

const useSkipComment = (
  options?: Omit<
    UseMutationOptions<SkipCommentOutput, Error, SkipCommentInput>,
    'mutationFn'
  >,
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const skipComment: MutationFunction<SkipCommentOutput, SkipCommentInput> = async (
    input: SkipCommentInput,
  ) => {
    if (!authenticated) {
      throw new Error('User must be authenticated to send reply');
    }

    const instance = await getAxiosInstance();

    return instance
      .post('api/assistant/skip-comment', input)
      .then((response) => response.data);
  };

  return useMutation<SkipCommentOutput, Error, SkipCommentInput>(skipComment, {
    ...options,
    onSuccess:
      options?.onSuccess ||
      ((data) => {
        console.log('Skip comment successfully!', data);
      }),
    onError:
      options?.onError ||
      ((error, input) => {
        console.error('Error skipping comment:', error, input);
      }),
  });
};

export { useSkipComment };
export type { SkipCommentInput, SkipCommentOutput };
