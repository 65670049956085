import { Channel, useSendReply } from '@axios/mutations';
import { Comment, Reply } from '@axios/queries';
import PopupComponent from '@components/popup/PopupComponent';
import alertIcon from '@images/alert-line.svg';
import CheckIcon from '@images/check-icon.svg';
import EditIcon from '@images/edit-icon.svg';
import elonIcon from '@images/elon_custom_icon.png';
import loaderAnimation from '@images/loader.json';
import CloseIcon from '@images/x-close.svg';
import { usePopupStore } from '@redux/store';
import Cookies from 'js-cookie';
import Lottie from 'lottie-react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ValidationComponent.module.scss';

type ValidationComponentProps = {
  comment: Comment;
  channel: Channel;
  text: string;
  onValidate: (reply?) => void; // Function to call when the validation button is clicked
  onClose?: () => void; // Function to call when the validation button is clicked
};

const ValidationComponent: React.FC<ValidationComponentProps> = ({
  comment,
  channel,
  text = '',
  onValidate,
  onClose,
}) => {
  const { t } = useTranslation();

  const MAX_TEXT_LENGTH = comment.post?.mediaType == 'tiktok' ? 150 : 2000;

  const [isValidate, setIsValidate] = useState(false);
  const [isEditing, setIsEditing] = useState(!text || text.length == 0); // New state for managing edit mode
  const [editText, setEditText] = useState(text ?? ''); // State to hold the edited text

  const [error, setError] = useState(false); // New state for managing text length error

  const { mutateAsync: sendReply, isLoading } = useSendReply();

  const rootRef = useRef(null); // Ref for the root element
  const textareaRef = useRef(null);

  const setPopup = usePopupStore((state) => state.setPopup);

  const showPopupFirstValidation = () => {
    // Check if the 'first-validate-action' cookie exists
    const isFirstValidation = !Cookies.get('first-validate-action');
    if (isFirstValidation) {
      // Show the popup if it's the user's first validation action
      setPopup(
        <PopupComponent
          title={t('elon.validationComponent.title')}
          description={
            <p className={styles.popupDescription}>
              <b>{t('elon.validationComponent.description.bold')}</b>{' '}
              {t('elon.validationComponent.description.normal')}
            </p>
          }
          buttonText={t('elon.validationComponent.confirm')}
          icon={elonIcon}
          onConfirm={() => {
            // Set a cookie to mark the first validation action
            Cookies.set('first-validate-action', 'true', { expires: 365 }); // Expires in 365 days
            setPopup(null, null); // Assuming passing null hides the popup
          }}
        />,
      );
    }
  };

  const handleValidate = async () => {
    showPopupFirstValidation();

    await sendReply({
      comment_id: comment.comment_id,
      channel_id: channel.channel_id,
      message: editText, // Use editText here
    }).then((reply) => {
      comment.reply = reply as Reply;

      setIsValidate(true);
      setIsEditing(false); // Exit editing mode after validation
      onValidate ? onValidate(reply) : null;
    });

    setIsEditing(false); // Exit editing mode after validation
    setIsValidate(true);
    // onValidate ? onValidate() : null;
  };

  const handleEdit = () => {
    if (isEditing) {
      // Check if the new text exceeds the maximum length
      if (text?.length > MAX_TEXT_LENGTH) {
        setError(true);
      } else {
        setError(false);
      }

      setEditText(text);
    }

    if (!text || text?.length == 0) {
      onClose();
    }

    setIsEditing(!isEditing); // Toggle editing mode
  };

  const handleTextareaChange = (e) => {
    const newText = e.target.value;
    setEditText(newText);

    // Check if the new text exceeds the maximum length
    if (newText.length > MAX_TEXT_LENGTH) {
      setError(true);
    } else {
      setError(false);
    }

    // Reset height to 'auto' to ensure the scrollHeight calculation is correct
    e.target.style.height = 'auto';
    // Set the height to scrollHeight to accommodate the content
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      textareaRef.current.focus(); // Mettre le focus sur le champ de saisie en mode édition
      const textarea = textareaRef.current;
      textarea.style.height = 'auto'; // Ajuster la hauteur pour le contenu
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [isEditing]);

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (
  //       rootRef.current &&
  //       !rootRef.current.contains(event.target) &&
  //       isEditing &&
  //       !isLoading &&
  //       !isValidate
  //     ) {
  //       setEditText(text);
  //       setIsEditing(false); // Exit edit mode if click is outside the component
  //     }
  //   }

  //   // Add click event listener
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     // Cleanup the event listener
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [rootRef]); // Dependency array includes rootRef

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      const textarea = textareaRef.current;
      // Ensure the textarea has the correct height as soon as editing starts
      textarea.style.height = 'auto'; // Reset height to recalculate
      textarea.style.height = `${textarea.scrollHeight}px`; // Adjust to content
    }
  }, [isEditing, isValidate]);

  return (
    <div ref={rootRef} className={`${styles.root} ${isEditing && styles.rootEditing}`}>
      <div className={styles.rootContainer}>
        <div className={styles.message}>
          <img
            src={channel.profile_picture_url}
            alt=""
            className={styles.profilePicture}
          />
          {!isEditing ? (
            <span>
              <b>{channel.username} :</b>{' '}
              {editText.split('\n').map((line, index, array) => (
                <React.Fragment key={index}>
                  {line}
                  {index < array.length - 1 && <br />}
                </React.Fragment>
              ))}
            </span>
          ) : (
            <textarea
              ref={textareaRef}
              value={editText}
              onChange={(e) => handleTextareaChange(e)}
              className={styles.editInput}
              placeholder={t('writePlaceholder')}
            />
          )}
        </div>

        <div className={styles.containerButton}>
          <div className={styles.buttons}>
            {!isValidate && !isEditing ? (
              <button
                onClick={handleEdit}
                className={`${styles.button} ${styles.editButton}`}
                disabled={isValidate}
              >
                <span className={`${styles.buttonText}`}>
                  <img src={EditIcon} alt="" />
                  {t('edit')}
                </span>
              </button>
            ) : (
              !isValidate && (
                <button
                  onClick={handleEdit}
                  className={`${styles.button} ${styles.editButton} ${styles.closeButton}`}
                  disabled={isValidate}
                >
                  <span className={`${styles.buttonText}`}>
                    <img src={CloseIcon} alt="" />
                  </span>
                </button>
              )
            )}

            <button
              onClick={handleValidate}
              className={`${styles.button} ${isValidate && styles.buttonValide}`}
              disabled={isValidate || !editText || editText.length == 0 || error}
            >
              <span className={`${styles.buttonText}`}>
                {!isValidate
                  ? t('elon.validationComponent.send')
                  : t('elon.validationComponent.sent')}
                {!isValidate && !isLoading && editText && editText.length != 0 && (
                  <img src={CheckIcon} alt="" />
                )}
                {isLoading && (
                  <Lottie
                    animationData={loaderAnimation}
                    loop={true}
                    style={{ width: 16, height: 16 }}
                  />
                )}
              </span>
            </button>
          </div>

          {error && (
            <div className={styles.errorMessage}>
              <img src={alertIcon} alt="" width={'24px'} />
              {t('elon.validationComponent.textTooLong', { max: MAX_TEXT_LENGTH })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ValidationComponent;
