const LogoTextSVG = ({ color = '#101828', width = '90' }) => {
  return (
    <svg width={width} viewBox="0 0 275 120" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        fontFamily="Bricolage Grotesque"
        fontSize="100"
        fontStyle="condensed"
        fontWeight="700"
        letterSpacing="-6.5px"
      >
        <g id="logo-text" transform="translate(-15, -68)">
          <text fill={color}>
            <tspan x="15.1" y="161">
              NSWR
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};

export default LogoTextSVG;
