import ErrorPage from '@components/error-page/ErrorPage';

import DashboardRoute from './dashboard';

const Index = () => <DashboardRoute />;

export default Index;
export const Catch = () => {
  return <ErrorPage />;
};
