/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
import { Channel, useUpdateUser } from '@axios/mutations';
import { Comment, Post, StripeData, useCheckSync, usePosts, User } from '@axios/queries';
import ImageLoader from '@components/image-loader/ImageLoader';
import PopupComponent from '@components/popup/PopupComponent';
import FiltersPopup from '@containers/task/popup-filters/FiltersPopup';
import arrowBottom from '@images/arrow-bottom.svg';
import arrowTop from '@images/arrow-top.svg';
import calendarIcon from '@images/calendar_icon.svg';
import commentIcon from '@images/comment_icon.svg';
import errorIcon from '@images/error_icon.png';
import facebookSVG from '@images/facebook.svg';
import filterIcon from '@images/filter.svg';
import followerIcon from '@images/follower_icon.svg';
import greenReplyIcon from '@images/green_reply_icon.svg';
import instagramSVG from '@images/instagram.svg';
import likeIcon from '@images/like_icon.svg';
import loaderBlueAnimation from '@images/LoaderBlue.json';
import LogoutIcon from '@images/logout-circle-line.svg';
import postIcon from '@images/post_icon.svg';
import replyIcon from '@images/reply_icon.svg';
import settingButton from '@images/setting_button.png';
import shareIcon from '@images/share_icon.svg';
import tiktokSVG from '@images/tiktok.svg';
import viewIcon from '@images/view_icon.svg';
import { Button, Checkbox, Menu, rem, Text } from '@mantine/core';
import { usePopupStore } from '@redux/store';
import { formatDate, formatNumber } from '@utils';
import Cookies from 'js-cookie';
import Lottie from 'lottie-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSortBy, useTable } from 'react-table';

import styles from './Channels.module.scss';

type ChannelsProps = {
  channel: Channel;
  posts: Post[];
  user: User;
  stripeData: StripeData;
};

const Channels = ({ stripeData, channel, posts: initialPosts, user }: ChannelsProps) => {
  const [toggleSettingOpened, setToggleSettingOpened] = useState(false);
  const [toggleFilterOpened, setToggleFilterOpened] = useState(false);

  // const [filters, setFilters] = useState({
  //   ads: true,
  //   organic: true,
  // });

  console.log('posts:', initialPosts);
  // const lastMonthAgo = new Date();
  // lastMonthAgo.setDate(lastMonthAgo.getDate() - 30);

  const uniqueAdsAccounts = [channel].reduce((acc, channel) => {
    if (channel.ads_accounts && channel.ads_accounts.length > 0) {
      channel.ads_accounts.forEach((account) => {
        if (account) {
          // Ensuring account is not undefined
          const uniqueKey = `${account.name}-${account.ad_account_id}`;
          if (!acc.has(uniqueKey)) {
            acc.set(uniqueKey, {
              value: account.ad_account_id,
              label: `${account.name} (${account.ad_account_id})`,
            });
          }
        }
      });
    }
    return acc;
  }, new Map());

  const adsAccounts = Array.from(uniqueAdsAccounts.values());

  const [filters, setFilters] = useState({
    viewAll: false,
    dateRange: [] as unknown as [Date, Date],
    viewUnreplied: false,
    filtersType: {
      ads:
        user?.subscription_plan == 2 || user?.stripe_subscription_status == 'trialing'
          ? true
          : false,
      organic: true,
    },
    adsAccounts:
      user?.subscription_plan == 2 || user?.stripe_subscription_status == 'trialing'
        ? (adsAccounts.map((account) => account.value) as [string])
        : ([] as unknown as [string]),
  });

  const CHANNEL_ICONS = {
    facebook: facebookSVG,
    instagram: instagramSVG,
    tiktok: tiktokSVG,
  };

  const {
    channel_id,
    username,
    user_id,
    name: channel_name,
    profile_picture_url,
  } = channel;
  const navigate = useNavigate(); // If you're using react-router-dom

  const { t } = useTranslation();

  const [syncStatus, setSyncStatus] = useState('syncing');
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState(initialPosts);
  const [filteredPosts, setFilteredPosts] = useState(
    initialPosts.filter((p) => {
      const type = p.mediaType;
      const postDate = new Date(p.timestamp);
      const adAccount = p.ad_account_id;

      // Checking if the postDate is within the filters.dateRange
      const isDateInRange =
        filters?.dateRange &&
        filters.dateRange.length == 2 &&
        filters.dateRange[0] &&
        filters.dateRange[1]
          ? postDate >= filters.dateRange[0] && postDate <= filters.dateRange[1]
          : true;

      // Checking if the adAccount is in the list of selected ad accounts in filters.adAccounts
      const isAdAccountSelected = type?.includes('ADS')
        ? filters?.adsAccounts.includes(adAccount) ||
          filters?.adsAccounts?.length == adsAccounts.length
        : true;

      const hasUnrepliedComments =
        (p.comments as Comment[])?.filter((c) => !c.reply)?.length > 0;

      if (filters?.viewUnreplied && !hasUnrepliedComments) {
        return false;
      }

      if (!filters?.filtersType?.ads && type?.includes('ADS')) {
        return false;
      }

      if (!filters?.filtersType?.organic && !type?.includes('ADS')) {
        return false;
      }

      // console.log('isDateInRange', isDateInRange);
      // console.log('isAdAccountSelected', isAdAccountSelected);
      // console.log('adaccounts', filters?.adsAccounts);
      // console.log('account', adAccount);

      return isDateInRange && isAdAccountSelected;
    }),
  );

  console.log('filters:', filters);

  console.log('filteredPosts:', filteredPosts);

  const [page, setPage] = useState(1);
  const loader = useRef(null);
  const observerRef = useRef(null);
  const pollingInterval = useRef<NodeJS.Timeout | null>(null);

  const setPopup = usePopupStore((state) => state.setPopup); // Use the setPopup function

  const { mutateAsync: updateUser } = useUpdateUser(user.user_id);

  const { isLoading: isLoadingPosts, refetch } = usePosts(
    { channel_id: channel_id, page: page }, // Include page in the query key to refetch when it changes
    {
      enabled: page != 1, // Keep the query disabled initially
      onSuccess: (newPosts: Post[]) => {
        setPosts((currentPosts) => {
          let updatedPosts = [];
          if (currentPosts && currentPosts.length > 0) {
            updatedPosts = [...currentPosts];
          }

          newPosts.forEach((post) => {
            if (!updatedPosts.some((existingPost) => existingPost._id === post._id)) {
              updatedPosts.push(post);
            }
          });
          return updatedPosts;
        });

        // Introduce a 500ms delay before setting isLoading to false
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      },
    },
  );

  const { refetch: refetchSyncStatus } = useCheckSync(
    {
      channel_id: channel_id,
      post_id: null,
      page: page,
      user_id: user_id,
    }, // Include page in the query key to refetch when it changes
    {
      enabled: false, // Keep the query disabled initially
      onSuccess: async (data) => {
        setSyncStatus(data.status); // Set the status based on the response
        if (data.status === 'complete') {
          clearInterval(pollingInterval.current); // Clear the interval if syncing is complete
          await refetch(); // Refetch the main data
        }
      },
    },
  );

  const confirmDelete = () => {
    setPopup(
      <PopupComponent
        title={t('channels.popupConfirm.title')}
        description={t('channels.popupConfirm.description')}
        buttonText={t('channels.popupConfirm.buttonText')}
        widthIcon={'85px !important'}
        icon={errorIcon}
        onConfirm={async () => {
          await deleteChannel();
        }}
        onCancelButtonText={t('Cancel')}
        onCancel={() => setPopup(null, null)}
        classname={styles.popupContainer}
      />,
    );
  };

  const deleteChannel = async () => {
    const newChannels = user.channels.filter((c) => c !== channel.channel_id);

    await updateUser({
      user_id: channel.user_id,
      channels: newChannels,
    })
      .then(() => {
        window.location.href =
          import.meta.env.VITE_NODE_ENV == 'development'
            ? 'http://localhost:3005'
            : 'https://app.nswr.ai';
      })
      .catch((error) => {
        console.error('Failed to update user channels:', error);
      });
  };

  useEffect(() => {
    // Only set up polling if syncStatus indicates it's needed
    if (syncStatus === 'syncing') {
      pollingInterval.current = setInterval(() => {
        void refetchSyncStatus();
      }, 5000); // Poll every 5 seconds
    }

    // Clean up the interval when the component unmounts or syncStatus changes
    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current);
      }
    };
  }, [syncStatus, refetchSyncStatus]);

  // Effect to handle refetching when page changes
  useEffect(() => {
    if (page > 1) {
      void refetch(); // This manually triggers the fetching based on the current `page` state
      setSyncStatus('syncing');
    }
  }, [page]);

  // // Update your IntersectionObserver setup to be inside a callback ref
  // const lastRowRef = useCallback((node) => {
  //   // Initialize observer if it hasn't been already
  //   if (!observerRef.current) {
  //     observerRef.current = new IntersectionObserver(
  //       (entries) => {
  //         if (entries[0].isIntersecting) {
  //           setIsLoading(true);
  //           setPage((prevPage) => prevPage + 1);
  //         }
  //       },
  //       { threshold: 0.1 },
  //     );
  //   }

  //   // If there's a node, observe it
  //   if (node) observerRef.current.observe(node);

  //   // Cleanup: stop observing the previous node
  //   return () => {
  //     if (observerRef.current && loader.current) {
  //       observerRef.current.unobserve(loader.current);
  //     }
  //   };
  // }, []); // Empty dependency array ensures this callback doesn't re-create the observer unnecessarily

  const applyFilters = (filters) => {
    setToggleFilterOpened(false);

    console.log('filters:', filters);

    const filteredPosts = posts.filter((p) => {
      const type = p.mediaType;
      const postDate = new Date(p.timestamp);
      const adAccount = p.ad_account_id;

      // Checking if the postDate is within the filters.dateRange
      const isDateInRange =
        filters?.dateRange &&
        filters.dateRange.length == 2 &&
        filters.dateRange[0] &&
        filters.dateRange[1]
          ? postDate >= filters.dateRange[0] && postDate <= filters.dateRange[1]
          : true;

      // Checking if the adAccount is in the list of selected ad accounts in filters.adAccounts
      const isAdAccountSelected = type?.includes('ADS')
        ? filters?.adsAccounts.includes(adAccount)
        : // ||
          //   filters?.adsAccounts?.length == adsAccounts.length
          true;

      const hasUnrepliedComments =
        (p.comments as Comment[])?.filter((c) => !c.reply)?.length > 0;

      if (filters?.viewUnreplied && !hasUnrepliedComments) {
        return false;
      }

      if (!filters?.filtersType?.ads && type?.includes('ADS')) {
        return false;
      }

      if (!filters?.filtersType?.organic && !type?.includes('ADS')) {
        return false;
      }

      // console.log('isDateInRange', isDateInRange);
      // console.log('isAdAccountSelected', isAdAccountSelected);
      // console.log('adaccounts', filters?.adsAccounts);
      // console.log('account', adAccount);

      return isDateInRange && isAdAccountSelected;
    });

    setFilteredPosts(filteredPosts);

    // Serialize and save filters to a cookie
    const serializedFilters = JSON.stringify(filters);
    Cookies.set(`${channel_id}-filters`, serializedFilters, { expires: 30 }); // Expires in 30 days
  };

  const loadMorePosts = () => {
    setIsLoading(true); // Show loading indicator
    setPage((prevPage) => prevPage + 1); // Increment page to fetch next set of posts
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t('channels.publications'),
        accessor: 'thumbnail_url',
        disableSortBy: true, // Disable sorting on this column
        Cell: ({ row }) => (
          <div className={`${styles.cell} ${styles.postCell}`}>
            <div className={styles.imageContainer}>
              {row.original.mediaType?.includes('ADS') && (
                <div className={styles.adsLabel}>AD</div>
              )}

              <ImageLoader
                src={row.original.thumbnail_url}
                alt=""
                className={styles.postImage}
                width={80}
                height={80}
              />
            </div>
            <p className={styles.postCaption}>{row.original.caption}</p>
          </div>
        ),
      },
      {
        Header: t('channels.createdDate'),
        sortType: (rowA, rowB) => {
          return (
            new Date(rowA.original.timestamp).getTime() -
            new Date(rowB.original.timestamp).getTime()
          );
        },
        accessor: 'timestamp',
        disableSortBy: false, // Disable sorting on this column
        Cell: ({ value }) => (
          <div className={`${styles.cell} ${styles.dateCell}`}>
            <img src={calendarIcon} alt="" />
            <span>{formatDate(new Date(value), 'fr')}</span>
          </div>
        ),
      },
      {
        Header: t('channels.interactions'),
        disableSortBy: false, // Disable sorting on this column
        id: 'interactions', // Use a unique ID for complex accessors
        sortType: (rowA, rowB) =>
          (rowA.original.comments ? (rowA.original.comments.length as number) : 0) -
          (rowB.original.comments ? (rowB.original.comments.length as number) : 0),
        //  +
        // (rowA.original.likeCount as number) -
        // ((rowB.original.comments ? (rowB.original.comments.length as number) : 0) +
        //   (rowB.original.likeCount as number)),
        accessor: (row) => row,
        Cell: ({ value }) => (
          <div className={`${styles.cell} ${styles.interactionCell}`}>
            <div className={styles.interaction}>
              <img src={greenReplyIcon} alt="" />
              <span>{formatNumber(value.repliedCommentsCount ?? 0)}</span>
            </div>

            <div className={styles.interaction}>
              <img src={commentIcon} alt="" />
              <span>{formatNumber(value.comments ? value.comments.length : 0)}</span>
            </div>

            <div className={styles.interaction}>
              <img src={likeIcon} alt="" />
              <span>{formatNumber(value.likeCount)}</span>
            </div>

            <div className={styles.interaction}>
              <img src={viewIcon} alt="" />
              <span>
                {value.mediaType?.includes('VIDEO')
                  ? formatNumber(value.views ?? 0)
                  : '-'}
              </span>
            </div>

            {/* <div className={styles.interaction}>
              <img src={shareIcon} alt="" />
              <span>{formatNumber(value.shares ?? 0)}</span>
            </div> */}
          </div>
        ),
      },
    ],
    [],
  );

  const data = React.useMemo(() => {
    return filteredPosts && filteredPosts.length > 0
      ? filteredPosts
          .filter((p) => p.post_id)
          .map((post) => ({
            ...post,
            thumbnail_url: post.thumbnail_url,
            timestamp: post.timestamp,
            interactions: post.likeCount + (post.comments ? post.comments.length : 0),
          }))
      : [];
  }, [filteredPosts]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setSortBy } =
    useTable(
      {
        columns,
        data,
        // Define initial state with sorting
        initialState: {
          sortBy: [
            {
              id: 'timestamp',
              desc: true, // Change to false if you want oldest first
            },
          ],
        },
      },
      useSortBy,
    ); // Make sure to apply the useSortBy plugin

  useEffect(() => {
    const sortPostsByDateAndComments = (posts: Post[]) => {
      // Sort posts by timestamp in descending order to get the most recent posts first
      const postsSortedByTimestamp = posts.sort(
        (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
      );

      // Group sorted posts by day part of timestamp
      const groupedByDay: Record<string, Post[]> = postsSortedByTimestamp.reduce(
        (acc, post) => {
          const day = post.timestamp.split('T')[0]; // Assuming timestamp is ISO string
          if (!acc[day]) {
            acc[day] = [];
          }
          acc[day].push(post);
          return acc;
        },
        {},
      );

      // Sort each group by the number of comments in descending order
      const sortedAndGroupedPosts = Object.values(groupedByDay).flatMap((group) =>
        group.sort((a, b) => b.comments.length - a.comments.length),
      );

      return sortedAndGroupedPosts;
    };

    if (isLoadingPosts) {
      setIsLoading(true);
    } else if (!isLoadingPosts && initialPosts) {
      const sortedPosts = sortPostsByDateAndComments(initialPosts);
      setPosts(sortedPosts);
      setIsLoading(false);
    }
  }, [initialPosts, isLoadingPosts]);

  useEffect(() => {
    // Load filters from cookies
    const serializedFilters = Cookies.get(`${channel_id}-filters`);
    if (serializedFilters) {
      const savedFilters = JSON.parse(serializedFilters);

      // Set loaded filters, maintaining separate control over dateRange
      setFilters(savedFilters);
      applyFilters(savedFilters);
    }
  }, []); // The empty array ensures this effect runs only once on mount

  return (
    <div className={styles.root}>
      {toggleFilterOpened && (
        <FiltersPopup
          onClose={() => setToggleFilterOpened(!toggleFilterOpened)}
          channels={[channel]}
          user={user}
          stripeData={stripeData}
          filters={filters}
          setFilters={setFilters}
          applyFilters={applyFilters}
          filterPostsWithComments={true}
        />
      )}

      <div className={styles.profileHeader}>
        <div className={styles.accountIconContainer}>
          <ImageLoader
            src={profile_picture_url}
            alt=""
            className={styles.accountIcon}
            width={'100%'}
            height={'100%'}
            borderRadius={100}
          />
          <img src={CHANNEL_ICONS[channel.type]} alt="" className={styles.channelIcon} />
        </div>

        <div>
          <div className={styles.channelName}>{channel_name}</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={styles.username}>@{username}</div>
            {syncStatus == 'syncing' && (
              <Lottie
                animationData={loaderBlueAnimation}
                loop={true}
                style={{ width: 20, height: 20, marginTop: '6px', marginLeft: '10px' }}
              />
            )}
          </div>
        </div>

        {/* Vertical Divider */}
        <div className={styles.dividerVertical}></div>

        {/* Metrics Container */}
        <div className={styles.metricsContainer}>
          <div className={styles.metricsRow}>
            <div className={styles.metricItem}>
              <img src={postIcon} alt="" />
              <div className={styles.textContainer}>
                <span>
                  {channel.posts_count || channel.posts_count == 0
                    ? formatNumber(posts.length)
                    : '-'}
                </span>
                <p>{t('publications')}</p>
              </div>
            </div>

            <div className={styles.metricItem}>
              <img src={followerIcon} alt="" />
              <div className={styles.textContainer}>
                <span>
                  {channel.followers_count || channel.followers_count == 0
                    ? formatNumber(channel.followers_count)
                    : '-'}
                </span>
                <p>{t('followers')}</p>
              </div>
            </div>
          </div>

          <div className={styles.metricsRow}>
            {/* <div className={styles.metricItem}>
              <img src={likeIcon} alt="" />
              <div className={styles.textContainer}>
                {channel.likes_count || channel.likes_count == 0
                  ? formatNumber(channel.likes_count)
                  : '-'}
                <p>{t('likes')}</p>
              </div>
            </div> */}

            <div className={styles.metricItem}>
              <img src={greenReplyIcon} alt="" />
              <div className={styles.textContainer}>
                <span>
                  {channel.replies_count || channel.replies_count == 0
                    ? formatNumber(channel.replies_count)
                    : '-'}
                </span>
                <p>{t('replies')}</p>
              </div>
            </div>

            <div className={styles.metricItem}>
              <img src={commentIcon} alt="" />
              <div className={styles.textContainer}>
                <span>
                  {channel.comments_count || channel.comments_count == 0
                    ? formatNumber(channel.comments_count)
                    : '-'}
                </span>
                <p>{t('comments')}</p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.buttonsContainer}>
          {/* <Menu
            opened={toggleFilterOpened}
            shadow="md"
            position="bottom-end"
            offset={10}
            styles={{
              dropdown: {
                padding: '10px 6px',
                borderRadius: '8px',
                background: '#FFFFFF',
                boxShadow: '0px 40px 96px -8px #585C5F33',
              },
              label: {
                cursor: 'pointer',
                marginBottom: '6px',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#525866',
              },
              item: {
                marginTop: '6px',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
              },
            }}
          >
            <Menu.Target>
              <button
                onClick={() => setToggleFilterOpened((o) => !o)}
                className={styles.filterButton}
              >
                <img src={filterIcon} alt="" />
                {t('Filter')}
              </button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>{t('filterByCategory')}</Menu.Label>

              <div style={{ padding: '0 12px' }}>
                <Menu.Divider style={{ width: '100%' }} />
              </div>

              <Menu.Item>
                <Checkbox
                  label={t('OrganicPosts')}
                  checked={filters.organic}
                  onChange={() => handleFilterChange('organic')}
                />
              </Menu.Item>

              <Menu.Item>
                <Checkbox
                  label={t('SponsoredPosts')}
                  checked={filters.ads}
                  onChange={() => handleFilterChange('ads')}
                />
              </Menu.Item>

              <div style={{ padding: '0 12px' }}>
                <Menu.Divider style={{ width: '100%' }} />
              </div>

              <div style={{ padding: '10px 6px' }}>
                <button
                  onClick={applyFilters}
                  className={styles.applyFilterButton} // Define this style in your CSS
                >
                  {t('ApplyFilters')}
                </button>
              </div>
            </Menu.Dropdown>
          </Menu> */}

          <button
            onClick={() => setToggleFilterOpened(!toggleFilterOpened)}
            className={styles.filterButton}
          >
            <img src={filterIcon} alt="" />
            {t('Filter')}
          </button>

          <Menu
            opened={toggleSettingOpened}
            onChange={setToggleSettingOpened}
            shadow="md"
            position="bottom-end"
            offset={10}
            styles={{
              dropdown: {
                padding: '10px 6px',
                borderRadius: '8px',
                background: '#FFFFFF',
                boxShadow: '0px 40px 96px -8px #585C5F33',
              },
              label: {
                marginBottom: '6px',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#525866',
              },
              item: {
                marginTop: '6px',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
              },
            }}
          >
            <Menu.Target>
              <button
                onClick={() => setToggleSettingOpened((o) => !o)}
                className={styles.settingsButton}
              >
                <img src={settingButton} alt="" />
              </button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>{t('channels.settings')}</Menu.Label>

              <div style={{ padding: '0 12px' }}>
                <Menu.Divider style={{ width: '100%' }} />
              </div>

              <Menu.Item
                color="#DF1C41"
                leftSection={
                  <img
                    style={{ width: '20px', height: '20px' }}
                    src={LogoutIcon}
                    alt=""
                  />
                }
                onClick={confirmDelete}
              >
                {t('channels.logout')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>

      <hr className={styles.divider} />

      <div className={styles.tableContainer}>
        <table style={{ width: '100%' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={column.id}
                    className={styles.header}
                  >
                    <div className={styles.headerCell}>
                      {column.render('Header')}
                      {column.isSorted && !column.disableSortBy ? (
                        column.isSortedDesc ? (
                          <img src={arrowBottom} alt="" />
                        ) : (
                          <img src={arrowTop} alt="" />
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              // const isLastRow = index === rows.length - 1; // Check if this is the last row
              return (
                <tr
                  {...row.getRowProps({
                    onClick: () => navigate(`/posts/${row.original.post_id}`), // Navigate to post page
                    className: styles.rowClickable, // Add a class for styling
                    // ref: isLastRow ? lastRowRef : null, // Only assign the ref to the last row
                  })}
                  key={row.id}
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      key={cell.column.id}
                      className={styles.bodyCell}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* {isLoading ? (
          <div className={styles.loader}>
            <Lottie
              animationData={loaderBlueAnimation}
              loop={true}
              style={{ width: 35, height: 35 }}
            />
          </div>
        ) : (
          <div className={styles.loader}>
            <button className={styles.loadMore} onClick={() => loadMorePosts()}>
              {t('loadMore')}
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export { Channels };
