import { useAuth0 } from '@auth0/auth0-react';
import { Channel } from '@axios/mutations';
import { StripeData, useChannels, User, useUser, useUsers } from '@axios/queries';
import ImageLoader from '@components/image-loader/ImageLoader';
import PopupComponent from '@components/popup/PopupComponent';
import ChoosePlanPopup from '@components/setting/choose-plan-popup/ChoosePlanPopup';
import { LogoTextSVG } from '@images';
import addIcon from '@images/add_icon.svg';
import checkFill from '@images/check-fill.svg';
import closeButton from '@images/close_button_icon.svg';
import elonIcon from '@images/elon_icon.svg';
import errorIcon from '@images/error_icon.png';
import facebookSVG from '@images/facebook.svg';
import homeIcon from '@images/home_icon.svg';
import instagramSVG from '@images/instagram.svg';
import openButton from '@images/open_button_icon.svg';
import settingIcon from '@images/setting_icon.svg';
import taskIcon from '@images/task_icon.svg';
import tiktokSVG from '@images/tiktok.svg';
import creditIcon from '@images/token_icon.svg';
import { Select } from '@mantine/core';
import { useAdminStore, useNavbarStore, usePopupStore } from '@redux/store';
import { Path } from '@router';
import Cookies from 'js-cookie';
import { capitalize } from 'lodash';
import * as moment from 'moment-timezone';
import React, { cloneElement, ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ChartBar, Logout, SmartHome } from 'tabler-icons-react';
import { useStore } from 'zustand';

import ChannelItem from './channel-item/ChannelItem';
import channelsData from './connect-channels/connectChannel.config';
import ConnectChannels from './connect-channels/ConnectChannels';
import styles from './NavBar.module.scss';

type Tab = {
  icon: ReactElement;
  name: string;
  path: Path;
  badge?: number; // Optional badge number
  color: string;
};

type NavBarProps = {
  activePath?: Path;
  channels: Channel[];
  user_id: string;
  userData: User;
  stripeData: StripeData;
};

const NavBar = ({
  activePath: activePathProp = '/',
  channels: initialChannels = [],
  user_id,
  stripeData,
  userData,
}: NavBarProps) => {
  // Use zustand store for navbar state
  const { isNavbarExpanded, toggleNavbar } = useNavbarStore();

  const { user } = useAuth0();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const setPopup = usePopupStore((state) => state.setPopup); // Use the setPopup function
  const { popupComponent, pathToShow } = usePopupStore();

  const [channels, setChannels] = useState(initialChannels);

  const today = new Date(); // Today's date
  const timeDiff = stripeData?.trial_end
    ? new Date(stripeData?.trial_end).getTime() - today.getTime()
    : null; // Difference in milliseconds
  const daysUntilTrialEnd = Math.round(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days

  const { refetch: refreshChannels } = useChannels(
    { user_id: user?.sub },
    {
      onSuccess: (channels) => {
        setChannels(channels);
      },
      enabled: false,
    },
  );

  const [activePath, setActivePath] = useState<Path>(activePathProp);

  const currentCommentCount = userData?.currentCommentCount;
  const nextReset = userData?.nextQuotaReset;
  const quotaComment = userData?.commentLimitPerMonth;
  const commentsLeft = quotaComment - currentCommentCount;

  const handleTabClick = (path: Path) => {
    setActivePath(path);
    navigate(path);
  };

  const CHANNEL_ICONS = {
    facebook: facebookSVG,
    instagram: instagramSVG,
    tiktok: tiktokSVG,
  };

  // // Adjust frameTime to consider frameTimeDays and start from the beginning of that day, in Paris timezone
  // const frameTime = moment.tz('Europe/Paris').startOf('day');

  // // Convert frameTime to the beginning of the day for comparison, still using Paris timezone
  // const targetDay = frameTime.clone(); // clone to keep original frameTime unchanged if needed

  // // Check if tasks for the target day have already been assigned
  // const todayTasks = userData.dailyTasks
  //   ? userData.dailyTasks[targetDay.format('YYYY-MM-DD')]
  //   : [];

  // const tasksLeft = todayTasks?.filter((t) => t.status == 'pending');

  // Define your tabs and sections
  const toolTabs: Tab[] = [
    {
      name: t('navBar.home'),
      path: '/dashboard',
      icon: <img src={homeIcon} alt="" />,
      color: '#2970FF',
    },
    {
      name: t('navBar.task'),
      path: '/task',
      icon: <img src={taskIcon} alt="" />,
      // badge: tasksLeft?.length,
      color: '#DD2590',
    },
    {
      name: t('navBar.setting'),
      path: '/setting',
      icon: <img src={settingIcon} alt="" />,
      color: '#F79009',
    },
  ];

  const openPopupAddChannel = () => {
    setPopup(<ConnectChannels user_id={user_id} channels={channels} user={userData} />);
  };

  const openPlanPopup = () => {
    setPopup(<ChoosePlanPopup user={userData} onClose={() => setPopup(null, null)} />);
  };

  const openPopupNoTokensLeft = () => {
    setPopup(
      <PopupComponent
        title={t('popupQuotaExceeded.title')}
        description={t('popupQuotaExceeded.description')}
        buttonText={t('popupQuotaExceeded.buttonText')}
        icon={errorIcon}
        onCancel={() => setPopup(null, null)}
        onCancelButtonText={t('Cancel')}
        onConfirm={() => {
          window.open(stripeData.customer_portal, '_blank');
        }}
      />,
    );
  };

  // Fonction pour ouvrir le portail client Stripe dans un nouvel onglet
  const handleManageSubscriptionClick = () => {
    window.open(stripeData.customer_portal, '_blank');
  };

  // Subscribe to popup state changes
  useEffect(() => {
    if (user?.sub) {
      void refreshChannels();
    }
  }, [refreshChannels, popupComponent, pathToShow, channels]);

  // Subscribe to popup state changes
  useEffect(() => {
    const hasVisitedToday = (channelId) => {
      const lastVisited = Cookies.get(`last-popup-time-${channelId}`);
      if (!lastVisited) return false;

      const lastVisitedDate = new Date(parseInt(lastVisited));
      const currentDate = new Date();

      // Check if last visited date is the same as the current date
      return (
        lastVisitedDate.getDate() === currentDate.getDate() &&
        lastVisitedDate.getMonth() === currentDate.getMonth() &&
        lastVisitedDate.getFullYear() === currentDate.getFullYear()
      );
    };

    if (channels && channels.length > 0) {
      for (const channel of channels) {
        if (!channel.isConnected && !hasVisitedToday(channel._id)) {
          openPopupAddChannel();

          // Update the cookie with the current timestamp, unique for each channel
          const now = new Date().getTime();
          Cookies.set(`last-popup-time-${channel._id}`, now.toString(), {
            expires: 1,
          }); // Expires in 1 day
          break; // Ensures the popup is triggered only once per check for each channel
        }
      }
    }
  }, [channels]);

  // Subscribe to popup state changes
  useEffect(() => {
    const hasVisitedToday = (user_id: string) => {
      const lastVisited = Cookies.get(`last-popup-time-${user_id}`);
      if (!lastVisited) return false;

      const lastVisitedDate = new Date(parseInt(lastVisited));
      const currentDate = new Date();

      // Check if last visited date is the same as the current date
      return (
        lastVisitedDate.getDate() === currentDate.getDate() &&
        lastVisitedDate.getMonth() === currentDate.getMonth() &&
        lastVisitedDate.getFullYear() === currentDate.getFullYear()
      );
    };

    const tokensLeft = userData?.commentLimitPerMonth - userData?.currentCommentCount;

    if (tokensLeft <= 0 && !hasVisitedToday(user_id)) {
      openPopupNoTokensLeft();

      // Update the cookie with the current timestamp, unique for each channel
      const now = new Date().getTime();
      Cookies.set(`last-popup-time-${user_id}`, now.toString(), {
        expires: 1,
      }); // Expires in 1 day
    }
  }, [channels]);

  return (
    <div
      className={`${styles.navBar} ${isNavbarExpanded ? '' : styles.collapsed}`}
      style={channels.length >= 3 ? { gap: '24px' } : {}}
    >
      <div className={styles.headerContainer}>
        <div
          className={`${styles.logo} ${isNavbarExpanded ? '' : styles.collapsed}`}
          onClick={() => navigate('/')}
        >
          <LogoTextSVG width={isNavbarExpanded ? '70px' : '50px'} />
        </div>

        <button
          onClick={toggleNavbar}
          className={`${styles.toggleButton} ${isNavbarExpanded ? '' : styles.collapsed}`}
        >
          {isNavbarExpanded ? (
            <img src={closeButton} width={'24px'} alt="" />
          ) : (
            <img src={openButton} width={'20px'} alt="" />
          )}
        </button>
      </div>

      {/* Section for "Mon CM" */}
      <div className={styles.section}>
        <div className={styles.sectionTitle}>{t('navBar.myCM')}</div>
        <div
          className={`${styles.tab} ${styles.cmTab} ${
            activePath === ('/elon' as Path) ? styles.active : ''
          }`}
          onClick={() => handleTabClick('/elon' as Path)}
        >
          <img src={elonIcon} className={styles.cmIcon} alt="" />
          {isNavbarExpanded && (
            <div>
              <div className={styles.tabTitle}>{t('navBar.chatWithElon')}</div>
              <div className={styles.tabDescription}>{t('navBar.h24')}</div>
            </div>
          )}
        </div>
      </div>

      {/* Section for "Tools" */}
      <div className={styles.section}>
        <div className={styles.sectionTitle}>{t('navBar.tools')}</div>
        {toolTabs.map((tab, tabIndex) => (
          <div
            key={tab.path}
            className={`${styles.tab} ${activePath === tab.path ? styles.active : ''} ${
              isNavbarExpanded ? '' : styles.collapsed
            }`}
            onClick={() => handleTabClick(tab.path)}
          >
            {tab.icon}
            {isNavbarExpanded && <span className={styles.tabName}>{tab.name}</span>}

            {tab.badge && (
              <div
                className={`${styles.badge} ${isNavbarExpanded ? '' : styles.collapsed}`}
                style={{ backgroundColor: tab.color }}
              >
                {tab.badge}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Section for "Connected Accounts" */}
      <div
        className={styles.section}
        style={{ gap: `${isNavbarExpanded ? '8px' : '16px'}` }}
      >
        <div className={styles.sectionTitle}>
          {isNavbarExpanded ? t('navBar.connectedAccounts') : t('navBar.accounts')}
        </div>

        {channels.map((channel, index) => (
          <div
            key={index}
            className={`${styles.tab} ${styles.accountTab} ${
              activePath === (`/channels/${channel.channel_id}` as Path)
                ? styles.active
                : ''
            }`}
            onClick={() => handleTabClick(`/channels/${channel.channel_id}` as Path)}
          >
            <div className={styles.accountIconContainer}>
              <ImageLoader
                src={channel.profile_picture_url}
                alt={''}
                className={styles.accountIcon}
                borderRadius={100}
                width={40}
                height={40}
              />

              {/* <img
                src={channel.profile_picture_url}
                alt={''}
                className={styles.accountIcon}
              /> */}
              <img
                src={CHANNEL_ICONS[channel.type]}
                alt={''}
                className={`${styles.channelIcon} ${
                  activePath === (`/channels/${channel.channel_id}` as Path)
                    ? styles.active
                    : ''
                }`}
              />
            </div>

            {isNavbarExpanded && (
              <div style={{ overflow: 'hidden' }}>
                {/* <div className={styles.tabTitle}>{channel.name}</div> */}
                <div className={styles.tabTitle}>{channelsData[channel.type].name}</div>

                {!channel.isConnected ? (
                  <div className={styles.error}>{t('errorReconnectChannel')}</div>
                ) : (
                  <div className={styles.tabDescription}>{channel.username}</div>
                )}
              </div>
            )}
          </div>
        ))}

        {channels.length < 3 && (
          <div
            className={`${styles.tab} ${styles.addAccount}`}
            style={{ marginTop: '8px' }}
            onClick={() => {
              openPopupAddChannel();
            }}
          >
            <img src={addIcon} alt="" />
            {isNavbarExpanded && (
              <div>
                <div
                  className={`${styles.tabTitle} ${
                    isNavbarExpanded ? '' : styles.collapsed
                  }`}
                >
                  {t('navBar.addAccount')}
                </div>
                <div
                  className={`${styles.tabDescription}  ${
                    isNavbarExpanded ? '' : styles.collapsed
                  }`}
                >
                  {t('navBar.socialNetworks')}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className={styles.lastSection}>
        {isNavbarExpanded && (
          <div className={styles.tokensContainer}>
            <div className={styles.valueContainer}>
              <img alt="" src={creditIcon} width={'24px'} />

              <div className={styles.textContainer}>
                <p className={styles.title}>
                  {commentsLeft > 1
                    ? t('tasks.popup.commentsLeft')
                    : t('tasks.popup.commentLeft')}
                </p>
                <p className={styles.value}>
                  {commentsLeft} / {quotaComment}
                </p>
              </div>
            </div>

            {!stripeData?.subscription_id && (
              <div className={styles.endTrial}>
                {`${t('freeTrial')} (${daysUntilTrialEnd.toString()} ${
                  daysUntilTrialEnd > 1 ? t('daysLeft') : t('dayLeft')
                })`}
              </div>
            )}

            <div
              className={styles.planEdit}
              onClick={() =>
                stripeData?.subscription_id && stripeData.status != 'canceled'
                  ? handleManageSubscriptionClick()
                  : openPlanPopup()
              }
            >
              {t('setting.subscription.editPlan')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { NavBar };
