import { useAuth0 } from '@auth0/auth0-react';
import {
  useCompleteOnboarding,
  // useCreateSetupIntents,
  // useCreateSubscription,
  useUpdateUser,
} from '@axios/mutations';
import { StripeData, useChannels, User, useUser } from '@axios/queries';
import { LanguageSwitcher, ScreenLoader } from '@components';
// import ChoosePlan from '@components/onboarding/choose-plan/ChoosePlan';
import { PlanDetailsObj } from '@components/onboarding/choose-plan/plan-details/PlanDetails';
import ConnectChannels from '@components/onboarding/connect-channels/ConnectChannels';
// import Payment from '@components/onboarding/payment/Payment';
import { PLANS } from '@components/onboarding/plan.config';
import UserDetails from '@components/onboarding/user-details/UserDetails';
// import PopupComponent from '@components/popup/PopupComponent';
import { LogoTextSVG } from '@images';
// import elonIcon from '@images/elon_custom_icon.png';
import { usePopupStore } from '@redux/store';
import { Path, useNavigate } from '@router';
import { changeLanguage } from 'i18next';
import React, { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
// import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import TiktokPixel from 'tiktok-pixel';

import styles from './Onboarding.module.scss';
import PathComponent from './path-component/PathComponent';

type OnboardingProps = {
  user: User;
  connectedChannels: Record<string, boolean>;
  // stripeData: StripeData;
  referral_id: string;
};

export type userDetails = {
  email: string;
  name: string;
  user_id: string;
};

const Onboarding = ({
  user,
  connectedChannels,
  // stripeData,
  referral_id,
}: OnboardingProps) => {
  const { t } = useTranslation();

  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 700);

  console.log('REFERRAL ID:', referral_id);
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth0();

  const setPopup = usePopupStore((state) => state.setPopup); // Use the setPopup function

  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [setupIntentId, setSetupIntentId] = useState<string | null>(null);

  const [onboardingDone, setOnboardingDone] = useState(false);

  // const { mutateAsync: createSetupIntent, isLoading: isSetupIntentLoading } =
  //   useCreateSetupIntents();

  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch: refetchUserData,
  } = useUser(user.user_id);

  const {
    data: channels,
    isLoading: isLoadingChannels,
    refetch: refreshChannels,
  } = useChannels({ user_id: user.user_id });

  // const [step, setStep] = useState(
  //   user.stripe_subscription_id &&
  //     (stripeData.status == 'active' || stripeData.status == 'trialing')
  //     ? 4
  //     : 1,
  // );
  const [step, setStep] = useState(1);

  // const { mutateAsync: createSubscription } = useCreateSubscription();

  // Updated useUpdateUser hook with onSuccess callback
  const { mutateAsync: updateUser } = useUpdateUser(user.user_id, {
    onSuccess: async () => {
      await refetchUserData();
      return;
    },
    onError: (error) => {
      console.error('Error updating user:', error);
    },
  });

  // Updated useUpdateUser hook with onSuccess callback
  const { mutateAsync: completeOnboardingHook } = useCompleteOnboarding({
    onSuccess: async (data) => {
      // await refetchUserData();

      ReactPixel.init('811682251011747');

      // Track the 'CompleteRegistration' event or a custom event for onboarding completion
      ReactPixel.track('CompleteRegistration', {
        content_name: 'Onboarding Completed',
        status: 'Completed',
      });

      const advancedMatching = {
        email: userData.email,
      }; // optional, more info: https://ads.tiktok.com/help/article?aid=10007891

      // Pixel initialization options
      const options = {
        debug: true, // Enable logs for debugging
      };

      await TiktokPixel.init('CO9S4IBC77UESEAR7H8G', advancedMatching, options);

      TiktokPixel.pageView(); // For tracking page view
      // Place this in the component or function where the onboarding completes
      TiktokPixel.track('CompleteRegistration', {
        content_name: 'Onboarding Completed',
        status: 'Completed',
      });

      return data;
    },
    onError: (error) => {
      console.error('Error completing onboarding:', error);
    },
  });

  const handleStepChange = async (nextStep: number, data?: any) => {
    console.log('NEXT STEP:', nextStep);
    if (nextStep == 2) {
      // Update user after channel mutations
      await updateUser({
        user_id: userDetails.user_id,
        name: userDetails.name,
        email: userDetails.email,
      });
    }

    // if (nextStep == 3) {
    //   if (!clientSecret && !isSetupIntentLoading) {
    //     await createSetupIntent({ user_id: userDetails.user_id, referral_id }).then(
    //       ({ clientSecret, setupIntentId }) => {
    //         setClientSecret(clientSecret);
    //         setSetupIntentId(setupIntentId);
    //       },
    //     );
    //   }
    // }

    // if (nextStep == 4) {
    //   if (userData?.onboarding_done && channels?.length) {
    //     const fineTuneJob = (await completeOnboardingHook({})) as unknown as string;
    //     completeOnboarding(fineTuneJob);
    //   }
    // }

    if (nextStep == 5) {
      const fineTuneJob = (await completeOnboardingHook({})) as unknown as string;

      // await refetchUserData(); // Refetch user data to ensure it's updated
      // await refreshChannels();
      completeOnboarding(fineTuneJob);
    }

    if (nextStep != 5) {
      setStep(nextStep);
    }
  };

  const completeOnboarding = (fineTuneJob: string) => {
    // if (!fineTuneJob) {
    //   setPopup(
    //     <PopupComponent
    //       title={t('onboarding.popupComplete.title')}
    //       description={t('onboarding.popupComplete.description')}
    //       buttonText={t('onboarding.popupComplete.startButton')}
    //       icon={elonIcon}
    //       onConfirm={() => {
    //         setPopup(null, null);
    //       }}
    //     />,
    //     '/',
    //   );
    // }

    setOnboardingDone(true);
    console.log('ONBOARDING DONE NAVIGATING TO DASHBOARD');

    // Delay navigation by 3 seconds (3000 milliseconds)
    setTimeout(() => {
      // navigate('/');
      // window.location.href = 'http://localhost:3005';
      window.location.href =
        import.meta.env.VITE_NODE_ENV == 'development'
          ? 'http://localhost:3005'
          : 'https://app.nswr.ai';
    }, 2000);
  };

  const [userDetails, setUserDetails] = useState({
    email: user.email,
    name: user.name,
    user_id: user.user_id,
  } as userDetails);

  const [selectedPlan, setSelectedPlan] = useState(2);

  const [selectedChannels, setSelectedChannels] = useState({
    channel_ids: ['instagram', 'facebook', 'tiktok'],
    channels: 0,
    overLimit: 0,
  });

  const [planDetails, setPlanDetails] = useState<PlanDetailsObj>({
    id: selectedPlan,
    name: t(PLANS[selectedPlan].name),
    description: t(PLANS[selectedPlan].description),
    features: PLANS[selectedPlan].features as any,
    monthlyPrice: PLANS[selectedPlan].monthlyPrice,
    yearlyPrice: PLANS[selectedPlan].yearlyPrice,
    billingCycle: 'yearly',
  });

  // Callbacks to update state from child components
  // const handlePlanSelect = (plan: { id: number; billingCycle: 'yearly' | 'monthly' }) => {
  //   console.log('PLAN:', plan);

  //   setSelectedPlan(plan.id);
  //   console.log(PLANS[plan.id]);

  //   setPlanDetails({
  //     id: plan.id,
  //     name: t(PLANS[plan.id].name),
  //     description: t(PLANS[plan.id].description),
  //     features: PLANS[plan.id].features as any,
  //     monthlyPrice: PLANS[plan.id].monthlyPrice,
  //     yearlyPrice: PLANS[plan.id].yearlyPrice,
  //     billingCycle: plan.billingCycle,
  //   });

  //   // TODO REMOVE AFTER BETA TEST -- WITHOUT CB
  //   // ######
  //   const newPlanDetails = {
  //     id: plan.id,
  //     name: t(PLANS[plan.id].name),
  //     description: t(PLANS[plan.id].description),
  //     features: PLANS[plan.id].features as any,
  //     monthlyPrice: PLANS[plan.id].monthlyPrice,
  //     yearlyPrice: PLANS[plan.id].yearlyPrice,
  //     billingCycle: plan.billingCycle,
  //   };

  //   // let newClientSecret = clientSecret;
  //   // if (!clientSecret && !isSetupIntentLoading) {
  //   //   await createSetupIntent({ user_id: userDetails.user_id, referral_id }).then(
  //   //     ({ clientSecret, setupIntentId }) => {
  //   //       newClientSecret = clientSecret;

  //   //       setClientSecret(clientSecret);
  //   //       setSetupIntentId(setupIntentId);
  //   //     },
  //   //   );
  //   // }

  //   // await createSubscription({
  //   //   referral_id,
  //   //   billingCycle: newPlanDetails.billingCycle,
  //   //   planId: newPlanDetails.id.toString(),
  //   //   addonQuantity: null,
  //   //   setupIntentClientSecret: newClientSecret,
  //   //   user_id: userDetails.user_id,
  //   // });
  //   // #########
  // };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <UserDetails
            onStepChange={handleStepChange}
            setUserDetails={setUserDetails}
            userDetails={userDetails}
            user={userData}
          />
        );
      // case 2:
      //   return (
      //     <ChoosePlan onStepChange={handleStepChange} onPlanSelect={handlePlanSelect} />
      //   );
      // case 3:
      //   return (
      //     <Payment
      //       referral_id={referral_id}
      //       planDetails={planDetails}
      //       onStepChange={handleStepChange}
      //       userDetails={userDetails}
      //       planId={planDetails.id.toString()}
      //       clientSecret={clientSecret}
      //       setupIntentId={setupIntentId}
      //     />
      //   );
      case 2:
        return (
          <ConnectChannels
            alreadyConnectedChannels={connectedChannels}
            user_id={user.user_id}
            onStepChange={handleStepChange}
            selectedChannels={selectedChannels.channel_ids}
          />
        );
      // Continue for other steps
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < 600);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures this effect runs only on mount and unmount

  // // Fonction pour ouvrir le portail client Stripe dans un nouvel onglet
  // const handleManageSubscriptionClick = () => {
  //   window.open(stripeData.customer_portal, '_blank');
  // };

  if (onboardingDone) {
    return <ScreenLoader />;
  }

  return (
    <div className={styles.onboardingContainer}>
      <header
        className={`${styles.onboardingHeader}`}
        style={isScreenSmall ? { justifyContent: 'center' } : {}}
      >
        <LogoTextSVG width="90px" />

        {!isScreenSmall && (
          <PathComponent currentStep={step} onStepChange={handleStepChange} />
        )}
      </header>

      <div className={styles.stepContent}>{renderStep()}</div>

      <footer
        className={styles.onboardingFooter}
        style={
          isScreenSmall
            ? {
                justifyContent: 'left',
                marginLeft: isScreenSmall ? '4px' : '12px',
                gap: isScreenSmall ? '12px' : '24px',
              }
            : {}
        }
      >
        {!isScreenSmall && (
          <>
            <span className={styles.footerText}>&copy; 2024 NSWR LTD.</span>
            <div className={styles.dividerVertical}></div>
            <button onClick={() => logout()} className={`${styles.logoutButton}`}>
              {t('logout')}
            </button>
          </>
        )}

        {isScreenSmall && (
          <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
            {/* {stripeData && stripeData.customer_portal && (
            <>
              <button
                onClick={handleManageSubscriptionClick}
                className={styles.logoutButton}
              >
                {t('myPlan')}
              </button>

              <div className={styles.dividerVertical}></div>
            </>
          )} */}

            <button onClick={() => logout()} className={`${styles.logoutButton}`}>
              {t('logout')}
            </button>
          </div>
        )}

        <div className={styles.dividerVertical}></div>
        <LanguageSwitcher user={userData} />
      </footer>
    </div>
  );
};

export { Onboarding };
