/* eslint-disable max-len */
import { FacebookPage, InstagramAccount } from '@axios/queries';
import ImageLoader from '@components/image-loader/ImageLoader';
import arrowRight from '@images/arrow-right-line.svg';
import arrowRightDisabled from '@images/arrow-right-line-grey.svg';
import backButton from '@images/back_btn.svg';
import checkFill from '@images/check-fill.svg';
import sadIcon from '@images/sad_custom_icon.png';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import channelsData from '../connectChannel.config';
import styles from './ChoosePage.module.scss';

export interface BusinessPage {
  id: string;
  name: string;
  image: string;
  username: string;
}

interface ErrorInfo {
  title: string;
  description: JSX.Element;
  icon: string;
  tutorialLink?: string;
}

interface ChoosePageProps {
  onConfirmPage: (page: InstagramAccount | FacebookPage) => void;
  onCancel: () => void;
  networkAccounts: InstagramAccount[] | FacebookPage[];
  channel: string;
}

const ChoosePage: React.FC<ChoosePageProps> = ({
  onConfirmPage,
  onCancel,
  networkAccounts,
  channel,
}) => {
  const { t } = useTranslation();

  const [selectedPage, setSelectedPage] = useState<
    InstagramAccount | FacebookPage | null
  >(null);
  const [errorInfo, setErrorInfo] = useState<ErrorInfo | null>(null);

  const handleConfirm = () => {
    if (selectedPage) {
      onConfirmPage(selectedPage);
    }
  };

  const handleReturn = () => {
    onCancel();
  };

  useEffect(() => {
    if (!networkAccounts || networkAccounts.length === 0) {
      setErrorInfo({
        title: t('onboarding.connectChannel.choosePage.error.title'),
        description: (
          <p className={styles.errorDescription}>
            {t('onboarding.connectChannel.choosePage.error.errorDescription')}{' '}
            <b>{t('onboarding.connectChannel.choosePage.error.boldDescription')}</b>{' '}
            {t('onboarding.connectChannel.choosePage.error.errorDescription2')}{' '}
            <b>{t('onboarding.connectChannel.choosePage.error.boldDescription2')}</b>.
            <p style={{ marginTop: '15px' }}>
              <a
                href={
                  channel == 'facebook'
                    ? 'https://nswr.notion.site/Welcome-to-NSWR-f609d554f3324ef7a1684d295712da7c'
                    : 'https://nswr.notion.site/Welcome-to-NSWR-f609d554f3324ef7a1684d295712da7c'
                }
                target="_blank"
                rel="noopener noreferrer"
                className={styles.tutorialLink}
              >
                {t('onboarding.connectChannel.choosePage.error.urlText')}
              </a>
            </p>
          </p>
        ),
        icon: sadIcon,
      });
    }
  }, [networkAccounts]);

  const handlePageSelect = (pageId: InstagramAccount) => {
    setSelectedPage((prevSelectedPage) => (prevSelectedPage === pageId ? null : pageId));
  };

  return (
    <div className={styles.choosePageContainer}>
      <button onClick={handleReturn} className={styles.returnButton}>
        <img src={backButton} alt="" />
      </button>

      <div className={styles.titleContainer}>
        <img
          src={errorInfo ? errorInfo.icon : channelsData[channel].customIcon}
          alt=""
          className={styles.customIcon}
        />

        <h2 className={styles.title}>
          {errorInfo ? errorInfo.title : t('onboarding.connectChannel.choosePage.title')}
        </h2>
        {!errorInfo && (
          <p className={styles.description}>
            {t('onboarding.connectChannel.choosePage.description')}
          </p>
        )}
      </div>

      <div className={styles.pagesContainer}>
        {errorInfo ? (
          errorInfo.description
        ) : (
          <p className={styles.choosePage}>
            {t('onboarding.connectChannel.choosePage.selectPage')}
          </p>
        )}

        {!errorInfo && networkAccounts && (
          <div className={styles.pageButtonsContainer}>
            {networkAccounts?.map((page) => (
              <button
                key={page.id}
                className={`${styles.pageButton} ${
                  selectedPage?.id === page.id ? styles.selected : ''
                }`}
                onClick={() => handlePageSelect(page)}
              >
                <ImageLoader
                  src={page.profile_picture_url}
                  alt={''}
                  className={styles.pageImage}
                  width={40}
                  height={40}
                />
                <div className={styles.pageInfoContainer}>
                  <div className={styles.pageText}>
                    <h3 className={styles.pageTitle}>{page.name}</h3>
                    {page.username && (
                      <p className={styles.pageUsername}>@{page.username}</p>
                    )}
                  </div>
                  {selectedPage?.id === page.id ? (
                    <img src={checkFill} alt="" className={styles.checkFill} />
                  ) : (
                    <img src={arrowRight} alt="" className={styles.arrowRight} />
                  )}
                </div>
              </button>
            ))}
          </div>
        )}
      </div>

      {!errorInfo && (
        <button
          onClick={handleConfirm}
          className={styles.confirmButton}
          disabled={!selectedPage}
        >
          {t('onboarding.connectChannel.choosePage.continue')}
          {selectedPage ? (
            <img src={arrowRight} alt="" className={styles.arrowIcon} />
          ) : (
            <img src={arrowRightDisabled} alt="" className={styles.arrowIcon} />
          )}
        </button>
      )}
    </div>
  );
};

export default ChoosePage;
