import { useAuth0 } from '@auth0/auth0-react';
import { useUpdateUser } from '@axios/mutations';
import { User } from '@axios/queries';
import { LanguageSwitcher } from '@components/language-switcher/LanguageSwitcher';
import ArrowIcon from '@images/arrow-right-s-line-blue.svg'; // Update with your actual import path
import LogoutIcon from '@images/logout-box-line.svg'; // Update with your actual import path
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ProfileComponent.module.scss';

interface ProfileComponentProps {
  user: User;
}

const ProfileComponent = ({ user }: ProfileComponentProps) => {
  const { isAuthenticated, logout } = useAuth0();
  const { t } = useTranslation();

  // Updated useUpdateUser hook with onSuccess callback
  const { mutateAsync: updateUser } = useUpdateUser(user.user_id);

  const handleUpdateUser = async () => {};

  return (
    <div className={styles.root}>
      <div className={styles.section}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{t('setting.profile.fullName')}</div>
        </div>

        <div className={styles.contentContainer}>
          <div className={styles.value}>{user.name}</div>
          {/* <div className={styles.editButton}>
            <button>Modifier</button>
            <img src={ArrowIcon} alt="" />
          </div> */}
        </div>
      </div>

      <div className={styles.separator} />

      <div className={styles.section}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{t('setting.profile.mail.title')}</div>
          <div className={styles.description}>
            {t('setting.profile.mail.description')}
          </div>
        </div>

        <div className={styles.contentContainer}>
          <div className={styles.value}>{user.email}</div>

          {/* <div className={styles.editButton}>
            <button>Modifier</button>
            <img src={ArrowIcon} alt="" />
          </div> */}
        </div>
      </div>

      <div className={styles.separator} />
      <div className={styles.section}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{t('setting.profile.language')}</div>
        </div>

        <div className={styles.contentContainer}>
          <LanguageSwitcher user={user} />
        </div>
      </div>

      <div className={styles.separator} />

      <div className={styles.section}>
        <div className={styles.titleContainer}></div>

        <div className={styles.contentContainer}>
          <div className={styles.value}>
            <div className={styles.editButton}>
              <button onClick={() => logout()} className={styles.logoutButton}>
                {t('setting.profile.logout')}
                <img src={LogoutIcon} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileComponent;
