import React from 'react';
import { useTranslation } from 'react-i18next';
import { Check, ChevronRight } from 'tabler-icons-react';

import styles from './PathComponent.module.scss';

type PathComponentProps = {
  currentStep: number;
  onStepChange: (nextStep: number, data?: any) => void;
};

const PathComponent = ({ currentStep, onStepChange }: PathComponentProps) => {
  const { t } = useTranslation();

  const steps = [
    { number: 1, title: t('onboarding.path.step1') },
    // { number: 2, title: t('onboarding.path.step2') },
    // { number: 3, title: t('onboarding.path.step3') },
    { number: 2, title: t('onboarding.path.step4') },
  ];

  return (
    <div className={styles.pathContainer}>
      {steps.map((step, index) => (
        <React.Fragment key={step.number}>
          <div
            className={`${styles.step} ${
              currentStep === step.number ? styles.activeStep : ''
            } ${step.number < currentStep ? styles.doneStep : ''}
            ${currentStep < 4 && step.number < currentStep ? styles.clickableStep : ''}
            `}
            onClick={() => {
              step.number < currentStep && currentStep < 4 && onStepChange(step.number);
            }}
          >
            <div className={styles.circle}>
              {step.number < currentStep ? (
                <Check strokeWidth={'3px'} size={16} color="#FFFFFF" />
              ) : (
                step.number
              )}
            </div>
            <span className={styles.stepTitle}>{step.title}</span>
          </div>

          {index < steps.length - 1 && (
            <ChevronRight strokeWidth={'2.5px'} size={14} color="#868C98" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default PathComponent;
