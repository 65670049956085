/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
import { Channel } from '@axios/mutations';
import { Comment, Post } from '@axios/queries';
import ImageLoader from '@components/image-loader/ImageLoader';
import { formatTimeToNowShort } from '@utils/formatTimeToNowShort';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './TaskComponent.module.scss';

type TaskComponentProps = {
  channel: Channel;
  post: Post;
  comment: Comment;
};

const TaskComponent = ({ channel, post, comment }: TaskComponentProps) => {
  const { t } = useTranslation();

  // Utilize the formatTimeToNow utility function for the timestamp
  const formattedTimestamp = useMemo(
    () => formatTimeToNowShort(comment?.timestamp),
    [comment?.timestamp],
  );

  return (
    <div className={styles.taskComponent}>
      <div className={styles.userInfo}>
        <div className={styles.accountIconContainer}>
          {/* <ImageLoader
            src={post?.thumbnail_url}
            alt={''}
            className={styles.postMedia}
            width={40}
            height={40}
          /> */}
          <ImageLoader
            borderRadius={'50%'}
            src={
              comment.profilePictureUrl ??
              `https://api.dicebear.com/7.x/micah/svg?seed=${
                comment.username || comment.comment_id
              }&flip=true&radius=50&baseColor=ac6651,f9c9b6,transparent&eyeShadowColor=d2eff3,e0ddff,ffeba4,ffedef,ffffff,transparent&backgroundColor=ffdfbf,d1d4f9,c0aede,b6e3f4,ffd5dc`
            }
            src2={`https://api.dicebear.com/7.x/micah/svg?seed=${
              comment.username || comment.comment_id
            }&flip=true&radius=50&baseColor=ac6651,f9c9b6,transparent&eyeShadowColor=d2eff3,e0ddff,ffeba4,ffedef,ffffff,transparent&backgroundColor=ffdfbf,d1d4f9,c0aede,b6e3f4,ffd5dc`}
            alt=""
            width={'40px'}
            height={'40px'}
            className={styles.accountIcon}
          />
          {/* <img src={CHANNEL_ICONS[channel.type]} alt="" className={styles.channelIcon} /> */}
        </div>

        <div className={styles.postCaption}>
          {comment.username && (
            <strong style={{ color: '#101828' }}>@{comment.username} :</strong>
          )}
          {comment.text}
        </div>
      </div>

      <div className={styles.postDetails}>
        <div className={styles.postTimestamp}>{formattedTimestamp}</div>
        {/* <ImageLoader
          src={post.thumbnail_url}
          alt={''}
          className={styles.postMedia}
          width={40}
          height={40}
        /> */}
        {/* <img src={post.thumbnail_url} alt={''} className={styles.postMedia} /> */}
      </div>
    </div>
  );
};

export { TaskComponent };
