/* eslint-disable max-len */
import '@services/lang/i18n';
import 'react-toastify/dist/ReactToastify.css';

import { Auth0ProviderWithNav } from '@components';
import { MantineProvider } from '@mantine/core';
import { persistor, store } from '@redux/store';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';

// Sentry.init({
//   dsn: import.meta.env.VITE_SENTRY_DSN,
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     // Sentry.replayIntegration({
//     //   maskAllText: false,
//     //   blockAllMedia: false,
//     // }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', 'app.nswr.ai', /^https:\/\/api.nswr.ai/],
//   // // Session Replay
//   // replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

LogRocket.init('8kcd7g/nswr');
// after calling LogRocket.init()
setupLogRocketReact(LogRocket);

const rootDiv = document.getElementById('root');
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.createRoot(rootDiv).render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MantineProvider>
        <Auth0ProviderWithNav>
          <QueryClientProvider client={queryClient}>
            <ToastContainer />
            <App />
            <Analytics />
            <SpeedInsights />
          </QueryClientProvider>
        </Auth0ProviderWithNav>
      </MantineProvider>
    </PersistGate>
  </Provider>,
  // </React.StrictMode>,
);
