/* eslint-disable react/no-unescaped-entities */
import { useAuth0 } from '@auth0/auth0-react';
import { LogoTextSVG } from '@images';
import computerIcon from '@images/computerIconError.png';
import { useNavigate } from '@router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ErrorMobile.module.scss';

const ErrorMobile: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isAuthenticated, logout } = useAuth0();

  useEffect(() => {
    // const timer = setTimeout(() => {
    //   window.location.href = '/';
    // }, 10000);
    // return () => {
    //   clearTimeout(timer);
    // };
  }, [navigate]);

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <LogoTextSVG width="90px" />
      </header>

      <div className={styles.mainContainer}>
        <img src={computerIcon} alt={''} className={styles.icon} />

        <div className={styles.title}>{t('error.mobileError')}</div>

        <div className={styles.description}>{t('error.descriptionMobileError')}</div>

        {/* <button
          className={styles.button}
          onClick={() => {
            window.location.href = '/';
          }}
        >
          Revenir à la page d'accueil
        </button> */}
      </div>

      <footer className={styles.footer}>
        <span className={styles.footerText}>&copy; 2024 NSWR LTD.</span>
        <button onClick={() => logout()} className={styles.logoutButton}>
          {t('logout')}
        </button>
        {/* TODO <div>FR</div> Language switcher */}
      </footer>
    </div>
  );
};

export default ErrorMobile;
