/* eslint-disable react-hooks/exhaustive-deps */
import { Comment } from '@axios/queries';
import ImageLoader from '@components/image-loader/ImageLoader';
import CheckIcon from '@images/check-icon.svg';
import facebookSVG from '@images/facebook.svg';
import instagramSVG from '@images/instagram.svg';
import tiktokSVG from '@images/tiktok.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TaskComponent } from '../task/TaskComponent';
import styles from './TasksList.module.scss';

type TasksListProps = {
  interactions: Comment[];
  selectedInteraction: Comment;
  onSelectInteraction: (interaction: Comment) => void;
  sending: boolean;
  skipping: boolean;
  applyViewAll?: () => void;
};

const TasksList = ({
  interactions,
  selectedInteraction,
  onSelectInteraction,
  sending,
  skipping,
  applyViewAll,
}: TasksListProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const CHANNEL_ICONS = {
    facebook: facebookSVG,
    instagram: instagramSVG,
    tiktok: tiktokSVG,
  };

  const [selectedId, setSelectedId] = useState<string>(
    selectedInteraction ? selectedInteraction?._id : null,
  );

  const handleSelect = (interaction: Comment) => {
    setSelectedId(interaction?._id);
    onSelectInteraction(interaction);

    // setHideContainer(false);
    // setShouldDisappear(false);
  };

  const removeDuplicates = (interactions: Comment[]) => {
    const unique = new Map(
      interactions.map((interaction) => [interaction.comment_id, interaction]),
    );
    return Array.from(unique.values());
  };

  const sortedInteractions = removeDuplicates(interactions);

  // .sort((a, b) => {
  //   // Checking for the presence of aiReply and status conditions
  //   const aPriority =
  //     (a.aiReply ? 1 : 0) -
  //     (a.shouldIgnore?.status ? 1 : 0) -
  //     (a.lackContext?.status ? 1 : 0);
  //   const bPriority =
  //     (b.aiReply ? 1 : 0) -
  //     (b.shouldIgnore?.status ? 1 : 0) -
  //     (b.lackContext?.status ? 1 : 0);

  //   // If priorities are equal, then sort by timestamp
  //   if (aPriority === bPriority) {
  //     return Date.parse(b.timestamp) - Date.parse(a.timestamp);
  //   }

  //   // Otherwise, sort by calculated priority (higher priority comes first)
  //   return bPriority - aPriority;
  // });

  useEffect(() => {
    // Set the selectedId only when the component mounts or when interactions become empty
    if (
      interactions.length > 0 &&
      (selectedId === null ||
        sortedInteractions.filter((interaction) => interaction._id == selectedId)
          .length == 0)
    ) {
      setSelectedId(interactions[0]._id);
    } else if (interactions.length === 0) {
      setSelectedId(null);
    }
    // Note: We intentionally do not include 'selectedId' in the dependency array
  }, [interactions]);

  const [shouldDisappear, setShouldDisappear] = useState(false);
  const [hideContainer, setHideContainer] = useState(false);

  useEffect(() => {
    handleSelect(selectedInteraction);
  }, [handleSelect, selectedId, selectedInteraction]);

  useEffect(() => {
    if (sending || skipping) {
      // const timer = setTimeout(() => {
      //   setShouldDisappear(true); // Cela déclenchera l'animation de disparition
      //   setTimeout(() => {
      //     setHideContainer(true); // Cela déclenchera l'animation de disparition
      //   }, 500);
      // }, 500);
      // return () => clearTimeout(timer);
    } else {
      setShouldDisappear(false);
      setHideContainer(false);
    }
  }, [sending, skipping]);

  return (
    <div className={styles.tasksList}>
      {sortedInteractions.map((interaction) => (
        <div
          key={interaction._id}
          style={{
            display: hideContainer && selectedId == interaction._id ? 'none' : '',
          }}
        >
          {/* Conditionally render the post information if this interaction is selected */}
          {selectedId === interaction._id ? (
            <div
              className={`${styles.selectedContainer} ${
                shouldDisappear ? styles.disappear : ''
              }`}
            >
              <div
                className={`${styles.postInfo} ${
                  sending ? styles.slideRightDisappear : ''
                } ${skipping ? styles.slideRightDisappear : ''}`}
              >
                <div
                  className={styles.accountIconContainer}
                  onClick={() => navigate(`/posts/${interaction?.post?.post_id}`)}
                >
                  {interaction?.post?.mediaType?.includes('ADS') && (
                    <div className={styles.adsLabel}>AD</div>
                  )}

                  <ImageLoader
                    src={interaction?.post?.thumbnail_url}
                    alt={''}
                    className={styles.postMedia}
                    width={45}
                    height={45}
                  />

                  <img
                    src={CHANNEL_ICONS[interaction.channel.type]}
                    alt=""
                    className={styles.channelIcon}
                  />
                </div>
                <p className={styles.caption}>{interaction.post?.caption}</p>
              </div>

              <div
                className={`${styles.interactionItem} ${
                  interaction._id === selectedId ? styles.selected : ''
                } ${sending || skipping ? styles.slideRightDisappear : ''}`}
                style={{
                  backgroundColor: '#FFFFFF !important',
                }}
                onClick={() => handleSelect(interaction)}
              >
                <TaskComponent
                  channel={interaction.channel}
                  post={interaction.post}
                  comment={interaction}
                />
              </div>

              {sending && (
                <div className={`${styles.sentBackground} ${styles.slideIn}`}>
                  {<img src={CheckIcon} alt="" style={{ marginTop: '2px' }} width={18} />}
                  {t('sent')}
                </div>
              )}

              {skipping && (
                <div className={`${styles.skippedBackground} ${styles.slideIn}`}>
                  {<img src={CheckIcon} alt="" style={{ marginTop: '2px' }} width={18} />}
                  {t('skipped')}
                </div>
              )}
            </div>
          ) : (
            <div
              className={`${styles.interactionItem} ${
                interaction._id === selectedId ? styles.selected : ''
              }`}
              style={{ borderRadius: 0 }}
              onClick={() => handleSelect(interaction)}
            >
              <TaskComponent
                channel={interaction.channel}
                post={interaction.post}
                comment={interaction}
              />
            </div>
          )}
        </div>
      ))}

      {applyViewAll && (
        <div className={styles.loader}>
          <button
            className={styles.loadMore}
            onClick={() => {
              applyViewAll();
            }}
          >
            {t('viewAll.label')}
          </button>
        </div>
      )}
    </div>
  );
};

export default TasksList;
