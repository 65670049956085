// FiltersPopup.tsx

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import 'dayjs/locale/fr';

import { Channel, useUpdateUser } from '@axios/mutations';
import { StripeData, User } from '@axios/queries';
import channelsData from '@components/onboarding/connect-channels/connectChannel.config';
import backButton from '@images/back_btn.svg';
import calendarIcon from '@images/calendar_icon.svg';
import ChatIcon from '@images/chat_smile.svg';
import checkIcon from '@images/check_white.svg';
import elonIcon from '@images/elon_custom_icon.png';
import creditIcon from '@images/token_icon.svg';
import { MultiSelect, Select, Switch } from '@mantine/core';
import { DatePicker, DatePickerInput } from '@mantine/dates';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './FiltersPopup.module.scss'; // Ensure you have a corresponding CSS module

type FiltersPopupProps = {
  disabledDateRange?: boolean;
  filterRecentComments?: boolean;
  filterPostsWithComments?: boolean;
  filterChannels?: boolean;
  onClose: () => void;
  channels: Channel[];
  user: User;
  stripeData: StripeData;
  filters?: {
    selectedChannels?: string[];
    viewAll?: boolean;
    viewUnreplied?: boolean;
    dateRange?: [Date, Date];
    filtersType?: {
      ads: boolean;
      organic: boolean;
    };
    adsAccounts: [string];
  };
  setFilters: (filters) => void;
  applyFilters: (filters) => void;
};

const FiltersPopup = ({
  disabledDateRange = false,
  filterRecentComments = false,
  filterChannels = false,
  filterPostsWithComments = false,
  onClose,
  channels,
  user,
  stripeData,
  filters,
  setFilters,
  applyFilters,
}: FiltersPopupProps) => {
  const { t } = useTranslation();
  const popupRef = useRef(null);

  const [dateRange, setDateRange] = useState<[Date, Date]>(filters?.dateRange);
  const [viewAll, setViewAll] = useState(filters?.viewAll);

  const [viewUnreplied, setViewUnreplied] = useState(filters?.viewUnreplied);

  const [selectedChannels, setSelectedChannels] = useState(
    filters?.selectedChannels ?? [],
  );

  const [filtersType, setFiltersType] = useState(filters?.filtersType);

  // Handle selecting/deselecting channels
  const toggleChannelSelection = (channel) => {
    setSelectedChannels((currentSelection) =>
      currentSelection.includes(channel.channel_id)
        ? currentSelection.filter((ch) => ch !== channel.channel_id)
        : [...currentSelection, channel.channel_id],
    );
  };

  const handleFilterChange = (filterName) => {
    setFiltersType((prevFilters) => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName],
    }));
  };

  const uniqueAdsAccounts = channels.reduce((acc, channel) => {
    if (channel.ads_accounts && channel.ads_accounts.length > 0) {
      channel.ads_accounts?.forEach((account) => {
        if (account) {
          // Ensuring account is not undefined
          const uniqueKey = `${account.name}-${account.ad_account_id}`;
          if (!acc.has(uniqueKey)) {
            acc.set(uniqueKey, {
              value: account.ad_account_id,
              label: `${account.name} (${account.ad_account_id})`,
            });
          }
        }
      });
    }
    return acc;
  }, new Map());

  const adsAccounts = Array.from(uniqueAdsAccounts.values());

  const [selectedAdsAccounts, setSelectedAdsAccounts] = useState<string[]>(
    filters?.adsAccounts,
  );

  // Function to handle the confirmation action
  const handleConfirm = () => {
    // console.log('dateRange:', dateRange);
    // console.log('viewAll:', viewAll);
    // console.log('filtersType:', filtersType);
    // console.log('selectedAdsAccounts:', selectedAdsAccounts);

    const newFilters = {
      selectedChannels: selectedChannels,
      dateRange: dateRange,
      viewAll: viewAll,
      viewUnreplied: viewUnreplied,
      filtersType: filtersType,
      adsAccounts: selectedAdsAccounts,
    };

    setFilters(newFilters);

    applyFilters(newFilters);
    onClose();
  };

  // Fonction pour ouvrir le portail client Stripe dans un nouvel onglet
  const handleManageSubscriptionClick = () => {
    window.open(stripeData.customer_portal, '_blank');
  };

  return (
    <div className={styles.root}>
      <div className={styles.popupContainer} ref={popupRef}>
        <button onClick={onClose} className={styles.returnButton}>
          <img src={backButton} alt="" />
        </button>

        {filterChannels && (
          <div className={styles.channelsContainer}>
            <p className={styles.title}>{t('filterByChannels')}</p>

            {channels.map((channel) => (
              <div
                key={channel.channel_id}
                className={`${styles.channel} ${
                  selectedChannels.includes(channel.channel_id)
                    ? styles.channelSelected
                    : ''
                }`}
                onClick={() => toggleChannelSelection(channel)}
              >
                <img
                  alt=""
                  src={channelsData[channel.type].image}
                  className={styles.channelIcon}
                />
                {channelsData[channel.type].name}

                <div
                  className={`${styles.checkbox} ${
                    selectedChannels.includes(channel.channel_id)
                      ? styles.checkboxSelected
                      : ''
                  }`}
                >
                  {selectedChannels.includes(channel.channel_id) && (
                    <img alt="" src={checkIcon} />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        <div className={styles.datesContainer}>
          <DatePickerInput
            clearable
            leftSection={<img src={calendarIcon} alt="" />}
            locale="fr"
            maxDate={new Date()}
            maxLevel="year"
            radius="md"
            size="md"
            type="range"
            disabled={disabledDateRange}
            allowSingleDateInRange
            label={t('filter.dateRange.label')}
            placeholder={t('filter.dateRange.placeholder')}
            defaultValue={dateRange}
            value={dateRange}
            onChange={setDateRange}
            weekendDays={[7, 7] as any}
            popoverProps={{
              offset: 12,
              styles: {
                dropdown: {
                  padding: '10px 6px',
                  borderRadius: '8px',
                  background: '#FFFFFF',
                  boxShadow: '0px 40px 96px -8px #585C5F33',
                },
              },
            }}
            styles={{
              label: {
                marginBottom: '12px',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#0A0D14',
              },
              input: {
                borderRadius: '10px',
                gap: '8px',
                width: '100%',
                backgroundColor: '#FFFFFF',
                border: '1px solid #E2E4E9',
                boxShadow: '0px 1px 2px 0px #E4E5E73D',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '20px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#0A0D14',
              },
              description: {
                lineHeight: '20px',
              },
            }}
          />
        </div>

        {filterRecentComments && (
          <div className={styles.commentsContainer}>
            <Switch
              checked={viewAll}
              onChange={(event) => setViewAll(event.currentTarget.checked)}
              color="#155EEF"
              labelPosition="left"
              label={t('viewAll.label')}
              description={t('viewAll.description')}
              size="md"
              styles={{
                track: {
                  flexShrink: 0,
                  cursor: 'pointer',
                },
                body: {
                  justifyContent: 'space-between',
                  alignItems: 'center',
                },
                label: {
                  flexShrink: '0',
                  fontFamily: 'Plus Jakarta Sans, sans-serif',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  letterSpacing: '-0.006em',
                  textAlign: 'left',
                  color: '#0A0D14',
                },
                description: {
                  lineHeight: '20px',
                },
              }}
            />
          </div>
        )}

        {filterPostsWithComments && (
          <div className={styles.commentsContainer}>
            <Switch
              checked={viewUnreplied}
              onChange={(event) => setViewUnreplied(event.currentTarget.checked)}
              color="#155EEF"
              labelPosition="left"
              label={t('viewUnreplied.label')}
              description={t('viewUnreplied.description')}
              size="md"
              styles={{
                track: {
                  flexShrink: 0,
                  cursor: 'pointer',
                },
                body: {
                  justifyContent: 'space-between',
                  alignItems: 'center',
                },
                label: {
                  flexShrink: '0',
                  fontFamily: 'Plus Jakarta Sans, sans-serif',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  letterSpacing: '-0.006em',
                  textAlign: 'left',
                  color: '#0A0D14',
                },
                description: {
                  lineHeight: '20px',
                },
              }}
            />
          </div>
        )}

        <div className={styles.commentsContainer}>
          <Switch
            checked={filtersType.organic}
            onChange={() => handleFilterChange('organic')}
            color="#155EEF"
            labelPosition="left"
            label={t('viewOrganicPosts.label')}
            description={t('viewOrganicPosts.description')}
            size="md"
            styles={{
              track: {
                flexShrink: 0,
                cursor: 'pointer',
              },
              body: {
                alignItems: 'center',
                justifyContent: 'space-between',
              },
              label: {
                flexShrink: '0',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#0A0D14',
              },
              description: {
                lineHeight: '20px',
              },
            }}
          />
        </div>

        <div className={styles.adsContainer}>
          <div>
            <div className={styles.title}>{t('adsFilterTitle')}</div>
            {user?.subscription_plan != 2 &&
              user?.stripe_subscription_status != 'trialing' && (
                <button
                  onClick={handleManageSubscriptionClick}
                  className={styles.buttonError}
                >
                  {`${t('adsFilterTitleNoAccess')} `}
                </button>
              )}
          </div>

          <Switch
            checked={filtersType.ads}
            onChange={() => handleFilterChange('ads')}
            color="#155EEF"
            disabled={
              user?.subscription_plan != 2 &&
              user?.stripe_subscription_status != 'trialing'
            }
            labelPosition="left"
            label={t('viewSponsoredPosts.label')}
            description={t('viewSponsoredPosts.description')}
            size="md"
            styles={{
              track: {
                flexShrink: 0,
                cursor: 'pointer',
              },
              body: {
                justifyContent: 'space-between',
                alignItems: 'center',
              },
              label: {
                flexShrink: '0',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#0A0D14',
              },
              description: {
                lineHeight: '20px',
              },
            }}
          />

          <MultiSelect
            disabled={
              user?.subscription_plan != 2 &&
              user?.stripe_subscription_status != 'trialing'
            }
            style={{ width: '100%' }}
            checkIconPosition="left"
            data={adsAccounts}
            label={t('selectAdsAccount')}
            defaultValue={
              user?.subscription_plan != 2 &&
              user?.stripe_subscription_status != 'trialing'
                ? []
                : selectedAdsAccounts
            }
            onChange={(value) => setSelectedAdsAccounts(value)} // Correctly update state when selection changes
            maxDropdownHeight={200}
            styles={{
              label: {
                marginBottom: '12px',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#0A0D14',
              },
              input: {
                borderRadius: '10px',
                gap: '8px',
                width: '100%',
                backgroundColor: '#FFFFFF',
                border: '1px solid #E2E4E9',
                boxShadow: '0px 1px 2px 0px #E4E5E73D',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#0A0D14',
              },
              dropdown: {
                width: '100%', // Ensure the dropdown matches the width of the input
                borderRadius: '10px',
                backgroundColor: '#FFFFFF',
                border: '1px solid #E2E4E9',
                boxShadow: '0px 1px 2px 0px #E4E5E73D',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '24px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#0A0D14',
              },
              description: {
                lineHeight: '20px',
              },
            }}
          />
        </div>

        <button onClick={handleConfirm} className={styles.button}>
          {t('ApplyFilters')}
        </button>
      </div>
    </div>
  );
};

export default FiltersPopup;
