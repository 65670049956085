import { useAuth0 } from '@auth0/auth0-react';
import { useAdminStore } from '@redux/store';
import axios from 'axios';
import { useCallback } from 'react';

const useAxiosInstance = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  // Accessing state
  const isAdmin = useAdminStore((state) => state.isAdmin);

  // const urlParams = new URLSearchParams(window.location.search);
  // const user_id_from_params = urlParams.get('user_id');

  const selectedUserId = useAdminStore((state) => state.userId);

  const getAxiosInstance = useCallback(
    async (streaming = false) => {
      if (!isLoading && !isAuthenticated) {
        return null;
      }
      const accessToken = await getAccessTokenSilently();

      if (import.meta.env.VITE_PRINT_ACCESS_TOKEN === 'true') {
        console.log('access token', accessToken);
      }

      // console.log('IS ADMIN:', isAdmin);
      // console.log('selectedUserId:', selectedUserId);

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      };

      // Check if an admin has selected a user ID and add it to the headers or params
      if (isAdmin && selectedUserId) {
        headers['X-Selected-User'] = selectedUserId; // Example header, adjust based on your API
      }

      if (streaming) {
        // For streaming, we need to modify the response type and remove the content-type header
        return axios.create({
          baseURL: import.meta.env.VITE_BACKEND_SERVER_URL,
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: 'stream',
        });
      }

      return axios.create({
        baseURL: import.meta.env.VITE_BACKEND_SERVER_URL,
        headers,
      });
    },
    [getAccessTokenSilently, isAdmin, isAuthenticated, isLoading, selectedUserId],
  );

  const getAxiosInstanceWithNoAuth = useCallback(
    () =>
      axios.create({
        baseURL: import.meta.env.VITE_BACKEND_SERVER_URL,
        headers: { 'Content-Type': 'application/json' },
      }),
    [],
  );

  return {
    getAxiosInstance,
    getAxiosInstanceWithNoAuth,
    authenticated: isAuthenticated && !isLoading,
  };
};

export { useAxiosInstance };
