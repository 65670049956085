import { useAxiosInstance } from '@axios/useAxiosInstance';
import { QueryFunction, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { Comment } from './usePosts';

const useLastHiddenComments = (
  params: {
    frameTimeDays?: number;
    count?: number;
  },
  options?: Omit<
    UseQueryOptions<
      Comment[],
      Error,
      Comment[],
      [string, number | undefined, number | undefined]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  },
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const fetchLastHiddenComments: QueryFunction<
    Comment[],
    [string, number | undefined, number | undefined]
  > = async ({ queryKey }) => {
    const [, frameTimeDays, count] = queryKey;
    const instance = await getAxiosInstance();

    return instance
      .get(
        `/api/assistant/last-hidden-comments?${
          frameTimeDays !== undefined ? `frameTimeDays=${frameTimeDays}` : ''
        }&${count !== undefined ? `count=${count}` : ''}`,
      )
      .then((response) => response.data);
  };

  return useQuery<
    Comment[],
    Error,
    Comment[],
    [string, number | undefined, number | undefined]
  >(
    ['last-hidden-comments', params.frameTimeDays, params.count],
    fetchLastHiddenComments,
    {
      ...options,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
      onSuccess:
        options?.onSuccess ||
        ((data) => {
          if (import.meta.env.VITE_PRINT_SUCCESSFUL_ENDPOINTS === 'true') {
            console.log('LastHiddenComments data fetched successfully!', data);
          }
        }),
      onError:
        options?.onError ||
        ((error) => {
          console.error('Error fetching LastHiddenComments data:', error);
        }),
      enabled: options?.enabled && authenticated,
    },
  );
};

export { useLastHiddenComments };
