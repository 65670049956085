/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import { Channel, useChatWithElon, useDeleteInstruction } from '@axios/mutations';
import { Message, User, useUser } from '@axios/queries';
import PopupComponent from '@components/popup/PopupComponent';
import { useNango } from '@hooks';
import { FacebookImage, InstagramImage } from '@images';
import DeleteIcon from '@images/delete-icon.svg';
import ElonIcon from '@images/elon_icon.svg';
import errorIcon from '@images/error_icon.png';
import darkElon from '@images/grey_elon.svg';
import loaderAnimation from '@images/loader.json';
import sendIcon from '@images/send_icon.svg';
import { usePopupStore } from '@redux/store';
import { capitalize } from 'lodash';
import Lottie from 'lottie-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Elon.module.scss';

type ElonProps = { channels: Channel[]; user: User };

const Elon = ({ channels, user }: ElonProps) => {
  const { t } = useTranslation();

  const { refetch: refetchUser } = useUser(
    {
      user_id: user?.user_id,
    },
    { enabled: false },
  );

  const { mutateAsync: chatWithElon, isLoading: isLoadingReply } = useChatWithElon({
    onSuccess: (data) => {},
    onError: (error) => {
      console.error('Error sending message:', error);
    },
  });

  const { mutate: deleteInstruction } = useDeleteInstruction({
    onSuccess: (data) => {
      console.log('Instruction deleted successfully', data);
    },
    onError: (error) => {
      console.error('Error deleting instruction:', error);
    },
  });

  const profile_picture_url = channels.find(
    (c) => c.profile_picture_url,
  ).profile_picture_url;

  const [messages, setMessages] = useState([]);
  const [localInstructions, setLocalInstructions] = useState(
    user?.elon_instructions || [],
  );
  const [message, setMessage] = useState<string>('');

  const messagesEndRef = useRef(null);
  const instructionsEndRef = useRef(null);

  const [isTyping, setIsTyping] = useState(false);
  const [isLoadingElonReply, setIsLoadingElonReply] = useState<boolean>(false);

  const setPopup = usePopupStore((state) => state.setPopup); // Use the setPopup function

  const handleSendMessage = (message: string) => {
    setIsLoadingElonReply(true);
    setMessage('');

    const userMessage = {
      content: message,
      role: 'user',
    };

    console.log('USER MESSAGE:', userMessage);

    setMessages((msgs) => [...msgs, userMessage]);

    const newMessages = [...messages, userMessage];

    void chatWithElon({
      messages: newMessages,
    }).then(({ instruction, message: messageElon, action }) => {
      // Existing logic to add Elon's message
      const message: Message = {
        content: messageElon,
        role: 'assistant',
      };

      setMessages((msgs) => [...msgs, message]);

      // New logic to add instruction
      if (instruction && action == 'instruction') {
        setLocalInstructions((currentInstructions) => [
          ...currentInstructions,
          instruction,
        ]);

        // Optionally refresh user data if it affects other parts of your app
        void refetchUser();
      }

      setIsLoadingElonReply(false);
    });
  };

  const handleDelete = (instruction: string) => {
    console.log('DELETE INSTRUCTION:', instruction);
    deleteInstruction({ instruction });

    // Filter out the instruction to delete
    const updated_instructions = localInstructions.filter((item) => item !== instruction);
    setLocalInstructions(updated_instructions);

    setPopup(null, null);
  };

  const confirmDelete = (instruction) => {
    setPopup(
      <PopupComponent
        title={t('elon.popupConfirm.title')}
        buttonText={t('elon.popupConfirm.buttonText')}
        icon={errorIcon}
        widthIcon={'70px !important'}
        onConfirm={() => {
          handleDelete(instruction);
        }}
        onCancelButtonText={t('Cancel')}
        onCancel={() => setPopup(null, null)}
        classname={styles.popupContainer}
      />,
    );
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messages]); // Dependency array includes `messages` to trigger scroll on update

  useEffect(() => {
    if (instructionsEndRef.current) {
      instructionsEndRef.current.scrollTop = instructionsEndRef.current.scrollHeight;
    }
  }, [localInstructions]); // Dependency array includes `localInstructions`

  return (
    <div className={styles.root}>
      <div className={styles.leftPanel}>
        <div className={styles.titleContainer}>Elon</div>
        <div className={styles.chatElon}>
          <div ref={messagesEndRef} className={styles.messages}>
            <div className={styles.ElonMessageContainer}>
              <img className={styles.ElonIcon} src={ElonIcon} alt="" />
              <div className={styles.text}>
                <div className={styles.nameTitle}>Elon</div>
                <div>{t('elon.instruction.firstMessage')}</div>
              </div>
            </div>

            {messages?.map((msg, index) => (
              <div
                key={index}
                className={`${styles.ElonMessageContainer} ${
                  msg.role == 'user' && styles.userMessageContainer
                }`}
              >
                {msg.role == 'assistant' && (
                  <img className={styles.ElonIcon} src={ElonIcon} alt="" />
                )}
                <div className={styles.text}>
                  <div className={styles.nameTitle}>
                    {msg.role == 'user' ? user.name : 'Elon'}
                  </div>
                  <div>{msg.message || msg.content}</div>
                </div>

                {profile_picture_url && msg.role == 'user' && (
                  <img src={profile_picture_url} alt="" className={styles.ElonIcon} />
                )}
              </div>
            ))}
          </div>

          <div
            className={styles.inputContainer}
            style={messages && messages.length > 0 ? { marginTop: '16px' } : {}}
          >
            <input
              type="text"
              className={styles.messageInput}
              placeholder={t('elon.writeToElon')}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleSendMessage(message)}
              disabled={isLoadingElonReply}
            />

            <button
              className={`${styles.sendButton} ${
                !(message?.length > 0) && !isLoadingElonReply && styles.sendButtonDisabled
              }`}
              onClick={() => handleSendMessage(message)}
              disabled={isLoadingElonReply || !(message?.length > 0)}
            >
              {!isLoadingElonReply ? (
                <img src={sendIcon} alt="" />
              ) : (
                <Lottie
                  animationData={loaderAnimation}
                  loop={true}
                  style={{ width: 16, height: 16 }}
                />
              )}
            </button>
          </div>
        </div>
      </div>

      <div className={styles.rightPanel}>
        <div className={styles.currentInstructions}>
          <div className={styles.title}>{t('elon.instruction.currentInstruction')}</div>
          <div ref={instructionsEndRef} className={styles.instructions}>
            {localInstructions?.map((instruction, index) => (
              <div key={index} className={styles.instructionContainer}>
                <div className={styles.content}>
                  <img className={styles.ElonIcon} src={ElonIcon} alt="" />
                  {instruction}
                </div>

                <button
                  className={styles.deleteButton}
                  onClick={() => confirmDelete(instruction)}
                >
                  <img src={DeleteIcon} alt="" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Elon };
