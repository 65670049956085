/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
import { Channel } from '@axios/mutations';
import { Comment, Post } from '@axios/queries';
import ImageLoader from '@components/image-loader/ImageLoader';
import ElonIcon from '@images/elon_icon.svg';
import facebookSVG from '@images/facebook.svg';
import instagramSVG from '@images/instagram.svg';
import tiktokSVG from '@images/tiktok.svg';
import { formatTimeToNowShort } from '@utils/formatTimeToNowShort';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './CommentByElon.module.scss';

type CommentByElonProps = {
  channel: Channel;
  post: Post;
  comment: Comment;
};

const CommentByElon = ({ channel, post, comment }: CommentByElonProps) => {
  const { t } = useTranslation();

  const CHANNEL_ICONS = {
    facebook: facebookSVG,
    instagram: instagramSVG,
    tiktok: tiktokSVG,
  };

  // Utilize the formatTimeToNow utility function for the timestamp
  const formattedTimestamp = useMemo(
    () =>
      comment.timestamp && comment.timestamp != 'ERROR'
        ? formatTimeToNowShort(comment.timestamp)
        : t('sendingShort'),
    [comment.timestamp],
  );

  return (
    <div className={styles.commentByElon}>
      <div className={styles.postSource}>
        <div className={styles.accountIconContainer}>
          <ImageLoader
            src={post.thumbnail_url}
            alt={''}
            className={styles.postMedia}
            width={40}
            height={40}
          />
          <img src={CHANNEL_ICONS[channel.type]} alt="" className={styles.channelIcon} />
        </div>

        <div className={styles.postCaption}>{post.caption}</div>
      </div>

      <div className={styles.userInfo}>
        <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
          <ImageLoader
            src={ElonIcon}
            alt={''}
            className={styles.accountIcon}
            width={40}
            height={40}
            borderRadius={'50%'}
          />
          <div className={styles.postCaption}>
            {comment.username && <strong style={{ color: '#101828' }}></strong>}
            {comment.text}
          </div>
        </div>

        <div className={styles.postTimestamp}>{formattedTimestamp}</div>
      </div>
    </div>
  );
};

export { CommentByElon };
