import { AppState, RedirectLoginOptions } from '@auth0/auth0-react';
import { LogoTextSVG } from '@images';
import arrowRight from '@images/arrow-right-line.svg';
import helloIcon from '@images/hello_icon.png';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './LoginPage.module.scss';

type LoginPageProps = {
  loginWithRedirect: (options?: RedirectLoginOptions<AppState>) => Promise<void>;
};

const LoginPage = ({ loginWithRedirect }: LoginPageProps) => {
  const { t } = useTranslation();

  const handleLogin = async (params: RedirectLoginOptions<AppState>) => {
    try {
      await loginWithRedirect(
        params,
        // You can set default params here if any
      );
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <LogoTextSVG width="90px" />
      </header>

      <div className={styles.mainContainer}>
        <img src={helloIcon} alt={''} className={styles.icon} />

        <div className={styles.title}>{t('welcome')}</div>

        <div className={styles.buttonsContainer}>
          <button
            className={styles.button}
            onClick={async () => {
              await handleLogin({
                authorizationParams: {
                  screen_hint: 'login',
                },
              });
            }}
          >
            {t('login')}
            <img src={arrowRight} alt="" className={styles.arrowIcon} />
          </button>

          <button
            className={styles.blackButton}
            onClick={async () => {
              await handleLogin({
                authorizationParams: {
                  screen_hint: 'signup',
                },
              });
            }}
          >
            {t('signup')}
            <img src={arrowRight} alt="" className={styles.arrowIcon} />
          </button>
        </div>
      </div>

      <footer className={styles.footer}>
        <span className={styles.footerText}>&copy; 2024 NSWR LTD.</span>
        {/* TODO <div>FR</div> Language switcher */}
      </footer>
    </div>
  );
};

export default LoginPage;
