import customIconFacebook from '@images/custom_icon_facebook.png';
import customIconInstagram from '@images/custom_icon_instagram.png';
import customIconTiktok from '@images/custom_icon_tiktok.png';
import facebookSVG from '@images/facebook.svg';
import instagramSVG from '@images/instagram.svg';
import tiktokSVG from '@images/tiktok.svg';

const channelsData = {
  facebook: {
    name: 'Facebook',
    image: facebookSVG,
    integrationId: 'facebook', // Replace with actual ID
    description: 'connectChannelConfig.facebook.description',
    urlTuto: 'https://nswr.notion.site/Welcome-to-NSWR-f609d554f3324ef7a1684d295712da7c',
    customIcon: customIconFacebook,
  },
  instagram: {
    name: 'Instagram',
    image: instagramSVG,
    description: 'connectChannelConfig.instagram.description',
    urlTuto: 'https://nswr.notion.site/Welcome-to-NSWR-f609d554f3324ef7a1684d295712da7c',
    integrationId: 'facebook', // Replace with actual ID
    customIcon: customIconInstagram,
  },
  tiktok: {
    name: 'TikTok',
    image: tiktokSVG,
    description: 'connectChannelConfig.tiktok.description',
    urlTuto: 'https://nswr.notion.site/Welcome-to-NSWR-f609d554f3324ef7a1684d295712da7c',
    integrationId: 'tiktok-accounts', // Replace with actual ID
    customIcon: customIconTiktok,
  },
  // ... add other channels as needed
};

export default channelsData;
