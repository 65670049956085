import { useAuth0 } from '@auth0/auth0-react';
import {
  Post,
  useChannels,
  useCheckSync,
  usePosts,
  useStripeData,
  useStripeSubscriptionStatus,
  useUser,
} from '@axios/queries';
import { AppWrapper } from '@components';
import ErrorPage from '@components/error-page/ErrorPage';
import { Channels } from '@containers';
import { ChannelsSkeleton } from '@containers/channels/ChannelsSkeleton';
import { Path, useParams } from '@router';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ChannelsRoute = () => {
  const { t } = useTranslation();

  const {
    isAuthenticated,
    isLoading: isLoadingAuth,
    loginWithRedirect,
    user,
  } = useAuth0();

  const { channel_id } = useParams('/channels/:channel_id');
  const [error404, setError404] = useState(false);

  // const { data: stripeStatus, isLoading: isLoadingStripeStatus } =
  //   useStripeSubscriptionStatus();

  const { data: stripeData, isLoading: isLoadingStripeData } = useStripeData();

  const { data: channel, isLoading: isLoadingChannel } = useChannels(
    {
      user_id: user?.sub,
      channel_id: channel_id,
    },
    { enabled: !error404 && !!stripeData?.hasAccess },
  );

  const [isLoadingPosts, setIsLoadingPosts] = useState(!error404);

  const { data: initialPosts, refetch } = usePosts(
    {
      channel_id: channel_id,
    },
    {
      enabled: (!channel_id || !error404) && !!stripeData?.hasAccess,
      onSettled: () => {
        setIsLoadingPosts(false); // Stop loading once the query is settled (either on success or failure)
      },
      onSuccess: (newPosts: Post[]) => {
        setPosts((currentPosts: Post[]) => {
          // First, filter out any posts that do not belong to the current channel.
          const relevantPosts =
            currentPosts?.filter((post) => post.channel_id === channel_id) ?? [];

          // Next, add new posts to the list, ensuring no duplicates and only adding if they belong to the current channel.
          newPosts.forEach((post) => {
            const isCurrentChannelPost = post.channel_id === channel_id; // Ensure the post belongs to the current channel.
            const isNotDuplicated = !relevantPosts?.some(
              (existingPost) => existingPost._id === post._id,
            ); // Check for duplication.

            if (isCurrentChannelPost && isNotDuplicated) {
              relevantPosts.push(post);
            }
          });

          return relevantPosts;
        });
      },
    },
  );

  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch: refetchUser,
  } = useUser(user?.sub, { enabled: !!user });

  const [posts, setPosts] = useState(initialPosts);
  const [syncStatus, setSyncStatus] = useState('syncing');
  const pollingInterval = useRef<NodeJS.Timeout | null>(null);

  const { refetch: refetchSyncStatus } = useCheckSync(
    {
      channel_id: channel_id,
    }, // Include page in the query key to refetch when it changes
    {
      enabled: false, // Keep the query disabled initially
      onSuccess: async (data) => {
        setSyncStatus(data.status); // Set the status based on the response
        if (data.status === 'complete') {
          clearInterval(pollingInterval.current); // Clear the interval if syncing is complete
          await refetch(); // Refetch the main data
        }
      },
    },
  );

  useEffect(() => {
    // console.log(syncStatus);
    // Only set up polling if syncStatus indicates it's needed
    if (syncStatus === 'syncing' && stripeData?.hasAccess) {
      pollingInterval.current = setInterval(() => {
        void refetchSyncStatus();
      }, 5000); // Poll every 0.5 seconds
    }

    // Clean up the interval when the component unmounts or syncStatus changes
    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current);
      }
    };
  }, [syncStatus, refetchSyncStatus, stripeData?.hasAccess]);

  // In your ChannelsRoute component
  useEffect(() => {
    setIsLoadingPosts(true); // Reset loading state

    void refetch();
  }, [channel_id, refetch]); // Ensure channel_id is included as a dependency

  const isLoading =
    isLoadingChannel ||
    isLoadingAuth ||
    isLoadingPosts ||
    !posts ||
    isLoadingUser ||
    (syncStatus == 'syncing' && (!posts || (posts as Post[]).length == 0));

  // Check if loading is done and the selected channel is not found
  const pageNotFound = !isLoadingChannel && (!channel || channel.length == 0);

  if (pageNotFound) {
    setError404(pageNotFound);

    return (
      <ErrorPage
        errorCode="404"
        title={t('error.404.title')}
        message={t('error.404.message')}
      />
    );
  }

  return (
    <AppWrapper
      activePath={`/channels/${channel_id}` as Path}
      isLoading={isLoading}
      isAuthenticated={isAuthenticated}
      loginWithRedirect={loginWithRedirect}
      user={user}
      isLoadingAuth={isLoadingAuth}
      loadingSkeleton={<ChannelsSkeleton />}
    >
      <Channels
        stripeData={stripeData}
        key={channel && !isLoadingChannel ? channel[0].channel_id : null}
        channel={channel && !isLoadingChannel ? channel[0] : null}
        posts={posts as Post[]}
        user={userData}
      />
    </AppWrapper>
  );
};

export default ChannelsRoute;
export const Catch = () => <ErrorPage />;
