import { configureStore } from '@reduxjs/toolkit';
import { ReactElement } from 'react';
import { combineReducers } from 'redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { create } from 'zustand';

import adminSlice from './slices/adminSlice';
import appReducer from './slices/appSlice';

// ZUSTAND
// POPUP STORE
interface PopupState {
  popupComponent: ReactElement | null;
  pathToShow?: string | null;
  setPopup: (popup: ReactElement | null, path?: string | null) => void;
}

export const usePopupStore = create<PopupState>((set) => ({
  popupComponent: null,
  pathToShow: null,
  setPopup: (popup, path) => set({ popupComponent: popup, pathToShow: path }),
}));

// NAVBAR STORE
interface NavbarState {
  isNavbarExpanded: boolean;
  toggleNavbar: () => void;
}

export const useNavbarStore = create<NavbarState>((set) => ({
  isNavbarExpanded: true,
  toggleNavbar: () => set((state) => ({ isNavbarExpanded: !state.isNavbarExpanded })),
}));

// IS ADMIN & USER ID STORE

interface StoreState {
  userId: string | null;
  isAdmin: boolean;
  isInitialized: boolean;
  setAdmin: (isAdmin: boolean) => void;
  setUserId: (userId: string | null) => void;
  setInitialized: (isInitialized: boolean | null) => void;
}

export const useAdminStore = create<StoreState>((set) => ({
  isAdmin: false,
  userId: null,
  isInitialized: false, // New flag
  setAdmin: (isAdmin) => set({ isAdmin }),
  setUserId: (userId) => set({ userId }),
  setInitialized: (isInitialized) => set({ isInitialized }), // New action to update the flag
}));

// OTHER STORE
const rootReducer = combineReducers({
  app: appReducer,
  admin: adminSlice.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['loadingGlobal'], // loadingGlobal will not be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
