import { useAxiosInstance } from '@axios/useAxiosInstance';
import { QueryFunction, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { Comment } from './usePosts';

const useCommentsByElon = (
  params: {
    count?: number;
    frameTimeDays?: number;
  },
  options?: Omit<
    UseQueryOptions<
      Comment[],
      Error,
      Comment[],
      [string, number | undefined, number | undefined]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  },
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const fetchCommentsByElon: QueryFunction<
    Comment[],
    [string, number | undefined, number | undefined]
  > = async ({ queryKey }) => {
    const [, frameTimeDays, count] = queryKey;
    const instance = await getAxiosInstance();

    return instance
      .get(
        `/api/assistant/last-comments-elon?${
          frameTimeDays !== undefined ? `frameTimeDays=${frameTimeDays}` : ''
        }&${count !== undefined ? `count=${count}` : ''}`,
      )
      .then((response) => response.data);
  };

  return useQuery<
    Comment[],
    Error,
    Comment[],
    [string, number | undefined, number | undefined]
  >(['tasks-comments-by-elon', params.frameTimeDays, params.count], fetchCommentsByElon, {
    ...options,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
    onSuccess:
      options?.onSuccess ||
      ((data) => {
        if (import.meta.env.VITE_PRINT_SUCCESSFUL_ENDPOINTS === 'true') {
          console.log('Tasks data fetched successfully!', data);
        }
      }),
    onError:
      options?.onError ||
      ((error) => {
        console.error('Error fetching Task data:', error);
      }),
    enabled: options?.enabled && authenticated,
  });
};

export { useCommentsByElon };
