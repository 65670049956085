// PricingPlan.tsx
import CheckFill from '@images/check-fill.svg';
import Close from '@images/close.svg';
import loaderAnimation from '@images/loader.json';
import loaderAnimationBlue from '@images/LoaderBlue.json';
import Lottie from 'lottie-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './PricingPlan.module.scss';

interface Feature {
  name: string;
  isActive?: boolean;
  count?: number;
}

interface PlanProps {
  name: string;
  description: string;
  features: Feature[];
  monthlyPrice: number;
  yearlyPrice: number;
  billingCycle: 'monthly' | 'yearly';
  isPopular: boolean;
  onChoosePlan: (billingCycle: 'monthly' | 'yearly') => void;
}

const PricingPlan: React.FC<PlanProps> = ({
  name,
  description,
  features,
  monthlyPrice,
  yearlyPrice,
  billingCycle,
  isPopular,
  onChoosePlan,
}) => {
  const price = billingCycle === 'monthly' ? monthlyPrice : yearlyPrice;
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={`${styles.planContainer} ${isPopular ? styles.popularPlan : ''}`}>
      {isPopular && (
        <div className={styles.mostPopularLabel}>
          {t('onboarding.choosePlan.mostPopular')}
        </div>
      )}

      <div className={styles.titleContainer}>
        <h2 className={styles.planName}>{name}</h2>
        <p className={styles.planDescription}>{description}</p>

        <div className={styles.priceSection}>
          <p>
            <span className={styles.price}>{price}€</span>/
            {t('onboarding.choosePlan.month')}
          </p>
          <p>
            {t('onboarding.choosePlan.billed')} {t('onboarding.choosePlan.per')}{' '}
            {billingCycle === 'monthly'
              ? t('onboarding.choosePlan.month')
              : t('onboarding.choosePlan.year')}
          </p>
        </div>
      </div>

      <button
        className={`${styles.choosePlanButton} ${isPopular ? styles.popularButton : ''}`}
        onClick={() => {
          setIsLoading(true);
          onChoosePlan(billingCycle);
        }}
      >
        {t('onboarding.payment.payNow')}
        {isLoading && (
          <Lottie
            className={styles.loader}
            animationData={isPopular ? loaderAnimation : loaderAnimationBlue}
            loop={true}
            style={{ width: 18, height: 18 }}
          />
        )}
      </button>

      <ul className={styles.featuresList}>
        {features.map((feature, index) => (
          <li key={index} className={styles.feature}>
            <span className={styles.featureName}>{t(feature.name)}</span>
            {feature.count !== undefined ? (
              <span className={styles.featureCount}>{feature.count}</span>
            ) : feature.isActive ? (
              <img src={CheckFill} className={styles.checkFill} alt="" />
            ) : (
              <img src={Close} className={styles.checkFill} alt="" />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PricingPlan;
