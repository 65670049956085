import loaderBlueAnimation from '@images/LoaderBlue.json';
import Lottie from 'lottie-react';
import React, { useState } from 'react';

import styles from './Tabs.module.scss';

type Tab = {
  label: string;
  content: React.ReactNode;
  count?: number; // Optional, for displaying a count next to the tab label
  loading?: boolean;
};

type TabsProps = {
  tabs: Tab[];
  activeTab?: string;
  onTabChange?: (label: string) => void;
};

const Tabs: React.FC<TabsProps> = ({
  tabs,
  activeTab,
  onTabChange = (label: string) => {},
}) => {
  // const [activeTab, setActiveTab] = useState(defaultActiveTab || tabs[0].label);

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabButtons}>
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`${styles.tabButton} ${
              activeTab === tab.label ? styles.activeTab : ''
            }`}
            onClick={() => {
              onTabChange(tab.label);
            }}
          >
            {tab.label}

            {tab.loading && tab.label === activeTab && (
              <Lottie
                animationData={loaderBlueAnimation}
                loop={true}
                style={{
                  width: 20,
                  height: 20,
                  marginLeft: '10px',
                }}
              />
            )}

            {tab.count !== undefined && (!tab.loading || tab.label != activeTab) && (
              <span className={styles.tabCount}>{tab.count}</span>
            )}
          </button>
        ))}
      </div>
      <div className={styles.tabContent}>
        {tabs.find((tab) => tab.label === activeTab)?.content}
      </div>
    </div>
  );
};

export { Tabs };
