import { useAuth0 } from '@auth0/auth0-react';
import {
  Post,
  useChannels,
  useCheckSync,
  usePosts,
  useStripeSubscriptionStatus,
} from '@axios/queries';
import { AppWrapper } from '@components';
import ErrorPage from '@components/error-page/ErrorPage';
import { Posts } from '@containers/posts/Posts';
import { PostsSkeleton } from '@containers/posts/PostsSkeleton';
import { Path, useParams } from '@router';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const PostsRoute = () => {
  const { t } = useTranslation();

  const {
    isAuthenticated,
    isLoading: isLoadingAuth,
    loginWithRedirect,
    user,
  } = useAuth0();

  const { data: stripeStatus, isLoading: isLoadingStripeStatus } =
    useStripeSubscriptionStatus();

  const { post_id } = useParams('/posts/:post_id');
  const {
    data: initialPost,
    isLoading: isLoadingPost,
    refetch,
  } = usePosts(
    {
      user_id: user?.sub,
      post_id: post_id,
    },
    {
      enabled: !!stripeStatus?.hasAccess,
      onSuccess: (newPost) => {
        setPost(newPost as Post);
      },
    },
  );

  const { data: channels, isLoading: isLoadingChannels } = useChannels({
    user_id: user?.sub,
  });

  const [post, setPost] = useState(initialPost);

  const channel_id = (post as Post)?.channel_id;

  const channel = channels?.find((channel) => channel.channel_id === channel_id);

  const [syncStatus, setSyncStatus] = useState('syncing');
  const pollingInterval = useRef<NodeJS.Timeout | null>(null);

  const { refetch: refetchSyncStatus } = useCheckSync(
    {
      channel_id: channel_id,
    }, // Include page in the query key to refetch when it changes
    {
      enabled: false, // Keep the query disabled initially
      onSuccess: async (data) => {
        setSyncStatus(data.status); // Set the status based on the response
        if (data.status === 'complete') {
          clearInterval(pollingInterval.current); // Clear the interval if syncing is complete
          await refetch(); // Refetch the main data
        }
      },
    },
  );

  useEffect(() => {
    // Only set up polling if syncStatus indicates it's needed
    if (syncStatus === 'syncing' && stripeStatus?.hasAccess) {
      pollingInterval.current = setInterval(() => {
        console.log('syncStatus:', syncStatus);

        void refetchSyncStatus();
      }, 5000); // Poll every 0.5 second
    }

    // Clean up the interval when the component unmounts or syncStatus changes
    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current);
      }
    };
  }, [syncStatus, refetchSyncStatus, stripeStatus?.hasAccess]);

  const isLoading =
    isLoadingChannels ||
    isLoadingAuth ||
    isLoadingPost ||
    isLoadingStripeStatus ||
    (syncStatus == 'syncing' && !post);

  // Check if loading is done and the selected channel is not found
  const pageNotFound = !isLoadingPost && !post;

  if (pageNotFound) {
    return (
      <ErrorPage
        errorCode="404"
        title={t('error.404.title')}
        message={t('error.404.message')}
      />
    );
  }

  return (
    <AppWrapper
      activePath={`/channels/${channel_id}` as Path}
      isLoading={isLoading}
      isAuthenticated={isAuthenticated}
      loginWithRedirect={loginWithRedirect}
      user={user}
      isLoadingAuth={isLoadingAuth}
      loadingSkeleton={<PostsSkeleton />}
    >
      <Posts channel={channel} post={post as Post} />
    </AppWrapper>
  );
};

export default PostsRoute;
export const Catch = () => <ErrorPage />;
