// Setting.tsx
import { StripeData, User } from '@axios/queries';
import ProfileComponent from '@components/setting/profile-component/ProfileComponent';
import SubscriptionComponent from '@components/setting/subscription-component/SubscriptionComponent';
import ArrowIcon from '@images/ArrowIconWhite.svg'; // Assuming this path is correct
import SubscriptionIcon from '@images/subscription-line.svg';
import SubscriptionIconActive from '@images/subscription-line-active.svg';
import UserIconActive from '@images/user-line.svg';
import UserIcon from '@images/user-line-not-active.svg';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Setting.module.scss';

interface SettingProps {
  user: User;
  stripeData: StripeData;
}

const Setting = ({ user, stripeData }: SettingProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(t('setting.tabs.profile'));

  return (
    <div className={styles.root}>
      <div className={styles.menuWrapper}>
        <div className={styles.title}>{t('setting.title')}</div>
        <div className={styles.separator} />
        <div className={styles.menu}>
          <div
            className={`${styles.menuItem} ${
              activeTab === t('setting.tabs.profile') ? styles.active : ''
            }`}
            onClick={() => setActiveTab(t('setting.tabs.profile'))}
          >
            <img
              src={activeTab === t('setting.tabs.profile') ? UserIconActive : UserIcon}
              alt=""
              className={styles.icon}
            />
            <p style={{ marginBottom: '1px' }}>{t('setting.profile.title')}</p>
            {activeTab === t('setting.tabs.profile') && (
              <img src={ArrowIcon} alt="" className={styles.arrowIcon} />
            )}
          </div>
          <div
            className={`${styles.menuItem} ${
              activeTab === t('setting.tabs.subscription') ? styles.active : ''
            }`}
            onClick={() => setActiveTab(t('setting.tabs.subscription'))}
          >
            <img
              src={
                activeTab === t('setting.tabs.subscription')
                  ? SubscriptionIconActive
                  : SubscriptionIcon
              }
              alt=""
              className={styles.icon}
            />
            <p style={{ marginBottom: '1px' }}>{t('setting.subscription.title')}</p>

            {activeTab === t('setting.tabs.subscription') && (
              <img src={ArrowIcon} alt="" className={styles.arrowIcon} />
            )}
          </div>
        </div>
      </div>
      <div className={styles.contentWrapper}>
        {activeTab === t('setting.tabs.profile') && <ProfileComponent user={user} />}
        {activeTab === t('setting.tabs.subscription') && (
          <SubscriptionComponent stripeData={stripeData} user={user} />
        )}
      </div>
    </div>
  );
};

export { Setting };
