// SettingSkeleton.tsx
import { StripeData, User } from '@axios/queries';
import ProfileComponent from '@components/setting/profile-component/ProfileComponent';
import SubscriptionComponent from '@components/setting/subscription-component/SubscriptionComponent';
import ArrowIcon from '@images/ArrowIconWhite.svg'; // Assuming this path is correct
import SubscriptionIcon from '@images/subscription-line.svg';
import SubscriptionIconActive from '@images/subscription-line-active.svg';
import UserIconActive from '@images/user-line.svg';
import UserIcon from '@images/user-line-not-active.svg';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import styles from './Setting.module.scss';

const SettingSkeleton = () => {
  return (
    <div className={styles.root}>
      <div className={styles.menuWrapper}>
        <div className={styles.title}>
          {' '}
          <Skeleton
            baseColor={'#EBF1FF'}
            highlightColor={'#F9FAFB'}
            duration={1.2}
            width={160}
            height={30}
          />
        </div>
        <div className={styles.separator} />
        <div className={styles.menu}>
          <div className={`${styles.menuItem} `}>
            <Skeleton
              baseColor={'#EBF1FF'}
              highlightColor={'#F9FAFB'}
              duration={1.2}
              width={160}
              height={40}
            />
          </div>
          <div className={`${styles.menuItem} `}>
            <Skeleton
              baseColor={'#EBF1FF'}
              highlightColor={'#F9FAFB'}
              duration={1.2}
              width={160}
              height={40}
            />
          </div>
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <Skeleton
          baseColor={'#EBF1FF'}
          highlightColor={'#F9FAFB'}
          duration={1.2}
          width={575}
          height={75}
        />
        <Skeleton
          style={{ marginTop: '25px' }}
          baseColor={'#EBF1FF'}
          highlightColor={'#F9FAFB'}
          duration={1.2}
          width={575}
          height={75}
        />
      </div>
    </div>
  );
};

export { SettingSkeleton };
