// import purpleLoader from '@images/Loader Logo_purple.gif';
// import whiteLoader from '@images/Loader Logo_white.gif';
import LoaderMagicWand from '@images/LoaderMagicWand.gif';
import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

import styles from './Loader.module.scss';
type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | string | number;

type LoaderProps = Omit<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  'color'
> & {
  size?: number;
  color?: 'white' | 'purple';
};

const sizeMapping: Record<string, number> = {
  xs: 16,
  sm: 24,
  md: 32,
  lg: 48,
  xl: 64,
  xxl: 128,
};

const Loader = (props: LoaderProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { size, color, width, height, ...rest } = props;
  const actualSize: number = (sizeMapping[size] || size || sizeMapping['xl']) * 1.5;

  const src = color === 'white' ? LoaderMagicWand : LoaderMagicWand;

  return (
    <img
      className={styles.root}
      width={actualSize}
      // height={actualSize}
      src={src}
      alt=""
      {...rest}
    />
  );
};

export type { LoaderProps };
export { Loader };
