import '@styles/global.scss';
import '@mantine/charts/styles.css';

import { useAuth0 } from '@auth0/auth0-react';
import { useUpdateUser } from '@axios/mutations';
import { useUser, useUserActivities, useUsers } from '@axios/queries';
import { ScreenLoader } from '@components';
import ErrorPage from '@components/error-page/ErrorPage';
import { Admin } from '@containers/admin/Admin';
import { useAdminStore } from '@redux/store';
import { changeLanguage } from '@services/lang/i18n';
import { decodeJWT } from '@utils/decodeJWT';
import { Routes } from 'generouted/react-router';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const App = () => {
  useEffect(() => {
    document.body.classList.add('body');
    return () => {
      document.body.classList.remove('body');
    };
  }, []);

  const {
    isAuthenticated,
    isLoading: isLoadingAuth,
    user,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const { i18n } = useTranslation();

  // useEffect(() => {
  //   const checkTokenValidity = async () => {
  //     if (isAuthenticated) {
  //       try {
  //         const token = await getAccessTokenSilently();
  //         const decoded = decodeJWT(token);

  //         // Create a date object for April 26, 2024, at 7:40 AM UTC
  //         const date = new Date(Date.UTC(2024, 3, 26, 7, 45, 0)); // Note: Month is 0-indexed (3 = April)

  //         // Convert the date object to a UNIX timestamp
  //         const cutoffDate = Math.floor(date.getTime() / 1000);

  //         // Check local storage for the flag
  //         const hasLoggedOutPostUpdate = localStorage.getItem('hasLoggedOutPostUpdate');

  //         // console.log(decoded.iat);
  //         // console.log(cutoffDate);
  //         console.log('decoded.iat < cutoffDate:', decoded.iat < cutoffDate);
  //         console.log('hasLoggedOutPostUpdate:', hasLoggedOutPostUpdate);

  //         if (!hasLoggedOutPostUpdate) {
  //           console.log(
  //             'Token issued before April 26, 2024, 9:40 AM Paris time, logging out...',
  //           );
  //           localStorage.setItem('hasLoggedOutPostUpdate', 'true'); // Set the flag to prevent future logouts

  //           void logout();
  //         }
  //       } catch (error) {
  //         console.error('Error retrieving or decoding token:', error);
  //       }
  //     }
  //   };

  //   void checkTokenValidity();
  // }, [isAuthenticated, getAccessTokenSilently, logout]);

  // Accessing state
  const selectedUserId = useAdminStore((state) => state.userId);

  // Accessing actions
  const setAdmin = useAdminStore((state) => state.setAdmin);
  const setUserId = useAdminStore((state) => state.setUserId);
  const user_id = useAdminStore((state) => state.userId);

  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch: refetchUser,
  } = useUser(user?.sub, {
    enabled: !!user?.sub,
  });

  // Determine if we should show the Admin selection component
  const shouldSelectUser = userData?.is_admin && !selectedUserId;

  const { data: users, isLoading: isLoadingUsers } = useUsers(null, {
    enabled: !!userData?.is_admin,
  });

  // Updated useUpdateUser hook with onSuccess callback
  const { mutateAsync: updateUser } = useUpdateUser(user?.sub);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get('user_id');

    if (user_id) {
      setUserId(user_id);
    }
  }, []);

  useEffect(() => {
    if (userData?.is_admin) {
      setAdmin(userData.is_admin);
    }
  }, [userData]);

  useEffect(() => {
    const setLanguage = async () => {
      const userLang = userData?.language;
      const browserLang = i18n.language.split('-')[0];
      const preferredLang = userLang ?? browserLang;
      const languageCode = preferredLang === 'fr' ? 'fr' : 'en';

      console.log('User data language:', userData?.language);
      console.log('Browser language (processed):', browserLang);
      console.log('Preferred language to set:', languageCode);

      if (i18n.language !== languageCode) {
        await changeLanguage(languageCode);
      }

      if (!userLang || userLang !== languageCode) {
        await updateUser({
          user_id: user?.user_id,
          language: languageCode,
        });
      }
    };

    // Only run once, at the first render
    if (userData) {
      void setLanguage();
    }
  }, [userData]); // Run whenever userData changes

  const { data: userActivities, isLoading: isLoadingUserActivities } = useUserActivities(
    user_id,
    {
      enabled: !!userData?.is_admin,
    },
  );

  const isLoading = useMemo(() => {
    return (
      isLoadingUser || isLoadingAuth || !isAuthenticated || isLoadingUsers
      // ||
      // isLoadingUserActivities
    );
  }, [
    isAuthenticated,
    isLoadingAuth,
    isLoadingUser,
    isLoadingUsers,
    // isLoadingUserActivities,
  ]);

  if (isLoading && userData?.is_admin) {
    return <ScreenLoader />;
  }

  if (shouldSelectUser) {
    return <Admin user={userData} users={users} userActivities={userActivities} />;
  }

  return <Routes />;
};

export default App;
export const Catch = () => {
  return <ErrorPage />;
};
