import '@mantine/charts/styles.css';
import '@mantine/core/styles.css';

import { useUpdateUser } from '@axios/mutations';
import { User } from '@axios/queries';
import ArrowDown from '@images/arrow-down-s-line.svg';
import globalIcon from '@images/global-line.svg';
import { Menu } from '@mantine/core';
import { changeLanguage } from '@services/lang/i18n';
import Cookies from 'js-cookie';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// import { useNavigate } from 'react-router-dom';
import styles from './LanguageSwitcher.module.scss';

type LanguageSwitcherProps = {
  user: User;
};

const LanguageSwitcher = ({ user }: LanguageSwitcherProps) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const defaultLanguage = 'en';
  const languageCode = i18n.language.split('-')[0] == 'fr' ? 'fr' : defaultLanguage;

  const [language, setLanguage] = useState(languageCode);
  const [toggleLanguageOpened, setToggleLanguageOpened] = useState(false);

  const { mutateAsync: updateUser } = useUpdateUser(user?.user_id);

  const handleLanguageChange = async () => {
    const newLanguage = language === 'fr' ? 'en' : 'fr';
    setLanguage(newLanguage); // Set the state before the async operation to ensure immediate UI update

    await changeLanguage(newLanguage.toLowerCase());
    await updateUser({
      user_id: user?.user_id,
      language: newLanguage,
    });
  };

  return (
    <div className={styles.main}>
      <Menu
        opened={toggleLanguageOpened}
        onChange={setToggleLanguageOpened}
        shadow="md"
        position="bottom-end"
        offset={4}
        styles={{
          dropdown: {
            padding: '0',
            borderRadius: '8px',
            background: '#FFFFFF',
            boxShadow: '0px 40px 96px -8px #585C5F33',
          },
          label: {
            fontFamily: 'Plus Jakarta Sans, sans-serif',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '-0.006em',
            textAlign: 'left',
            color: '#525866',
            cursor: 'pointer !important',
          },
          item: {
            fontFamily: 'Plus Jakarta Sans, sans-serif',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.006em',
            textAlign: 'left',
            cursor: 'pointer !important',
          },
        }}
      >
        <Menu.Target>
          <div className={styles.main}>
            <button
              onClick={() => setToggleLanguageOpened((o) => !o)}
              className={styles.main}
            >
              <img style={{ marginRight: '4px' }} src={globalIcon} alt="" />
              <span>{t(language.toUpperCase())}</span>
              <img src={ArrowDown} alt="" />
            </button>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            onClick={async () => {
              await handleLanguageChange();
            }}
          >
            <div className={styles.main}>
              <span>{language == 'fr' ? t('EN') : t('FR')}</span>
            </div>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export { LanguageSwitcher };
