/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import { Channel, useSkipComment } from '@axios/mutations';
import { Comment, Reply, StripeData, User } from '@axios/queries';
import { Tabs } from '@components';
import PopupComponent from '@components/popup/PopupComponent';
import { CommentHeader } from '@components/posts/comment-header/CommentHeader';
import ElonComponent from '@components/posts/replies-list/elon/ElonComponent';
import RepliesList from '@components/posts/replies-list/RepliesList';
import elonIcon from '@images/elon_custom_icon.png';
import filterIcon from '@images/filter.svg';
import settingButton from '@images/setting_button.png';
import viewAllIcon from '@images/viewAll.svg';
import { Checkbox, Menu, Switch } from '@mantine/core';
import { usePopupStore } from '@redux/store';
import Cookies from 'js-cookie';
import * as moment from 'moment-timezone';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FiltersPopup from './popup-filters/FiltersPopup';
import SettingsPopup from './popup-settings/SettingsPopup'; // Assuming you'll name your new component SettingsPopup
import { PostHeader } from './post-header/PostHeader';
import styles from './Task.module.scss';
import TasksList from './tasks-list/TasksList';

type TaskProps = {
  tasks: Comment[];
  recentHiddenComments: Comment[];
  channels: Channel[];
  user: User;
  stripeData: StripeData;
};

const Task = ({ tasks, recentHiddenComments, channels, user, stripeData }: TaskProps) => {
  const { t } = useTranslation();

  // Function to extract all comment_ids
  const getAllCommentIds = (tasks: { [key: string]: any }): string[] => {
    if (!tasks) {
      return [];
    }

    return Object.values(tasks)
      .flat() // Flatten the array of Task arrays into a single array of Task
      .map((task) => task.comment_id); // Extract the comment_id from each Task
  };

  // Example usage
  // const dailyTasksIDs = getAllCommentIds(user.dailyTasks);
  const dailyTasksIDs = tasks.filter((t) => t.task).map((t) => t.comment_id);

  const [toggleFilterOpened, setToggleFilterOpened] = useState(false);

  const twoDaysAgo = new Date();
  twoDaysAgo.setDate(twoDaysAgo.getDate() - 7);

  const uniqueAdsAccounts = channels.reduce((acc, channel) => {
    if (channel.ads_accounts && channel.ads_accounts.length > 0) {
      channel.ads_accounts.forEach((account) => {
        if (account) {
          // Ensuring account is not undefined
          const uniqueKey = `${account.name}-${account.ad_account_id}`;
          if (!acc.has(uniqueKey)) {
            acc.set(uniqueKey, {
              value: account.ad_account_id,
              label: `${account.name} (${account.ad_account_id})`,
            });
          }
        }
      });
    }
    return acc;
  }, new Map());

  const adsAccounts = Array.from(uniqueAdsAccounts.values());

  const [filters, setFilters] = useState({
    selectedChannels: channels.map((c) => c.channel_id),
    viewAll: false,
    dateRange: [twoDaysAgo, new Date()] as [Date, Date],
    filtersType: {
      ads:
        user?.subscription_plan == 2 || user?.stripe_subscription_status == 'trialing'
          ? true
          : false,
      organic: true,
    },
    adsAccounts:
      user?.subscription_plan == 2 || user?.stripe_subscription_status == 'trialing'
        ? (adsAccounts.map((account) => account.value) as [string])
        : ([] as unknown as [string]),
  });

  const selectedRef = useRef(null);
  const [activeTab, setActiveTab] = useState(t('tasks.notConfirmed')); // Default active tab
  const [skipAutoSelect, setSkipAutoSelect] = useState(false);

  const [filteredTasks, setFilteredTasks] = useState(() => {
    return tasks?.filter((t) => {
      if (filters?.viewAll) {
        if (t.skipped) {
          return false;
        }
        return true;
      } else {
        if (!dailyTasksIDs?.includes(t.comment_id)) {
          return false;
        }

        if (t.skipped) {
          return false;
        }

        return true;
      }
    });
  });

  const [tasksLeft, setTasksLeft] = useState(() => {
    return filteredTasks
      .filter((task) => {
        if (task.hidden?.status) {
          return false;
        }

        if (task.reply) {
          return false;
        } else {
          const username = task.channel.username;
          const name = task.channel.name;

          const reply = (task.replies as Reply[]).find((r) => {
            return (
              r.username == username ||
              r.username?.includes(name) ||
              r.writtenByAI ||
              r.noReplyNeeded
            );
          });

          if (reply) {
            return false;
          }
        }
        return true;
      })
      .sort((a, b) => {
        // Checking for the presence of aiReply and status conditions
        const aPriority =
          (a.aiReply ? 1 : 0) -
          (a.shouldIgnore?.status ? 1 : 0) -
          (a.lackContext?.status ? 1 : 0);
        const bPriority =
          (b.aiReply ? 1 : 0) -
          (b.shouldIgnore?.status ? 1 : 0) -
          (b.lackContext?.status ? 1 : 0);

        // If priorities are equal, then sort by timestamp
        if (aPriority === bPriority) {
          return Date.parse(b.timestamp) - Date.parse(a.timestamp);
        }

        // Otherwise, sort by calculated priority (higher priority comes first)
        return bPriority - aPriority;
      });
  });

  const [tasksDone, setTasksDone] = useState(() => {
    return filteredTasks
      .filter((task) => {
        if (task.hidden?.status) {
          return false;
        }

        if (task.reply) {
          if (filters?.viewAll) {
            return true;
          }

          // if (task.reply?.writtenByAI) {
          //   return true;
          // }

          return true;
        } else {
          console.log('NO TASK REPLY');

          const username = task.channel.username;
          const name = task.channel.name;

          const reply = (task.replies as Reply[]).find((r) => {
            if (
              r.username == username ||
              r.username?.includes(name) ||
              r.writtenByAI ||
              r.noReplyNeeded
            ) {
              return true;
            }
          });

          if (reply) {
            if (filters?.viewAll) {
              return true;
            }

            const username = task.channel.username;
            const name = task.channel.name;

            if (reply) {
              return true;
            }
          }
        }
        return false;
      })
      .sort((a, b) => {
        // Checking for the presence of aiReply and status conditions
        const aPriority =
          (a.aiReply ? 1 : 0) -
          (a.shouldIgnore?.status ? 1 : 0) -
          (a.lackContext?.status ? 1 : 0);
        const bPriority =
          (b.aiReply ? 1 : 0) -
          (b.shouldIgnore?.status ? 1 : 0) -
          (b.lackContext?.status ? 1 : 0);

        // If priorities are equal, then sort by timestamp
        if (aPriority === bPriority) {
          return Date.parse(b.timestamp) - Date.parse(a.timestamp);
        }

        // Otherwise, sort by calculated priority (higher priority comes first)
        return bPriority - aPriority;
      });
  });

  const [hiddenComments, setHiddenComments] = useState(() => {
    // Combine and filter hidden items
    const combined = [
      ...filteredTasks.filter((task) => task.hidden?.status),
      ...recentHiddenComments.filter((comment) => comment.hidden?.status),
    ];

    // Remove duplicates by converting to a Map and back to an array
    const unique = Array.from(
      new Map(combined.map((item) => [item.comment_id, item])).values(),
    );

    // Sort by timestamp, most recent first
    return unique.sort((a, b) => {
      // Checking for the presence of aiReply and status conditions
      const aPriority =
        (a.aiReply ? 1 : 0) -
        (a.shouldIgnore?.status ? 1 : 0) -
        (a.lackContext?.status ? 1 : 0);
      const bPriority =
        (b.aiReply ? 1 : 0) -
        (b.shouldIgnore?.status ? 1 : 0) -
        (b.lackContext?.status ? 1 : 0);

      // If priorities are equal, then sort by timestamp
      if (aPriority === bPriority) {
        return Date.parse(b.timestamp) - Date.parse(a.timestamp);
      }

      // Otherwise, sort by calculated priority (higher priority comes first)
      return bPriority - aPriority;
    });
  });

  const [sending, setSending] = useState(false);
  const [skipping, setSkipping] = useState(false);

  // Inside the Task component, before return statement, manage the visibility of the popup
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);

  // Method to toggle the popup visibility
  const togglePopup = () => setShowSettingsPopup(!showSettingsPopup);

  const applyFilters = (filters) => {
    setToggleFilterOpened(false);

    const filteredTasks = tasks
      .filter((task) => {
        const type = task?.post?.mediaType;
        // const postDate = new Date(task?.post?.timestamp);
        const adAccount = task?.post?.ad_account_id;

        const channel_id = task?.channel?.channel_id;

        // Checking if the adAccount is in the list of selected ad accounts in filters.adAccounts
        const isAdAccountSelected = type?.includes('ADS')
          ? filters?.adsAccounts.includes(adAccount) ||
            filters?.adsAccounts?.length == adsAccounts.length
          : true;

        // Checking if the channel is in the list of selected ad channel in filters.selectedChannels
        const isChannelSelected = filters?.selectedChannels?.includes(channel_id);

        if (!isChannelSelected) {
          return false;
        }

        if (!filters.filtersType.ads && type?.includes('ADS')) {
          return false;
        }
        if (!filters.filtersType.organic && !type?.includes('ADS')) {
          return false;
        }

        if (!isAdAccountSelected) {
          return false;
        }

        if (!filters.viewAll && !dailyTasksIDs.includes(task.comment_id)) {
          return false;
        }

        if (task.skipped) {
          return false;
        }

        // Apply the new filters
        return true;
      })
      .sort((a, b) => {
        if (!filters.viewAll) {
          // Checking for the presence of aiReply and status conditions
          const aPriority =
            (a.aiReply ? 1 : 0) -
            (a.shouldIgnore?.status ? 1 : 0) -
            (a.lackContext?.status ? 1 : 0);
          const bPriority =
            (b.aiReply ? 1 : 0) -
            (b.shouldIgnore?.status ? 1 : 0) -
            (b.lackContext?.status ? 1 : 0);

          // If priorities are equal, then sort by timestamp
          if (aPriority === bPriority) {
            return Date.parse(b.timestamp) - Date.parse(a.timestamp);
          }
          // Otherwise, sort by calculated priority (higher priority comes first)
          return bPriority - aPriority;
        }

        return Date.parse(b.timestamp) - Date.parse(a.timestamp);
      });

    setFilteredTasks(filteredTasks);

    const newTasksLeft = filteredTasks
      .filter((task) => {
        if (task.hidden?.status) {
          return false;
        }

        if (task.reply) {
          return false;
        } else {
          const username = task.channel.username;
          const name = task.channel.name;

          const reply = (task.replies as Reply[]).find((r) => {
            return (
              r.username == username ||
              r.username?.includes(name) ||
              r.writtenByAI ||
              r.noReplyNeeded
            );
          });

          if (reply) {
            return false;
          }
        }
        return true;
      })
      .sort((a, b) => {
        if (!filters.viewAll) {
          // Checking for the presence of aiReply and status conditions
          const aPriority =
            (a.aiReply ? 1 : 0) -
            (a.shouldIgnore?.status ? 1 : 0) -
            (a.lackContext?.status ? 1 : 0);
          const bPriority =
            (b.aiReply ? 1 : 0) -
            (b.shouldIgnore?.status ? 1 : 0) -
            (b.lackContext?.status ? 1 : 0);

          // If priorities are equal, then sort by timestamp
          if (aPriority === bPriority) {
            return Date.parse(b.timestamp) - Date.parse(a.timestamp);
          }
          // Otherwise, sort by calculated priority (higher priority comes first)
          return bPriority - aPriority;
        }

        return Date.parse(b.timestamp) - Date.parse(a.timestamp);
      });

    setTasksLeft(newTasksLeft);

    const newTasksDone = filteredTasks
      .filter((task) => {
        if (task.hidden?.status) {
          return false;
        }

        if (task.reply) {
          if (filters?.viewAll) {
            return true;
          }

          // if (task.reply?.writtenByAI) {
          //   return true;
          // }
          return true;
        } else {
          const username = task.channel.username;
          const name = task.channel.name;

          const reply = (task.replies as Reply[]).find((r) => {
            return (
              r.username == username ||
              r.username?.includes(name) ||
              r.writtenByAI ||
              r.noReplyNeeded
            );
          });

          if (reply) {
            if (filters?.viewAll) {
              return true;
            }

            // if (task.reply?.writtenByAI) {
            //   return true;
            // }
            return true;
          }
        }
        return false;
      })
      .sort((a, b) => {
        if (!filters.viewAll) {
          // Checking for the presence of aiReply and status conditions
          const aPriority =
            (a.aiReply ? 1 : 0) -
            (a.shouldIgnore?.status ? 1 : 0) -
            (a.lackContext?.status ? 1 : 0);
          const bPriority =
            (b.aiReply ? 1 : 0) -
            (b.shouldIgnore?.status ? 1 : 0) -
            (b.lackContext?.status ? 1 : 0);

          // If priorities are equal, then sort by timestamp
          if (aPriority === bPriority) {
            return Date.parse(b.timestamp) - Date.parse(a.timestamp);
          }
          // Otherwise, sort by calculated priority (higher priority comes first)
          return bPriority - aPriority;
        }

        return Date.parse(b.timestamp) - Date.parse(a.timestamp);
      });

    setTasksDone(newTasksDone);

    // Combine and filter hidden items
    const combined = [
      ...filteredTasks.filter((task) => task.hidden?.status),
      ...recentHiddenComments.filter((comment) => comment.hidden?.status),
    ];

    // Remove duplicates by converting to a Map and back to an array
    let uniques = Array.from(
      new Map(combined.map((item) => [item.comment_id, item])).values(),
    );

    // Sort by timestamp, most recent first
    uniques = uniques.sort((a, b) => {
      if (!filters.viewAll) {
        // Checking for the presence of aiReply and status conditions
        const aPriority =
          (a.aiReply ? 1 : 0) -
          (a.shouldIgnore?.status ? 1 : 0) -
          (a.lackContext?.status ? 1 : 0);
        const bPriority =
          (b.aiReply ? 1 : 0) -
          (b.shouldIgnore?.status ? 1 : 0) -
          (b.lackContext?.status ? 1 : 0);

        // If priorities are equal, then sort by timestamp
        if (aPriority === bPriority) {
          return Date.parse(b.timestamp) - Date.parse(a.timestamp);
        }
        // Otherwise, sort by calculated priority (higher priority comes first)
        return bPriority - aPriority;
      }

      return Date.parse(b.timestamp) - Date.parse(a.timestamp);
    });

    setHiddenComments(uniques);

    // Serialize and save filters to a cookie
    const serializedFilters = JSON.stringify(filters);
    Cookies.set(`tasks-filters-${user?.user_id}`, serializedFilters, { expires: 30 }); // Expires in 30 days
  };

  const applyViewAll = () => {
    const newFilters = {
      ...filters,
      viewAll: !filters.viewAll,
    };

    applyFilters(newFilters);
    setFilters(newFilters);
  };

  // Initialize selectedInteraction with the first interaction of the default active tab
  const [selectedInteraction, setSelectedInteraction] = useState<Comment | null>();

  const setPopup = usePopupStore((state) => state.setPopup);

  const { mutateAsync: skipCommentHook } = useSkipComment();

  // Function to update the selected interaction and tasks lists
  const valideComment = (reply: Reply) => {
    if (!selectedInteraction) return;

    setSending(true);

    console.log('SENDING!!');
    const animationDuration = 600; // Exemple : durée de l'animation en ms

    selectedInteraction.reply = reply;
    selectedInteraction.replies = [...selectedInteraction.replies, reply] as Reply[];

    setTimeout(() => {
      const updatedTasks = filteredTasks
        .map((task) =>
          task.comment_id === selectedInteraction.comment_id
            ? { ...task, reply: reply, aiReply: null }
            : task,
        )
        .sort((a, b) => {
          // Checking for the presence of aiReply and status conditions
          const aPriority =
            (a.aiReply ? 1 : 0) -
            (a.shouldIgnore?.status ? 1 : 0) -
            (a.lackContext?.status ? 1 : 0);
          const bPriority =
            (b.aiReply ? 1 : 0) -
            (b.shouldIgnore?.status ? 1 : 0) -
            (b.lackContext?.status ? 1 : 0);

          // If priorities are equal, then sort by timestamp
          if (aPriority === bPriority) {
            return Date.parse(b.timestamp) - Date.parse(a.timestamp);
          }

          // Otherwise, sort by calculated priority (higher priority comes first)
          return bPriority - aPriority;
        });

      setTasksLeft(updatedTasks.filter((task) => !task.reply && !task.hidden?.status));
      setTasksDone(updatedTasks.filter((task) => task.reply && !task.hidden?.status));
      setHiddenComments([
        ...updatedTasks.filter((task) => task.hidden?.status),
        ...hiddenComments.filter((c) => c.hidden?.status),
      ]);

      // Trouvez l'index de l'interaction actuelle
      const index = tasksLeft.findIndex(
        (task) => task.comment_id === selectedInteraction.comment_id,
      );

      // Déterminez l'interaction à sélectionner ensuite
      let nextSelectedInteraction;

      const newTasksLeft = updatedTasks.filter((task) => !task.reply && task.aiReply);
      if (index === tasksLeft.length - 1) {
        nextSelectedInteraction =
          newTasksLeft.length > 1 ? newTasksLeft[newTasksLeft.length - 1] : null;
      } else {
        nextSelectedInteraction = newTasksLeft[index];
      }

      setSkipAutoSelect(true); // Prevent auto-selection from overriding this

      // Mise à jour de l'interaction sélectionnée
      setSelectedInteraction(nextSelectedInteraction);

      setSending(false); // Simulez la fin de l'animation
    }, animationDuration);
  };

  // Function to update the selected interaction and tasks lists
  const onHide = (comment: Comment) => {
    if (!selectedInteraction) return;

    console.log('SELECTED INTERACTION HIDDEN', selectedInteraction.hidden?.status);
    const animationDuration = 100; // Exemple : durée de l'animation en ms

    setTimeout(() => {
      const updatedTasks = filteredTasks
        .map((task) =>
          task.comment_id === selectedInteraction.comment_id
            ? {
                ...task,
                hidden: {
                  status: selectedInteraction.hidden?.status,
                  timestamp: new Date().toDateString(),
                },
              }
            : task,
        )
        .sort((a, b) => {
          // Checking for the presence of aiReply and status conditions
          const aPriority =
            (a.aiReply ? 1 : 0) -
            (a.shouldIgnore?.status ? 1 : 0) -
            (a.lackContext?.status ? 1 : 0);
          const bPriority =
            (b.aiReply ? 1 : 0) -
            (b.shouldIgnore?.status ? 1 : 0) -
            (b.lackContext?.status ? 1 : 0);

          // If priorities are equal, then sort by timestamp
          if (aPriority === bPriority) {
            return Date.parse(b.timestamp) - Date.parse(a.timestamp);
          }

          // Otherwise, sort by calculated priority (higher priority comes first)
          return bPriority - aPriority;
        });

      setTasksLeft(updatedTasks.filter((task) => !task.reply && !task.hidden?.status));
      setTasksDone(updatedTasks.filter((task) => task.reply && !task.hidden?.status));
      setHiddenComments([
        ...updatedTasks.filter((task) => task.hidden?.status),
        ...hiddenComments.filter((c) => c.hidden?.status),
      ]);

      // Trouvez l'index de l'interaction actuelle
      const index = tasksLeft.findIndex(
        (task) => task.comment_id === selectedInteraction.comment_id,
      );

      // Déterminez l'interaction à sélectionner ensuite
      let nextSelectedInteraction;

      const newTasksLeft = updatedTasks.filter(
        (task) => !task.reply && !task.hidden?.status,
      );
      if (index === tasksLeft.length - 1) {
        nextSelectedInteraction =
          newTasksLeft.length > 1 ? newTasksLeft[newTasksLeft.length - 1] : null;
      } else {
        nextSelectedInteraction = newTasksLeft[index];
      }

      setSkipAutoSelect(true); // Prevent auto-selection from overriding this

      // Mise à jour de l'interaction sélectionnée
      setSelectedInteraction(nextSelectedInteraction);

      setSending(false); // Simulez la fin de l'animation
    }, animationDuration);
  };

  // Function to update the selected interaction and tasks lists
  const onLike = (comment: Comment) => {
    if (!selectedInteraction) return;

    setSkipAutoSelect(true); // Prevent auto-selection from overriding this

    console.log('SELECTED INTERACTION LIKED', selectedInteraction.liked?.status);

    const updatedTasks = filteredTasks
      .map((task) =>
        task.comment_id === selectedInteraction.comment_id
          ? {
              ...task,
              liked: {
                status: selectedInteraction.liked?.status,
                timestamp: new Date().toDateString(),
              },
            }
          : task,
      )
      .sort((a, b) => {
        // Checking for the presence of aiReply and status conditions
        const aPriority =
          (a.aiReply ? 1 : 0) -
          (a.shouldIgnore?.status ? 1 : 0) -
          (a.lackContext?.status ? 1 : 0);
        const bPriority =
          (b.aiReply ? 1 : 0) -
          (b.shouldIgnore?.status ? 1 : 0) -
          (b.lackContext?.status ? 1 : 0);

        // If priorities are equal, then sort by timestamp
        if (aPriority === bPriority) {
          return Date.parse(b.timestamp) - Date.parse(a.timestamp);
        }

        // Otherwise, sort by calculated priority (higher priority comes first)
        return bPriority - aPriority;
      });

    setTasksLeft(updatedTasks.filter((task) => !task.reply && !task.hidden?.status));
    setTasksDone(updatedTasks.filter((task) => task.reply && !task.hidden?.status));
    setHiddenComments([
      ...updatedTasks.filter((task) => task.hidden?.status),
      ...hiddenComments.filter((c) => c.hidden?.status),
    ]);
  };

  // Function to update the selected interaction and tasks lists
  const skipComment = () => {
    console.log('SKIP COMMENT:', selectedInteraction);
    if (!selectedInteraction) return;

    void skipCommentHook({ comment_id: selectedInteraction.comment_id });

    setSkipping(true);

    const animationDuration = 600; // Exemple : durée de l'animation en ms

    selectedInteraction.skipped = true;

    setTimeout(() => {
      const updatedTasks = filteredTasks
        .map((task) =>
          task.comment_id === selectedInteraction.comment_id
            ? { ...task, skipped: true }
            : task,
        )
        .filter((task) => !task.skipped)
        .sort((a, b) => {
          // Checking for the presence of aiReply and status conditions
          const aPriority =
            (a.aiReply ? 1 : 0) -
            (a.shouldIgnore?.status ? 1 : 0) -
            (a.lackContext?.status ? 1 : 0);
          const bPriority =
            (b.aiReply ? 1 : 0) -
            (b.shouldIgnore?.status ? 1 : 0) -
            (b.lackContext?.status ? 1 : 0);

          // If priorities are equal, then sort by timestamp
          if (aPriority === bPriority) {
            return Date.parse(b.timestamp) - Date.parse(a.timestamp);
          }

          // Otherwise, sort by calculated priority (higher priority comes first)
          return bPriority - aPriority;
        });

      setTasksLeft(updatedTasks.filter((task) => !task.reply && !task.hidden?.status));
      setTasksDone(updatedTasks.filter((task) => task.reply && !task.hidden?.status));
      setHiddenComments([
        ...updatedTasks.filter((task) => task.hidden?.status),
        ...hiddenComments.filter((c) => c.hidden?.status),
      ]);

      // Trouvez l'index de l'interaction actuelle
      const index = tasksLeft.findIndex(
        (task) => task.comment_id === selectedInteraction.comment_id,
      );

      // Déterminez l'interaction à sélectionner ensuite
      let nextSelectedInteraction;

      const newTasksLeft = updatedTasks.filter((task) => !task.reply && task.aiReply);
      if (index === tasksLeft.length - 1) {
        nextSelectedInteraction =
          newTasksLeft.length > 1 ? newTasksLeft[newTasksLeft.length - 1] : null;
      } else {
        nextSelectedInteraction = newTasksLeft[index];
      }

      setSkipAutoSelect(true); // Prevent auto-selection from overriding this

      // Mise à jour de l'interaction sélectionnée
      setSelectedInteraction(nextSelectedInteraction);

      setSkipping(false); // Simulez la fin de l'animation
    }, animationDuration);
  };

  const showInfoPopup = () => {
    setPopup(
      <PopupComponent
        title={t('tasks.popup.title')}
        description={
          <p className={styles.popupDescription}>
            {t('tasks.popup.description.firstPart')}
            <b>{t('tasks.popup.description.secondPart')}</b>
            {t('tasks.popup.description.thirdPart')} <br />
            <br />
            <i>{t('tasks.popup.information')}</i>
          </p>
        }
        buttonText={t('tasks.popup.buttonText')}
        icon={elonIcon}
        onConfirm={() => {
          setPopup(null, null); // Assuming passing null hides the popup
          togglePopup();
        }}
      />,
      '/task',
    );
  };

  useEffect(() => {
    // Check if the 'visited' cookie exists
    const hasVisited = Cookies.get(`task-visited-${user?.user_id}`);
    if (!hasVisited) {
      showInfoPopup();

      // Set a cookie to mark that the user has visited
      Cookies.set(`task-visited-${user?.user_id}`, 'true', { expires: 365 }); // Expires in 365 days
    }
  }, [setPopup]);

  useEffect(() => {
    if (skipAutoSelect) {
      // Reset the flag and skip this round of auto-selection
      setSkipAutoSelect(false);
      return;
    }

    let currentTasks =
      activeTab == t('tasks.notConfirmed')
        ? tasksLeft
        : activeTab == t('tasks.confirmed')
        ? tasksDone
        : hiddenComments;

    currentTasks = currentTasks.sort((a, b) => {
      if (!filters.viewAll) {
        // Checking for the presence of aiReply and status conditions
        const aPriority =
          (a.aiReply ? 1 : 0) -
          (a.shouldIgnore?.status ? 1 : 0) -
          (a.lackContext?.status ? 1 : 0);
        const bPriority =
          (b.aiReply ? 1 : 0) -
          (b.shouldIgnore?.status ? 1 : 0) -
          (b.lackContext?.status ? 1 : 0);

        // If priorities are equal, then sort by timestamp
        if (aPriority === bPriority) {
          return Date.parse(b.timestamp) - Date.parse(a.timestamp);
        }
        // Otherwise, sort by calculated priority (higher priority comes first)
        return bPriority - aPriority;
      }

      return Date.parse(b.timestamp) - Date.parse(a.timestamp);
    });

    // console.log('CURRENT TASKS:', currentTasks);
    // console.log('TASKS DONE:', tasksDone);
    // console.log('TASKS LEFT:', tasksLeft);
    // console.log('FILTERED TASKS:', filteredTasks);
    // console.log('VIEW ALL:', viewAll);

    setSelectedInteraction(currentTasks.length > 0 ? currentTasks[0] : null);
  }, [activeTab, tasksLeft, tasksDone, hiddenComments, filters, filteredTasks]);

  const handleSelectInteraction = useCallback((interaction: Comment) => {
    setSelectedInteraction(interaction);
    setSkipAutoSelect(false); // Allow auto-selection to work again in the future
  }, []);

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [selectedInteraction]);

  useEffect(() => {
    // Load filters from cookies
    const serializedFilters = Cookies.get(`tasks-filters-${user?.user_id}`);
    if (serializedFilters) {
      const savedFilters = JSON.parse(serializedFilters);

      // Set loaded filters, maintaining separate control over dateRange
      setFilters((prevFilters) => ({
        ...savedFilters,
        // Reapply or adjust the dateRange as needed
        dateRange: prevFilters.dateRange,
      }));
      // Optionally, apply filters immediately if needed
      applyFilters(savedFilters);
    }
  }, []); // The empty array ensures this effect runs only once on mount

  // Function to render content for each tab
  const tabContent = (tasks: Comment[], key: string) => (
    <TasksList
      key={key}
      interactions={tasks}
      onSelectInteraction={handleSelectInteraction}
      selectedInteraction={selectedInteraction}
      sending={sending}
      skipping={skipping}
      applyViewAll={!filters?.viewAll && applyViewAll}
    />
  );

  // Tabs configuration
  const tabs = [
    {
      label: t('tasks.notConfirmed'),
      content: tabContent(tasksLeft, t('tasks.notConfirmed')),
    },
    {
      label: t('tasks.confirmed'),
      content: tabContent(tasksDone, t('tasks.confirmed')),
    },
    {
      label: t('HiddenComments'),
      content: tabContent(hiddenComments, t('HiddenComments')),
    },
  ];

  return (
    <div className={styles.root}>
      {showSettingsPopup && (
        <SettingsPopup
          onClose={togglePopup}
          channels={channels}
          user={user}
          stripeData={stripeData}
          adsAccounts={adsAccounts}
        />
      )}

      {toggleFilterOpened && (
        <FiltersPopup
          onClose={() => setToggleFilterOpened(!toggleFilterOpened)}
          channels={channels}
          user={user}
          stripeData={stripeData}
          filters={filters}
          setFilters={setFilters}
          applyFilters={applyFilters}
          disabledDateRange={true}
          filterRecentComments={true}
          filterChannels={true}
        />
      )}

      <div className={styles.leftPanel}>
        <div className={styles.titleContainer}>
          <div
            onClick={() => {
              showInfoPopup();
            }}
            className={styles.title}
          >
            {t('tasks.title')}
          </div>

          {filteredTasks?.length > 0 && (
            <div className={styles.badgeTasks}>
              {tasksLeft.length}
              {/* {tasksLeft.length > 1 ? t('tasks.tasks') : t('tasks.task')} */}
            </div>
          )}

          <div className={styles.buttonsContainer}>
            {/* <Menu
              opened={toggleFilterOpened}
              shadow="md"
              position="bottom-end"
              offset={10}
              width={320}
              styles={{
                dropdown: {
                  padding: '6px 12px 12px 12px',
                  borderRadius: '8px',
                  background: '#FFFFFF',
                  boxShadow:
                    'rgba(88, 92, 95, 0.2) 0px 40px 96px -8px, rgba(88, 92, 95, 0.2) 0px 0px 20px 0px',
                  cursor: 'unset',
                },
                item: {
                  marginTop: '12px',
                  fontFamily: 'Plus Jakarta Sans, sans-serif',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '-0.006em',
                  textAlign: 'left',
                  cursor: 'unset',
                },
              }}
            >
              <Menu.Target>
                <button
                  onClick={() => setToggleFilterOpened((o) => !o)}
                  className={styles.filterButton}
                >
                  <img src={filterIcon} alt="" />
                  {t('Filter')}
                </button>
              </Menu.Target>

              <Menu.Dropdown>
                <div style={{ padding: '0 12px' }}>
                  <Menu.Divider style={{ width: '100%' }} />
                </div>

                <Menu.Item>
                  <Switch
                    checked={viewAll}
                    onChange={(event) => setViewAll(!viewAll)}
                    color="#155EEF"
                    labelPosition="left"
                    label={!viewAll ? t('viewElon') : t('viewAll')}
                    size="md"
                    styles={{
                      body: {
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      },
                      label: {
                        fontSize: '14px',
                        cursor: 'pointer',
                      },
                      trackLabel: {
                        cursor: 'pointer',
                      },
                    }}
                  />
                </Menu.Item>

                <div style={{ padding: '12px 12px 0px 12px' }}>
                  <Menu.Divider style={{ width: '100%' }} />
                </div>

                <Menu.Item>
                  <Checkbox
                    styles={{
                      body: {
                        cursor: 'pointer',
                      },
                      label: {
                        fontSize: '14px',
                        cursor: 'pointer',
                      },
                      labelWrapper: {
                        cursor: 'pointer',
                      },
                      input: {
                        cursor: 'pointer',
                      },
                    }}
                    label={t('OrganicPosts')}
                    checked={filters.organic}
                    onChange={() => handleFilterChange('organic')}
                  />
                </Menu.Item>

                <Menu.Item>
                  <Checkbox
                    styles={{
                      body: {
                        cursor: 'pointer',
                      },
                      label: {
                        fontSize: '14px',
                        cursor: 'pointer',
                      },
                      labelWrapper: {
                        cursor: 'pointer',
                      },
                      input: {
                        cursor: 'pointer',
                      },
                    }}
                    label={t('SponsoredPosts')}
                    checked={filters.ads}
                    onChange={() => handleFilterChange('ads')}
                  />
                </Menu.Item>

                <div style={{ padding: '12px 12px 6px 12px' }}>
                  <Menu.Divider style={{ width: '100%' }} />
                </div>

                <div style={{ padding: '10px 6px' }}>
                  <button
                    onClick={applyFilters}
                    className={styles.applyFilterButton} // Define this style in your CSS
                  >
                    {t('ApplyFilters')}
                  </button>
                </div>
              </Menu.Dropdown>
            </Menu> */}

            <button
              onClick={() => setToggleFilterOpened(!toggleFilterOpened)}
              className={styles.filterButton}
            >
              <img src={filterIcon} alt="" />
              {t('Filter')}
            </button>

            <button onClick={togglePopup} className={styles.settingsButton}>
              <img src={settingButton} alt="" style={{ width: '40px', flexShrink: 0 }} />
            </button>
          </div>
        </div>

        <div className={styles.tasksListContainer}>
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={(newTab) => setActiveTab(newTab)} // Handle tab change
          />
        </div>
      </div>

      <div className={styles.rightPanel}>
        {selectedInteraction && (
          <>
            <div className={styles.repliesContainer}>
              <PostHeader
                channel={selectedInteraction.channel}
                post={selectedInteraction.post}
              />

              <CommentHeader
                interaction={selectedInteraction}
                selectedRef={selectedRef}
                selectedId={selectedInteraction?._id}
                onHide={onHide}
                onLike={onLike}
              />

              {(selectedInteraction.replies?.length > 0 || selectedInteraction.reply) && (
                <RepliesList
                  channel={selectedInteraction.channel}
                  interaction={selectedInteraction}
                  selectedRef={selectedRef}
                  selectedId={selectedInteraction?._id}
                  key={selectedInteraction?._id}
                />
              )}
            </div>

            <div className={styles.elonContainer}>
              <ElonComponent
                channel={selectedInteraction.channel}
                interaction={selectedInteraction}
                key={selectedInteraction._id}
                handleValidation={(reply) => {
                  valideComment(reply);
                }}
                showSkipButton={!selectedInteraction.reply}
                skipComment={() => skipComment()}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { Task };
