/* eslint-disable max-len */
import { AppState, RedirectLoginOptions, User } from '@auth0/auth0-react';
import { useUpdateUser } from '@axios/mutations';
import {
  useChannels,
  useNangoConnections,
  useStripeData,
  // useStripeSubscriptionStatus,
  useUser,
} from '@axios/queries';
import { Loader, NavBar, ScreenLoader } from '@components';
import ErrorPage from '@components/error-page/ErrorPage';
import ErrorMobile from '@components/error-page-mobile/ErrorMobile';
import LoginPage from '@components/login-page/LoginPage';
import PopupComponent from '@components/popup/PopupComponent';
import ChoosePlanPopup from '@components/setting/choose-plan-popup/ChoosePlanPopup';
import elonIcon from '@images/elon_custom_icon.png';
import errorIcon from '@images/error_icon.png';
import { setIsAdmin } from '@redux/slices/adminSlice';
import { usePopupStore } from '@redux/store';
import { Path, useNavigate } from '@router';
import { checkIsAdmin, validateEmail } from '@utils';
import { checkIsPaying } from '@utils/utils';
import { Crisp } from 'crisp-sdk-web';
import Cookies from 'js-cookie';
import LogRocket from 'logrocket';
import { cloneElement, ReactElement, useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import styles from './AppWrapper.module.scss';

type AppWrapperProps = {
  isAuthenticated: boolean;
  isLoadingAuth: boolean;
  loginWithRedirect: (options?: RedirectLoginOptions<AppState>) => Promise<void>;
  user: User;
  activePath?: Path;
  isLoading?: boolean;
  children?: ReactElement;
  mobileSupported?: boolean;
  loadingSkeleton?: ReactElement;
};

const AppWrapper = ({
  isAuthenticated,
  isLoadingAuth,
  loginWithRedirect,
  user,
  activePath,
  isLoading: isLoadingProp = false,
  children,
  mobileSupported = false,
  loadingSkeleton,
}: AppWrapperProps) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const location = useLocation();
  const { popupComponent, pathToShow } = usePopupStore();
  const navigate = useNavigate();

  const setPopup = usePopupStore((state) => state.setPopup); // Use the setPopup function

  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch: refetchUser,
  } = useUser(user?.sub, { enabled: !!user?.sub });

  const { data: stripeData, isLoading: isLoadingStripeData } = useStripeData();

  // const { data: stripeStatus, isLoading: isLoadingStripeStatus } =
  //   useStripeSubscriptionStatus({ enabled: !!stripeData?.subscription_id });

  const fineTuneModel = userData?.fine_tune_model;
  const isLoadingModel = fineTuneModel ? false : true;

  const {
    data: channels,
    isLoading: isLoadingChannels,
    refetch: refreshChannels,
  } = useChannels({ user_id: user?.sub }, { enabled: !!userData && !!user?.sub });

  useEffect(() => {
    if (user?.sub) {
      // Trigger a refetch of user data whenever the route changes
      void refetchUser();
      void refreshChannels();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (userData) {
      LogRocket.identify(userData.user_id, {
        name: userData.name,
        email: userData.email,

        // Add your own custom user variables here, ie:
        subscriptionType: 'pro',
      });
    }
  }, [userData?.email, userData?.name, userData?.user_id]);

  useEffect(() => {
    // if (isMobile && !mobileSupported && import.meta.env.VITE_NODE_ENV != 'development') {
    //   return;
    // }

    if (!isLoadingAuth && !isAuthenticated) {
      console.log('Redirecting to login');
      // Handle redirect logic
    } else if (
      user &&
      !isLoadingUser &&
      !isLoadingChannels &&
      !isLoadingStripeData &&
      (!userData?.onboarding_done || !channels?.length)
      // ||
      // (!userData?.fine_tune_job && !userData?.fine_tune_model))
    ) {
      console.log('userData:', userData);
      console.log('channels:', channels);
      console.log('stripeData:', stripeData);
      console.log('REDIRECT ONBOARDING');
      navigate('/onboarding');
    }
  }, [
    isAuthenticated,
    isLoadingAuth,
    user,
    userData,
    navigate,
    mobileSupported,
    isLoadingUser,
    isLoadingChannels,
    channels?.length,
    isLoadingStripeData,
    channels,
    stripeData,
  ]);

  const openPlanPopup = () => {
    setPopup(<ChoosePlanPopup user={userData} />);
  };

  useEffect(() => {
    if (!isLoadingStripeData && !isLoadingUser && userData && !stripeData?.hasAccess) {
      console.log('isLoadingStripeData:', isLoadingStripeData);
      console.log('hasAccess:', stripeData);

      setPopup(
        <PopupComponent
          title={t('appWrapper.popupSubscription.title')}
          description={
            stripeData?.subscription_id
              ? stripeData.status != 'canceled'
                ? t('appWrapper.popupSubscription.description')
                : t('subscriptionCanceled.description')
              : t('trialEnded.description')
          }
          buttonText={
            stripeData?.subscription_id
              ? stripeData.status != 'canceled'
                ? t('appWrapper.popupSubscription.buttonText')
                : t('subscriptionCanceled.buttonText')
              : t('trialEnded.buttonText')
          }
          icon={errorIcon}
          onConfirm={() => {
            // Rediriger l'utilisateur vers le portail client Stripe dans un nouvel onglet pour vérifier son abonnement ou mettre à jour ses informations de paiement
            stripeData?.subscription_id && stripeData.status != 'canceled'
              ? window.open(stripeData.customer_portal, '_blank')
              : openPlanPopup();
          }}
        />,
      );
    }
  }, [setPopup, isLoadingStripeData, stripeData, isLoadingUser, userData]);

  useEffect(() => {
    if (
      !fineTuneModel &&
      userData?.fine_tune_job &&
      !isLoadingStripeData &&
      stripeData?.hasAccess
    ) {
      Crisp.setZIndex(10); // TODO VERIFIY

      setPopup(
        <PopupComponent
          title={t('onboarding.popupFineTuning.title')}
          description={t('onboarding.popupFineTuning.description')}
          icon={elonIcon}
        />,
      );
    }
  }, [
    fineTuneModel,
    isLoadingStripeData,
    setPopup,
    stripeData,
    t,
    userData?.fine_tune_job,
  ]);

  // const isAdmin = checkIsAdmin(user);
  // const isPaying = checkIsPaying(user);

  // useEffect(() => {
  //   if (isAdmin) {
  //     dispatch(setIsAdmin(true));
  //   } else {
  //     dispatch(setIsAdmin(false));
  //   }
  // }, [isAdmin, dispatch, isPaying]);

  const isLoading = useMemo(() => {
    return (
      isLoadingProp ||
      isLoadingAuth ||
      !isAuthenticated ||
      isLoadingUser ||
      isLoadingChannels ||
      isLoadingStripeData
    );
  }, [
    isLoadingProp,
    isLoadingAuth,
    isAuthenticated,
    isLoadingUser,
    isLoadingChannels,
    isLoadingStripeData,
  ]);

  useEffect(() => {
    const pathWithoutCrisp = ['/task', '/post', '/onboarding'];

    // Function to check if the current path starts with any of the paths in pathWithoutCrisp
    const isPathExcluded = pathWithoutCrisp.some((excludedPath) =>
      location?.pathname?.includes(excludedPath),
    );

    if (!isLoading && userData && isAuthenticated) {
      Crisp.configure('6a975e2b-e56f-4161-b6ec-e3e13a870ebc');

      Crisp.setTokenId(userData?.user_id);
      Crisp.user.setEmail(userData?.email);
      Crisp.user.setNickname(userData?.name);
      Crisp.setZIndex(999999);

      Crisp.setHideOnMobile(true);

      if (isPathExcluded) {
        Crisp.setZIndex(-10);
      }
    } else {
      Crisp.setZIndex(-10);
    }
  }, [isAuthenticated, isLoading, userData, activePath, location?.pathname]);

  if (!isLoadingAuth && !isAuthenticated) {
    return <LoginPage loginWithRedirect={loginWithRedirect} />;
  }

  if (
    isLoadingAuth ||
    !isAuthenticated ||
    isLoadingChannels ||
    isLoadingUser ||
    isLoadingStripeData
  ) {
    return <ScreenLoader />;
  }

  if (isMobile && !mobileSupported && import.meta.env.VITE_NODE_ENV != 'development') {
    return <ErrorMobile />;
  }

  // if (isLoadingModel && stripeStatus?.hasAccess) {
  //   // return <ScreenLoader message={t('loader.fineTuneModel')} />;
  // }

  return (
    <div className={styles.root}>
      <NavBar
        activePath={activePath}
        channels={channels}
        user_id={userData?.user_id}
        userData={userData}
        stripeData={stripeData}
      />
      {((pathToShow && location.pathname === pathToShow) ||
        (!pathToShow && popupComponent)) &&
        popupComponent}

      {isLoading || (!isLoadingStripeData && !stripeData?.hasAccess) ? (
        loadingSkeleton ? (
          cloneElement(loadingSkeleton)
        ) : (
          <Loader className={styles.loader} />
        )
      ) : (
        children && cloneElement(children)
      )}
    </div>
  );
};

export { AppWrapper };
