/* eslint-disable max-len */
import { Comment, SocialMediaInteraction } from '@axios/queries';
import { formatTimeToNowShort } from '@utils/formatTimeToNowShort';
import { getInitial } from '@utils/getInitial';
import React, { useEffect, useState } from 'react';

import styles from './CommentsList.module.scss';

type CommentsListProps = {
  interactions: Comment[];
  onSelectInteraction: (interaction: Comment) => void;
};

const CommentsList = ({ interactions, onSelectInteraction }: CommentsListProps) => {
  const [selectedId, setSelectedId] = useState<string>(null); // Default to the first item

  const handleSelect = (interaction: Comment) => {
    setSelectedId(interaction._id);
    onSelectInteraction(interaction);
  };

  const removeDuplicates = (interactions: Comment[]) => {
    const unique = new Map(
      interactions.map((interaction) => [interaction.comment_id, interaction]),
    );
    return Array.from(unique.values());
  };

  const sortedInteractions = removeDuplicates(interactions).sort(
    (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
  );

  useEffect(() => {
    // Set the selectedId only when the component mounts or when interactions become empty
    if (
      interactions.length > 0 &&
      (selectedId === null ||
        sortedInteractions.filter((interaction) => interaction._id == selectedId)
          .length == 0)
    ) {
      setSelectedId(interactions[0]._id);
    } else if (interactions.length === 0) {
      setSelectedId(null);
    }
    // Note: We intentionally do not include 'selectedId' in the dependency array
  }, [interactions]);

  return (
    <div className={styles.commentsList}>
      {sortedInteractions.map((interaction) => (
        <div
          key={interaction._id}
          className={`${styles.interactionItem} ${
            interaction._id === selectedId ? styles.selected : ''
          }`}
          onClick={() => handleSelect(interaction)}
        >
          <div className={styles.contentContainer}>
            <div className={styles.userAvatar}>
              <img
                style={{ borderRadius: '50%' }}
                src={
                  interaction.profilePictureUrl ??
                  `https://api.dicebear.com/7.x/micah/svg?seed=${
                    interaction.username || interaction.comment_id
                  }&flip=true&radius=50&baseColor=ac6651,f9c9b6,transparent&eyeShadowColor=d2eff3,e0ddff,ffeba4,ffedef,ffffff,transparent&backgroundColor=ffdfbf,d1d4f9,c0aede,b6e3f4,ffd5dc`
                  // `https://api.dicebear.com/7.x/initials/svg?seed=${interaction.username}&radius=50&backgroundColor=00897b,00acc1,039be5,1e88e5,3949ab,43a047,5e35b1,7cb342,8e24aa,c0ca33,d81b60,e53935,f4511e,fb8c00,fdd835,ffb300,ffd5dc,ffdfbf&fontFamily=Arial&chars=1`
                  // `https://api.dicebear.com/7.x/avataaars-neutral/svg?seed=${interaction.username}&radius=50&backgroundColor=d08b5b,edb98a,f8d25c,fd9841,ffdbb4,c0aede,b6e3f4,ffd5dc,ffdfbf,d1d4f9,ae5d29&eyebrows=default,defaultNatural,flatNatural,frownNatural,raisedExcited,raisedExcitedNatural,upDown,upDownNatural&eyes=closed,default,eyeRoll,happy,hearts,side,squint,surprised,wink,winkWacky&mouth=default,disbelief,eating,grimace,sad,screamOpen,serious,smile,tongue,twinkle,concerned`
                }
                alt=""
              />
            </div>

            <div className={styles.content}>
              {interaction.username && <b>@{interaction.username}:</b>} {interaction.text}
            </div>
          </div>

          <span className={styles.date}>
            {formatTimeToNowShort(interaction.timestamp)}
          </span>
        </div>
      ))}
    </div>
  );
};

export default CommentsList;
