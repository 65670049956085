import { useAxiosInstance } from '@axios/useAxiosInstance';
import { QueryFunction, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { Channel } from './useChannels';

type Post = {
  _id: string;
  user_id: string;
  channel_id: string;
  post_id: string;
  ad_account_id?: string;
  caption: string;
  transcription: string;
  mediaType: string;
  thumbnail_url: string;
  media_url: string;
  permalink: string;
  likeCount: number;
  timestamp: string;
  comments: string[] | Comment[];
  last_update?: string;
  created_date?: string;
  views?: number;
  shares?: number;
  repliedCommentsCount: number;
};

type Reply = {
  _id: string;
  user_id: string;
  channel_id: string;
  post_id: string;
  ad_account_id?: string;
  comment_id: string;
  reply_id: string;
  text: string;
  embedded_text?: number[];
  classification?: string;
  profilePictureUrl: string;
  username: string;
  likeCount: number;
  timestamp: string;
  writtenByAI: boolean;
  aiReply: { _id: string; text: string };
  reply?: Reply;
  noReplyNeeded?: boolean;
  hidden: {
    status: boolean;
    timestamp: string;
  };
  last_update?: string;
  created_date?: string;
};

type Comment = {
  _id: string;
  user_id: string;
  channel_id: string;
  channel?: Channel;
  post_id: string;
  ad_account_id?: string;
  post?: Post;
  comment_id: string;
  text: string;
  embedded_text?: number[];
  classification?: string;
  profilePictureUrl: string;
  username: string;
  likeCount: number;
  timestamp: string;
  reply: Reply;
  noReplyNeeded: boolean;
  replies: string[] | Reply[];
  writtenByAI: boolean;
  aiReply: { _id: string; text: string };
  hidden: {
    status: boolean;
    timestamp: string;
  };
  liked: {
    status: boolean;
    timestamp: string;
  };
  shouldIgnore?: {
    status: boolean;
    reason?: string;
  };
  lackContext: {
    status: boolean;
    reason: string;
  };
  shouldUseReply: {
    status: boolean;
    reason: string;
  };
  skipped: boolean;
  last_update?: string;
  created_date?: string;
  task: boolean;
};

const usePosts = (
  params: {
    post_id?: string;
    user_id?: string;
    channel_id?: string;
    page?: number;
  },
  options?: Omit<
    UseQueryOptions<Post[], Error, Post[] | Post, [string, string, string, string]>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  },
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const fetchPosts: QueryFunction<
    Post[],
    [string, string, string, string]
  > = async () => {
    const instance = await getAxiosInstance();
    const channel_id = params.channel_id;
    const post_id = params.post_id;
    const user_id = params.user_id;
    const page = params.page;

    return instance
      .get(
        `/api/fetch-data/posts?${channel_id && `channel_id=${channel_id}`}&${
          post_id && `post_id=${post_id}`
        }&${user_id && `user_id=${user_id}`}&${page && `page=${page}`}`,
      )
      .then((response) => response.data);
  };

  return useQuery<Post[], Error, Post[] | Post, [string, string, string, string]>(
    ['posts', params.channel_id, params.post_id, params.user_id],
    fetchPosts,
    {
      ...options,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
      onSuccess:
        options?.onSuccess ||
        ((data) => {
          if (import.meta.env.VITE_PRINT_SUCCESSFUL_ENDPOINTS === 'true') {
            console.log('Posts data fetched successfully!', data);
          }
        }),
      onError:
        options?.onError ||
        ((error) => {
          console.error('Error fetching Posts data:', error);
        }),
      enabled: options?.enabled && authenticated,
    },
  );
};

export type { Comment, Post, Reply };
export { usePosts };
