/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import { Channel } from '@axios/mutations';
import { Comment, Post, Reply, useCheckSync, usePosts } from '@axios/queries';
import { Tabs } from '@components';
import { CommentHeader } from '@components/posts/comment-header/CommentHeader';
import CommentsList from '@components/posts/comments-list/CommentsList';
import { PostHeader } from '@components/posts/post-header/PostHeader';
import ElonComponent from '@components/posts/replies-list/elon/ElonComponent';
import RepliesList from '@components/posts/replies-list/RepliesList';
import ChevronLeft from '@images/chevron-left-grey.svg';
import loaderBlueAnimation from '@images/LoaderBlue.json';
import Lottie from 'lottie-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './Posts.module.scss';

type PostsProps = {
  channel: Channel;
  post: Post;
};

const Posts = ({ channel, post: initialPost }: PostsProps) => {
  const { channel_id } = channel;
  const { post_id } = initialPost;
  const [post, setPost] = useState(initialPost);
  const navigate = useNavigate(); // If you're using react-router-dom

  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(t('posts.noReplied')); // Default active tab
  const [syncStatus, setSyncStatus] = useState('syncing');
  const pollingInterval = useRef<NodeJS.Timeout | null>(null);

  const { refetch } = usePosts(
    { channel_id: channel_id, post_id: post_id }, // Include page in the query key to refetch when it changes
    {
      enabled: false, // Keep the query disabled initially
      onSuccess: (newPost) => {
        setPost(newPost as Post);
      },
    },
  );

  const { refetch: refetchSyncStatus } = useCheckSync(
    {
      channel_id: channel_id,
      post_id: post_id,
    }, // Include page in the query key to refetch when it changes
    {
      enabled: false, // Keep the query disabled initially
      onSuccess: async (data) => {
        setSyncStatus(data.status); // Set the status based on the response
        if (data.status === 'complete') {
          clearInterval(pollingInterval.current); // Clear the interval if syncing is complete
          await refetch(); // Refetch the main data
        }
      },
    },
  );

  useEffect(() => {
    // Only set up polling if syncStatus indicates it's needed
    if (syncStatus === 'syncing') {
      pollingInterval.current = setInterval(() => {
        void refetchSyncStatus();
      }, 5000); // Poll every 0.5 second
    }

    // Clean up the interval when the component unmounts or syncStatus changes
    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current);
      }
    };
  }, [syncStatus, refetchSyncStatus]);

  const [interactions, setInteractions] = useState(post.comments as Comment[]);

  const selectedRef = useRef(null);

  // State to track the selected interaction
  const [selectedInteraction, setSelectedInteraction] = useState<Comment | null>(null);

  // Splitting interactions into replied and to be replied
  const repliedComments = useMemo(
    () =>
      interactions.filter(
        (interaction) => interaction.reply && !interaction.hidden?.status,
      ),
    [interactions],
  );

  const toBeRepliedComments = useMemo(
    () =>
      interactions.filter(
        (interaction) =>
          !interaction.reply &&
          !interaction.noReplyNeeded &&
          !interaction.hidden?.status &&
          interaction.username != channel.username &&
          !interaction.username?.includes(channel.name),
      ),
    [interactions],
  );

  const hiddenComments = useMemo(
    () => interactions.filter((interaction) => interaction.hidden?.status),
    [interactions],
  );

  useEffect(() => {
    console.log('selectedInteraction:', selectedInteraction);
  }, [selectedInteraction]);

  // Update selectedInteraction and selectedComments when interactions data changes
  useEffect(() => {
    // Determine which interactions to set based on the active tab
    const interactionsToSet =
      activeTab === t('posts.noReplied')
        ? toBeRepliedComments
        : activeTab === t('posts.replied')
        ? repliedComments
        : hiddenComments;

    if (interactionsToSet.length > 0) {
      const firstInteraction = interactionsToSet[0];
      handleSelectInteraction(firstInteraction);
    } else {
      setSelectedInteraction(null);
    }
  }, [activeTab, hiddenComments, repliedComments, toBeRepliedComments]);

  const handleSelectInteraction = useCallback(
    (interaction: Comment) => {
      setSelectedInteraction(interaction);
    },
    [interactions],
  );

  // Function to update the selected interaction and tasks lists
  const sendReply = (newReply: Reply) => {
    console.log('NEW REPLY:', newReply);
    // selectedInteraction.reply = newReply;
    // selectedInteraction.replies = [...(selectedInteraction.replies as Reply[]), newReply];

    const updatedComments = post.comments.map((comment) => {
      // Find the comment that matches the selectedInteraction.comment_id
      if (comment.comment_id === selectedInteraction.comment_id) {
        comment.reply = newReply;

        comment.replies = [...(comment.replies as Reply[]), newReply];
        // Return a new object with the updated reply
        return { ...comment, reply: newReply, aiReply: null };
      }
      // For all other comments, return them unchanged
      return comment;
    });

    post.comments = updatedComments;

    setPost(post);
    setInteractions(updatedComments);

    void refetch;
  };

  // Function to update the selected interaction and tasks lists
  const onHide = (comment: Comment) => {
    console.log('SELECTED INTERACTION HIDDEN', !selectedInteraction.hidden?.status);
    const updatedComments = post.comments.map((comment) => {
      // Find the comment that matches the selectedInteraction.comment_id
      if (comment.comment_id === selectedInteraction.comment_id) {
        // Return a new object with the updated reply
        return {
          ...comment,
          hidden: {
            status: !selectedInteraction.hidden?.status,
            timestamp: new Date().toString(),
          },
        };
      }
      // For all other comments, return them unchanged
      return comment;
    });

    post.comments = updatedComments;

    setPost(post);
    setInteractions(updatedComments);

    void refetch;
  };

  // Function to update the selected interaction and tasks lists
  const onLike = (comment: Comment) => {
    console.log('SELECTED INTERACTION LIKED', !selectedInteraction.liked?.status);
    const updatedComments = post.comments.map((comment) => {
      // Find the comment that matches the selectedInteraction.comment_id
      if (comment.comment_id === selectedInteraction.comment_id) {
        // Return a new object with the updated reply
        return {
          ...comment,
          liked: {
            status: !selectedInteraction.liked?.status,
            timestamp: new Date().toString(),
          },
        };
      }
      // For all other comments, return them unchanged
      return comment;
    });

    post.comments = updatedComments;

    setPost(post);
    setInteractions(updatedComments);

    void refetch;
  };

  // Function to render content for each tab
  const tabContent = (tabInteractions: Comment[]) => (
    <CommentsList
      interactions={tabInteractions}
      onSelectInteraction={handleSelectInteraction}
    />
  );

  // Tabs configuration
  const tabs = [
    {
      label: t('posts.noReplied'),
      content: tabContent(toBeRepliedComments),
      count: toBeRepliedComments.length > 0 ? toBeRepliedComments.length : undefined,
      loading: syncStatus === 'syncing',
    },
    {
      label: t('posts.replied'),
      content: tabContent(repliedComments),
      loading: syncStatus === 'syncing',
      count: repliedComments.length > 0 ? repliedComments.length : undefined,
    },
    {
      label: t('HiddenComments'),
      content: tabContent(hiddenComments),
    },
  ];

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [selectedInteraction]);

  return (
    <div className={styles.root}>
      <div className={styles.leftPanel}>
        <button
          onClick={() => navigate(`/channels/${channel.channel_id}`)}
          className={`${styles.backButton}`}
        >
          <img src={ChevronLeft} alt="" />
          Retour
        </button>

        <PostHeader channel={channel} post={post} />

        <div className={styles.commentsListContainer}>
          <Tabs tabs={tabs} activeTab={activeTab} onTabChange={setActiveTab} />
        </div>
      </div>

      <div className={styles.rightPanel}>
        {selectedInteraction && (
          <>
            <div className={styles.repliesContainer}>
              <CommentHeader
                interaction={{
                  ...selectedInteraction,
                  post: post,
                  channel: channel,
                  channel_id: channel_id,
                  post_id: post_id,
                }}
                selectedRef={selectedRef}
                selectedId={selectedInteraction?._id}
                onHide={onHide}
                onLike={onLike}
              />

              {selectedInteraction.replies?.length > 0 && (
                <RepliesList
                  channel={channel}
                  interaction={selectedInteraction}
                  selectedRef={selectedRef}
                  selectedId={selectedInteraction?._id}
                  key={selectedInteraction?._id}
                />
              )}

              {/* {selectedInteraction?.aiReply && (
            <ReplyComponent
              channel={channel}
              reply={{
                _id: selectedInteraction.aiReply._id,
                comment_id: selectedInteraction.aiReply.relatedTo,
                text: selectedInteraction.aiReply.text,
                username: 'ELON',
                profilePictureUrl: '',
                timestamp: '10/12/2024',
              }}
            />
          )} */}
            </div>

            <div className={styles.elonContainer}>
              <ElonComponent
                channel={channel}
                interaction={selectedInteraction}
                key={selectedInteraction._id}
                handleValidation={(reply) => sendReply(reply)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { Posts };
