import { useUpdateUser } from '@axios/mutations';
import { User } from '@axios/queries';
import { userDetails } from '@containers/onboarding/Onboarding';
import arrowRight from '@images/arrow-right-line.svg';
import arrowRightDisabled from '@images/arrow-right-line-grey.svg';
import checkIcon from '@images/check_white.svg';
import loaderAnimation from '@images/loader.json';
import welcomeIcon from '@images/welcome_icon.png';
import { isValidEmail } from '@utils'; // import the isValidEmail function
import i18next from 'i18next';
import Lottie from 'lottie-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './UserDetails.module.scss';

interface UserDetailsProps {
  onStepChange: (nextStep: number) => void;
  setUserDetails: (userDetails: userDetails) => void;
  userDetails: userDetails;
  user: User;
}

const UserDetails: React.FC<UserDetailsProps> = ({
  onStepChange,
  userDetails,
  setUserDetails,
  user,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState(
    user?.name
      ? user?.name
      : userDetails.name != userDetails.email
      ? userDetails.name
      : '',
  );
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState(userDetails.email);
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: updateUser } = useUpdateUser(userDetails?.user_id);

  const handleConfirm = () => {
    if (name && email) {
      setIsLoading(true); // Set loading to true

      console.log('UPDATING USER NAME:', name);
      void updateUser({
        user_id: userDetails.user_id,
        name: name,
      });

      setUserDetails({
        name: name,
        email: email,
        user_id: userDetails.user_id,
      });

      onStepChange(2); // Proceed to next step
    }
  };

  return (
    <div className={styles.userDetailsContainer}>
      <div className={styles.titleContainer}>
        <img src={welcomeIcon} alt="" className={styles.welcomeIcon} />

        <h2 className={styles.title}>{t('onboarding.userDetails.welcome')}</h2>
        <p className={styles.description}>{t('onboarding.userDetails.description')}</p>
      </div>

      <div className={styles.inputsContainer}>
        <div className={styles.inputGroup}>
          <span className={styles.inputLabel}>
            {t('onboarding.userDetails.fullName')}
          </span>
          <input
            className={styles.inputField}
            type="text"
            placeholder="John Doe"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        {/* <div className={styles.inputGroup}>
          <span className={styles.inputLabel}>{t('onboarding.userDetails.mail')}</span>
          <input
            className={styles.inputField}
            type="email"
            placeholder="hello@nswr.ai"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {email.length >= 1 && !isValidEmail(email) && (
            <span className={styles.errorText}>
              {t('onboarding.userDetails.errorMail')}
            </span>
          )}
        </div> */}
      </div>

      <div className={styles.checkboxContainer}>
        <div
          className={`${styles.checkbox} ${checked ? styles.checkboxSelected : ''}`}
          onClick={() => setChecked(!checked)}
        >
          {checked && <img alt="" src={checkIcon} />}
        </div>
        <p className={styles.description}>
          {t('onboarding.userDetails.acceptTerms.1')}

          <a
            className={styles.link}
            href="https://nswr.ai/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('onboarding.userDetails.acceptTerms.2')}
          </a>
        </p>
      </div>

      <button
        onClick={handleConfirm}
        className={styles.confirmButton}
        disabled={!name || !email || !checked}
      >
        {t('onboarding.userDetails.next')}
        {name && email ? (
          !isLoading ? (
            <img src={arrowRight} alt="" className={styles.arrowIcon} />
          ) : (
            <Lottie
              className={styles.arrowIcon}
              animationData={loaderAnimation}
              loop={true}
              style={{ width: 24, height: 24 }}
            />
          )
        ) : (
          <img src={arrowRightDisabled} alt="" className={styles.arrowIcon} />
        )}
      </button>
    </div>
  );
};

export default UserDetails;
