import { useAxiosInstance } from '@axios/useAxiosInstance';
import { QueryFunction, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { Comment } from './usePosts';

const useStatsElon = (
  options?: Omit<
    UseQueryOptions<Comment[], Error, Comment[]>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  },
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const fetchStatsElon: QueryFunction<Comment[]> = async () => {
    const instance = await getAxiosInstance();

    return instance.get(`/api/assistant/stats-elon`).then((response) => response.data);
  };

  return useQuery<Comment[], Error, Comment[]>(['stats-elon'], fetchStatsElon, {
    ...options,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
    onSuccess:
      options?.onSuccess ||
      ((data) => {
        if (import.meta.env.VITE_PRINT_SUCCESSFUL_ENDPOINTS === 'true') {
          console.log('Stats Elon fetched successfully!', data);
        }
      }),
    onError:
      options?.onError ||
      ((error) => {
        console.error('Error fetching Stats Elon:', error);
      }),
    enabled: options?.enabled && authenticated,
  });
};

export { useStatsElon };
