import { useUser } from '@axios/queries';
import { useAxiosInstance } from '@axios/useAxiosInstance';
import { MutationFunction, useMutation, UseMutationOptions } from '@tanstack/react-query';

type UpdateUserInput = {
  user_id: string;
  name?: string;
  email?: string;
  channels?: string[];
  onboarding_done?: boolean;
  setting_tasks?: {
    tasks_per_day: number;
    channels: string[];
    autoHide: boolean;
    autoLike: boolean;
    onlyAds: boolean;
    adsAccounts: string[];
  };
  language?: 'fr' | 'en';
};

type User = {
  _id: string;
  user_id: string;
  is_admin: boolean;
  name: string;
  email: string;
  onboarding_done: boolean;
  channels: string[];
  fine_tune_model?: string;
  fine_tune_job?: string;
  elon_instructions: string[];
  last_update?: string;
  created_date?: string;
  language: 'fr' | 'en';
};

const useUpdateUser = (
  user_id: string,
  options?: Omit<UseMutationOptions<User, Error, UpdateUserInput>, 'mutationFn'>,
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();
  const { data: userData, isLoading: isLoadingUser } = useUser(user_id);

  const updateUser: MutationFunction<User, UpdateUserInput> = async (
    input: UpdateUserInput,
  ) => {
    if (!authenticated || (!userData && !isLoadingUser)) {
      return;
    }

    const correctedData = {
      update: input,
      filter: {
        user_id: input.user_id,
      },
    };
    const instance = await getAxiosInstance();

    return instance
      .post('/api/users/createOrUpdate', correctedData)
      .then((response) => response.data);
  };

  return useMutation<User, Error, UpdateUserInput>(updateUser, {
    ...options,
    onSuccess:
      options?.onSuccess ||
      ((data) => {
        if (import.meta.env.VITE_PRINT_SUCCESSFUL_ENDPOINTS === 'true') {
          console.log('User successfully updated!', data);
        }
      }),
    onError:
      options?.onError ||
      ((input, error) => {
        console.error('Error updating user:', input, error);
      }),
  });
};

export { useUpdateUser };
