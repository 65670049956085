import { AiReply, History, Message } from '@axios/queries';
import { useAxiosInstance } from '@axios/useAxiosInstance';
import { MutationFunction, useMutation, UseMutationOptions } from '@tanstack/react-query';

// Define the input type for creating a subscription
type UpdateReplyInput = {
  aiReplyId: string;
  userMessage: string;
  comment_id: string;
  messages: Message[];
};

// Update the return type for the subscription according to your new endpoint
type UpdateReplyOutput = {
  aiReply: AiReply;
  updatedReply: string;
  message: string;
  action: 'instruction' | 'discussion';
  language: 'string';
  instruction: string;
};

const useUpdateReply = (
  options?: Omit<
    UseMutationOptions<UpdateReplyOutput, Error, UpdateReplyInput>,
    'mutationFn'
  >,
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  // Mutation function to create a subscription
  const updateReply: MutationFunction<UpdateReplyOutput, UpdateReplyInput> = async (
    input: UpdateReplyInput,
  ) => {
    console.log('INPUT:', input);
    if (!authenticated) {
      throw new Error('User must be authenticated to update message');
    }

    const instance = await getAxiosInstance();
    return instance
      .post('/api/assistant/update-reply', input)
      .then((response) => response.data);
  };

  // Use the mutation with React Query
  return useMutation<UpdateReplyOutput, Error, UpdateReplyInput>(updateReply, {
    ...options,
    onSuccess:
      options?.onSuccess ||
      ((data) => {
        if (import.meta.env.VITE_PRINT_SUCCESSFUL_ENDPOINTS === 'true') {
          console.log('Update message successfully created!', data);
        }
      }),
    onError:
      options?.onError ||
      ((error, input) => {
        console.error('Error creating update message:', input, error);
      }),
  });
};

export { useUpdateReply };
export type { UpdateReplyInput, UpdateReplyOutput };
