import { useAxiosInstance } from '@axios/useAxiosInstance';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

const useDeleteInstruction = (
  options?: UseMutationOptions<
    Comment[], // Assuming the response type
    Error, // Error type
    { instruction: string }, // Variables type
    unknown // Context type (optional)
  >,
) => {
  const { getAxiosInstance } = useAxiosInstance();

  const deleteInstruction = async ({ instruction }: { instruction: string }) => {
    // Encoding: Ensure instruction is URL-encoded
    const encodedInstruction = encodeURIComponent(instruction);

    const instance = await getAxiosInstance();
    return instance
      .post(`/api/assistant/delete-instruction?instruction=${encodedInstruction}`)
      .then((response) => response.data);
  };

  return useMutation(deleteInstruction, options);
};

export { useDeleteInstruction };
