import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import english from './en.json';
import french from './fr.json';

const resources = {
  en: { translation: english },
  fr: { translation: french },
};

void i18next
  .use(initReactI18next)
  .use(LanguageDetector) // Use the LanguageDetector
  .init({
    resources,
    lng: navigator.language, // Use browser language setting initially
    fallbackLng: 'en', // Fallback to English if the language is not supported
    interpolation: { escapeValue: false },
    load: 'languageOnly', // This will ignore region codes and load 'fr' for 'fr-CA', 'fr-FR', etc.
    detection: {
      order: [
        'localStorage',
        'cookie',
        'navigator', // Priority to browser setting
        'querystring',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['localStorage', 'cookie'], // Store user's choice in localStorage and cookie
      excludeCacheFor: ['cimode'], // languages to not persist
    },
  });

// Function to change language
export const changeLanguage = async (language: string) => {
  console.log('LANGUAGE CHANGE TO:', language);
  await i18next.changeLanguage(language);
  localStorage.setItem('i18nextLng', language); // Manually setting the language in localStorage
};

export default i18next;
