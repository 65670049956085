import { useAxiosInstance } from '@axios/useAxiosInstance';
import { QueryFunction, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { Comment } from './usePosts';

export type CommentStatistics = {
  currentPeriodStart: Date;
  previousPeriodStart: Date;
  currentPeriodEnd: Date;
  numberOfComments: number;
  previousPeriodNumberOfComments: number;
  percentageChange: number;
  dailyComments: { [key: string]: number }; // Key: Date string, Value: Number of comments
};

const useStatsCommentsElon = (
  params: {
    frameTimeDays?: number;
  },
  options?: Omit<
    UseQueryOptions<Comment[], Error, Comment[], [string, number | undefined]>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  },
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const fetchStatsCommentsElon: QueryFunction<
    Comment[],
    [string, number | undefined]
  > = async ({ queryKey }) => {
    const [, frameTimeDays] = queryKey;
    const instance = await getAxiosInstance();

    return instance
      .get(
        `/api/assistant/stats-comments-elon?${
          frameTimeDays !== undefined ? `frameTimeDays=${frameTimeDays}` : ''
        }`,
      )
      .then((response) => response.data);
  };

  return useQuery<Comment[], Error, Comment[], [string, number | undefined]>(
    ['stats-comments-elon', params.frameTimeDays],
    fetchStatsCommentsElon,
    {
      ...options,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
      onSuccess:
        options?.onSuccess ||
        ((data) => {
          if (import.meta.env.VITE_PRINT_SUCCESSFUL_ENDPOINTS === 'true') {
            console.log('Stats Elon fetched successfully!', data);
          }
        }),
      onError:
        options?.onError ||
        ((error) => {
          console.error('Error fetching Stats Elon:', error);
        }),
      enabled: options?.enabled && authenticated,
    },
  );
};

export { useStatsCommentsElon };
