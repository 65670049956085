import { useTranslation } from 'react-i18next';

export const PLANS = [
  {
    id: 0,
    name: 'planConfig.starter.name',
    description: 'planConfig.starter.description',
    features: [
      { name: 'planConfig.features.commentsPerMonth', count: 200 },
      // { name: 'planConfig.features.videoContentDecryption', count: 30 },
      { name: 'planConfig.features.includedNetworks', isActive: true },
      { name: 'planConfig.features.aiTrained', isActive: true },
      // {
      //   name: 'planConfig.features.customCommentManagement',
      //   isActive: false,
      // },
      { name: 'planConfig.features.autoLike', isActive: false },
      { name: 'planConfig.features.autoHide', isActive: false },
      { name: 'planConfig.features.commentSelection', isActive: false },
      { name: 'planConfig.features.adsCommentAccess', isActive: false },
    ],
    monthlyPrice: 29.99,
    yearlyPrice: 22.99,
    isPopular: false,
  },
  {
    id: 1,
    name: 'planConfig.contentCreator.name',
    description: 'planConfig.contentCreator.description',
    features: [
      { name: 'planConfig.features.commentsPerMonth', count: 500 },
      // { name: 'planConfig.features.videoContentDecryption', count: 60 },
      { name: 'planConfig.features.includedNetworks', isActive: true },
      { name: 'planConfig.features.aiTrained', isActive: true },
      // {
      //   name: 'planConfig.features.customCommentManagement',
      //   isActive: true,
      // },
      { name: 'planConfig.features.autoLike', isActive: true },
      { name: 'planConfig.features.autoHide', isActive: false },
      { name: 'planConfig.features.commentSelection', isActive: false },
      { name: 'planConfig.features.adsCommentAccess', isActive: false },
    ],
    monthlyPrice: 59.99,
    yearlyPrice: 44.99,
    isPopular: true,
  },
  {
    id: 2,
    name: 'planConfig.business.name',
    description: 'planConfig.business.description',
    features: [
      { name: 'planConfig.features.commentsPerMonth', count: 1000 },
      // { name: 'planConfig.features.videoContentDecryption', count: 120 },
      { name: 'planConfig.features.includedNetworks', isActive: true },
      { name: 'planConfig.features.aiTrained', isActive: true },
      // {
      //   name: 'planConfig.features.customCommentManagement',
      //   isActive: true,
      // },
      { name: 'planConfig.features.autoLike', isActive: true },
      { name: 'planConfig.features.autoHide', isActive: true },
      { name: 'planConfig.features.commentSelection', isActive: true },
      { name: 'planConfig.features.adsCommentAccess', isActive: true },
    ],
    monthlyPrice: 199.99,
    yearlyPrice: 149.99,
    isPopular: false,
  },
];

export const PAYMENT_LINKS = {
  0: {
    monthly: 'https://buy.stripe.com/5kA9Ea0OQdc68yQaEJ',
    yearly: 'https://buy.stripe.com/28o03A2WY5JEcP6cMQ',
  },
  1: {
    monthly: 'https://buy.stripe.com/5kAeYu7de2xs02kbIL',
    yearly: 'https://buy.stripe.com/cN28A6cxyfke02k9AC',
  },
  2: {
    monthly: 'https://buy.stripe.com/8wM8A6fJK9ZUaGY000',
    yearly: 'https://buy.stripe.com/5kA17Ecxy5JEcP6cMS',
  },
};
