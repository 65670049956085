// PopupComponent.tsx
import React from 'react';

import styles from './PopupComponent.module.scss'; // Create a corresponding CSS module

type PopupComponentProps = {
  title: string;
  description?: string | JSX.Element;
  buttonText?: string;
  icon?: string;
  onConfirm?: (any?) => void;
  onCancelButtonText?: string;
  onCancel?: () => void; // Optional second button handle
  classname?: any;
  widthIcon?: any;
};

const PopupComponent = ({
  title,
  description,
  buttonText,
  icon,
  onConfirm,
  onCancelButtonText,
  onCancel,
  classname,
  widthIcon,
}: PopupComponentProps) => {
  return (
    <div className={styles.root}>
      <div className={`${styles.popupContainer} ${classname}`}>
        <div className={styles.titleContainer}>
          {icon && (
            <img
              src={icon}
              alt=""
              className={styles.customIcon}
              width={widthIcon || '100px'}
            />
          )}

          <h2 className={styles.title}>{title}</h2>

          {description && <p className={styles.description}>{description}</p>}
        </div>

        <div className={styles.buttonContainer}>
          {/* Conditionally render the second button if its text is provided */}
          {onCancelButtonText && onCancel && (
            <button onClick={onCancel} className={styles.buttonCancel}>
              {onCancelButtonText}
            </button>
          )}

          {buttonText && onConfirm && (
            <button onClick={onConfirm} className={styles.button}>
              {buttonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupComponent;
