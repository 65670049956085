import { useAxiosInstance } from '@axios/useAxiosInstance';
import { MutationFunction, useMutation, UseMutationOptions } from '@tanstack/react-query';

type NewChannelInput = {
  channel_id: string;
  type: string;
};

type Channel = {
  _id: string;
  user_id: string;
  channel_id: string;
  context_summary: string;
  preferences: object;
  type: string;
  ads_accounts?: {
    ad_account_id: string;
    business_name?: string;
    name: string;
    promote_pages: Array<{
      name: string;
      id: string;
    }>;
  }[];
  username: string;
  name: string;
  website: string;
  profile_picture_url: string;
  details: object;
  likes_count: number;
  posts_count: number;
  followers_count: number;
  comments_count: number;
  replies_count: number;
  isConnected: boolean;
  last_update?: string;
  created_date?: string;
};

const useNewChannel = (
  options?: Omit<UseMutationOptions<Channel, Error, NewChannelInput>, 'mutationFn'>,
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const newChannel: MutationFunction<Channel, NewChannelInput> = async (
    input: NewChannelInput,
  ) => {
    if (!authenticated) {
      return;
    }

    const instance = await getAxiosInstance();

    return instance
      .post('/api/channels/createOrUpdate', input)
      .then((response) => response.data);
  };

  return useMutation<Channel, Error, NewChannelInput>(newChannel, {
    ...options,
    onSuccess:
      options?.onSuccess ||
      ((data) => {
        if (import.meta.env.VITE_PRINT_SUCCESSFUL_ENDPOINTS === 'true') {
          console.log('Channel successfully added!', data);
        }
      }),
    onError:
      options?.onError ||
      ((input, error) => {
        console.error('Error added Channel:', input, error);
      }),
  });
};

export { useNewChannel };
export type { Channel };
