/* eslint-disable react/no-unstable-nested-components */
import 'react-loading-skeleton/dist/skeleton.css';

import React from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './Posts.module.scss';

const PostsSkeleton = () => {
  return (
    <div className={styles.root}>
      <div className={styles.leftPanel}>
        <Skeleton
          baseColor={'#EBF1FF'}
          highlightColor={'#F9FAFB'}
          duration={1.2}
          height={75}
          width={300}
        />

        <div className={styles.commentsListContainer}>
          <Skeleton
            baseColor={'#EBF1FF'}
            highlightColor={'#F9FAFB'}
            duration={1.2}
            height={42}
            width={'100%'}
          />

          <div style={{ marginTop: '12px' }}>
            <Skeleton
              baseColor={'#EBF1FF'}
              highlightColor={'#F9FAFB'}
              duration={1.2}
              height={65}
              width={'100%'}
              count={15}
              style={{ marginTop: '8px' }}
            />
          </div>
        </div>
      </div>

      <div className={styles.rightPanel}>
        <div className={styles.repliesContainer}>
          <Skeleton
            baseColor={'#EBF1FF'}
            highlightColor={'#F9FAFB'}
            duration={1.2}
            height={120}
          />

          <div style={{ marginTop: '7px' }}>
            <Skeleton
              baseColor={'#EBF1FF'}
              highlightColor={'#F9FAFB'}
              duration={1.2}
              height={80}
              count={4}
              style={{ marginTop: '8px' }}
            />
          </div>
        </div>

        <div className={styles.elonContainer}>
          <Skeleton
            baseColor={'#EBF1FF'}
            highlightColor={'#F9FAFB'}
            duration={1.2}
            height={115}
          />
        </div>
      </div>
    </div>
  );
};

export { PostsSkeleton };
