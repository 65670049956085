import Nango from '@nangohq/frontend';

const useNango = () => {
  const nango = new Nango({
    publicKey: import.meta.env.VITE_NANGO_PUBLIC_KEY,
  });

  const connectWithNango = async (integrationKey: string, user_id?: string) => {
    if (!user_id) {
      return;
    }

    await nango
      .auth(integrationKey, user_id)
      .then((result) => {
        if (import.meta.env.VITE_PRINT_SUCCESSFUL_ENDPOINTS === 'true') {
          console.log(
            `OAuth flow succeeded for provider "${result.providerConfigKey}" and connection-id!`,
          );
        }
      })
      .catch((error: any) => {
        console.error(
          `There was an error in the OAuth flow for integration: ${error.message}`,
        );
        throw error;
      });
  };

  return { connectWithNango };
};

export { useNango };
