import { useAxiosInstance } from '@axios/useAxiosInstance';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

// Assuming the response type of your endpoint
type SyncStatusResponse = {
  isSyncRequired: boolean;
  lastSyncDate: string; // or Date, depending on how you handle dates
  status: 'syncing' | 'complete';
};

const useCheckSync = (
  params: {
    channel_id: string;
    post_id?: string;
    user_id?: string;
    page?: number;
  },
  options?: Omit<UseQueryOptions<SyncStatusResponse, Error>, 'queryKey' | 'queryFn'>,
) => {
  const { getAxiosInstance } = useAxiosInstance();

  const fetchSyncStatus = async (): Promise<SyncStatusResponse> => {
    const { channel_id, post_id, user_id, page } = params;
    const instance = await getAxiosInstance();

    const response = await instance.get('/api/sync-data/check-sync-status', {
      params: { channel_id, post_id, user_id, page },
    });

    return response.data;
  };

  return useQuery<SyncStatusResponse, Error>(
    ['checkSyncStatus', params.channel_id, params.post_id, params.user_id, params.page],
    fetchSyncStatus,
    {
      ...options,
      // You might want to set staleTime and refetchInterval dynamically based on the use case
      staleTime: 0, // Immediately considered stale
      refetchInterval: 10000, // Poll every 10 seconds
      refetchOnWindowFocus: false,
    },
  );
};

export { useCheckSync };
export type { SyncStatusResponse };
