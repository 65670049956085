import { useAuth0 } from '@auth0/auth0-react';
import {
  useChannels,
  useStripeData,
  useStripeSubscriptionStatus,
  useUser,
} from '@axios/queries';
import { AppWrapper } from '@components';
import ErrorPage from '@components/error-page/ErrorPage';
import { Elon } from '@containers';
import { NoAccessComponent } from '@containers/no-access/NoAccessComponent';
import { useEffect, useMemo } from 'react';

const ElonRoute = () => {
  const {
    isAuthenticated,
    isLoading: isLoadingAuth,
    loginWithRedirect,
    user,
  } = useAuth0();

  // const { data: stripeStatus, isLoading: isLoadingStripeStatus } =
  //   useStripeSubscriptionStatus();

  const { data: stripeData, isLoading: isLoadingStripeData } = useStripeData();

  const { data: channels, isLoading: isLoadingChannels } = useChannels(
    {
      user_id: user?.sub,
    },
    { enabled: !!stripeData?.hasAccess },
  );

  const { data: userData, isLoading: isLoadingUser } = useUser(
    {
      user_id: user?.sub,
    },
    { enabled: !!stripeData?.hasAccess },
  );

  const isLoading = useMemo<boolean>(
    () => isLoadingChannels || isLoadingUser || isLoadingStripeData,
    [isLoadingChannels, isLoadingUser, isLoadingStripeData],
  );

  if (userData?.subscription_plan == 0) {
    return (
      <AppWrapper
        activePath="/elon"
        isLoading={isLoading}
        isLoadingAuth={isLoadingAuth}
        isAuthenticated={isAuthenticated}
        loginWithRedirect={loginWithRedirect}
        user={user}
      >
        <NoAccessComponent stripeData={stripeData} />
      </AppWrapper>
    );
  }

  return (
    <AppWrapper
      activePath="/elon"
      isLoading={isLoading}
      isLoadingAuth={isLoadingAuth}
      isAuthenticated={isAuthenticated}
      loginWithRedirect={loginWithRedirect}
      user={user}
    >
      <Elon channels={channels} user={userData} />
    </AppWrapper>
  );
};

export default ElonRoute;
export const Catch = () => {
  return <ErrorPage />;
};
