import { useAxiosInstance } from '@axios/useAxiosInstance';
import { MutationFunction, useMutation, UseMutationOptions } from '@tanstack/react-query';

// Define the input type for creating a subscription
type CreateCheckoutSessionInput = {
  planId: number;
  billingCycle: 'monthly' | 'yearly';
  user_id: string;
  referral_id: string;
};

// Update the return type for the subscription according to your new endpoint
type CreateCheckoutSessionOutput = {
  session_url: string;
};

const useCreateCheckoutSession = (
  options?: Omit<
    UseMutationOptions<CreateCheckoutSessionOutput, Error, CreateCheckoutSessionInput>,
    'mutationFn'
  >,
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  // Mutation function to create a subscription
  const createCheckoutSession: MutationFunction<
    CreateCheckoutSessionOutput,
    CreateCheckoutSessionInput
  > = async (input: CreateCheckoutSessionInput) => {
    if (!authenticated) {
      throw new Error('User must be authenticated to create a subscription');
    }

    const instance = await getAxiosInstance();
    return instance
      .post('/api/stripe/create-checkout-session', input)
      .then((response) => response.data);
  };

  // Use the mutation with React Query
  return useMutation<CreateCheckoutSessionOutput, Error, CreateCheckoutSessionInput>(
    createCheckoutSession,
    {
      ...options,
      onSuccess:
        options?.onSuccess ||
        ((data) => {
          console.log(data);
          if (import.meta.env.VITE_PRINT_SUCCESSFUL_ENDPOINTS === 'true') {
            console.log('CheckoutSession successfully created!', data);
          }
        }),
      onError:
        options?.onError ||
        ((error, input) => {
          console.error('Error creating checkout session:', input, error);
        }),
    },
  );
};

export { useCreateCheckoutSession };
export type { CreateCheckoutSessionInput, CreateCheckoutSessionOutput };
