import { useAxiosInstance } from '@axios/useAxiosInstance';
import { QueryFunction, useQuery, UseQueryOptions } from '@tanstack/react-query';

type StripeData = {
  subscription_id: string;
  renewal_date: string;
  customer_portal: string;
  status: string;
  trial_end: Date;
  cancel_at: Date;
  hasAccess: boolean;
  stripe_customer_id: string;
};

const useStripeData = (
  options?: Omit<
    UseQueryOptions<StripeData, Error, StripeData, [string, string]>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  },
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const fetchStripeData: QueryFunction<StripeData, [string, string]> = async () => {
    const instance = await getAxiosInstance();

    return instance
      .get(
        `/api/stripe/stripe-user-information?${
          window?.Rewardful?.referral && 'referral_id=' + window?.Rewardful?.referral
        }`,
      )
      .then((response) => response.data);
  };

  const queryKey: [string, string] = ['stripeData', ''];

  return useQuery<StripeData, Error, StripeData, [string, string]>(
    queryKey,
    fetchStripeData,
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      ...options,
      onSuccess:
        options?.onSuccess ||
        ((data) => {
          if (import.meta.env.VITE_PRINT_SUCCESSFUL_ENDPOINTS === 'true') {
            console.log('StripeData data fetched successfully!', data);
          }
        }),
      onError:
        options?.onError ||
        ((error) => {
          console.error('Error fetching StripeData:', error);
        }),

      enabled: options?.enabled && authenticated,
    },
  );
};

export type { StripeData };
export { useStripeData };
