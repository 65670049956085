import { Channel } from '@axios/mutations';
import { useAxiosInstance } from '@axios/useAxiosInstance';
import { QueryFunction, useQuery, UseQueryOptions } from '@tanstack/react-query';

const useChannels = (
  filter?: Partial<Channel>,
  options?: Omit<
    UseQueryOptions<Channel[], Error, Channel[], [string, Partial<Channel>]>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  },
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const fetchChannel: QueryFunction<Channel[], [string, Partial<Channel>]> = async () => {
    const instance = await getAxiosInstance();

    return instance
      .get('/api/channels/findAllUpdated', { params: filter })
      .then((response) => response.data);
  };

  return useQuery<Channel[], Error, Channel[], [string, Partial<Channel>]>(
    ['channels', filter],
    fetchChannel,
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options,
      onSuccess:
        options?.onSuccess ||
        ((data) => {
          if (import.meta.env.VITE_PRINT_SUCCESSFUL_ENDPOINTS === 'true') {
            console.log('Channels data fetched successfully!', data);
          }
        }),
      onError:
        options?.onError ||
        ((error) => {
          console.error('Error fetching Channels data:', error);
        }),
      enabled: options?.enabled && authenticated,
    },
  );
};

export type { Channel };
export { useChannels };
