import ErrorPage from '@components/error-page/ErrorPage';
import { useTranslation } from 'react-i18next';

const index = () => {
  const { t } = useTranslation();

  return (
    <ErrorPage
      errorCode="404"
      title={t('error.404.title')}
      message={t('error.404.message')}
    />
  );
};

export default index;
export const Catch = () => {
  return <ErrorPage />;
};
