/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import '@mantine/charts/styles.css';
import '@mantine/core/styles.css';

import { Comment, Reply, User } from '@axios/queries';
import { CommentComponent } from '@components/comment/CommentComponent';
import ImageLoader from '@components/image-loader/ImageLoader';
import BadgeElon from '@images/BadgeElon.svg';
import CommentIcon from '@images/comment-icon.svg';
import ElonIcon from '@images/elon_icon.svg';
import { BarChart, LineChart } from '@mantine/charts';
import { capitalize } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CommentByElon } from './comment-by-elon/CommentByElon';
import styles from './Dashboard.module.scss';
import TaskWidget from './task-widget/TaskWidget';

type DashboardProps = {
  user: User;
  tasks: Comment[];
  lastComments: Comment[];
  commentsByElon: Comment[];
  statsElon: any;
  statsCommentsElon: any;
  topUsers: any;
};

const Dashboard = ({
  user,
  tasks,
  lastComments,
  commentsByElon,
  statsElon,
  statsCommentsElon,
  topUsers,
}: DashboardProps) => {
  const { t } = useTranslation();

  const engagementAfterElon = Math.round(statsElon?.recentAverageEngagement);
  const percentageIncraseAfterElon = Math.round(statsElon?.overallPercentageIncrease);

  const navigate = useNavigate();

  const currentInstructions = user?.elon_instructions || [];

  function transformDailyComments(dailyComments: { [key: string]: number }) {
    if (!dailyComments) {
      return [];
    }
    return Object.entries(dailyComments).map(([date, commentaires]) => {
      // Format the date as needed, here it's assumed to be in 'MMM DD' format
      const formattedDate = new Date(date).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      });
      return { date: formattedDate, commentaires };
    });
  }

  // Function to extract all comment_ids
  const getAllCommentIds = (tasks: { [key: string]: any }): string[] => {
    if (!tasks) {
      return [];
    }

    return Object.values(tasks)
      .flat() // Flatten the array of Task arrays into a single array of Task
      .map((task) => task.comment_id); // Extract the comment_id from each Task
  };

  const dailyTasksIDs = getAllCommentIds(user.dailyTasks);

  const [filteredTasks, setFilteredTasks] = useState(() => {
    return tasks?.filter((t) => {
      if (!dailyTasksIDs?.includes(t.comment_id)) {
        return false;
      }

      if (t.skipped) {
        return false;
      }

      if (t.hidden?.status) {
        return false;
      }

      if (!t.aiReply) {
        return false;
      }

      if (t.reply) {
        return false;
      } else {
        const username = t.channel.username;
        const name = t.channel.name;

        const reply = (t.replies as Reply[]).find((r) => {
          return (
            r.username == username ||
            r.writtenByAI ||
            r.noReplyNeeded ||
            r.username?.includes(name)
          );
        });

        if (reply) {
          return false;
        }
      }
      return true;
    });
  });

  return (
    <div className={styles.root}>
      <div className={styles.headerContainer}>
        <div className={styles.title}>{t('Dashboard')}</div>
        {/* TODO <div className={styles.filter}>Filter</div> */}
      </div>

      <div className={styles.rowSmallContainer}>
        <div className={styles.smallWidgetContainer}>
          <div className={styles.titleContainer}>
            <p className={styles.graphTitle}>{t('dashboard.commentsTitle')}</p>
            <div className={styles.valueContainer}>
              <span className={styles.graphValue}>
                {statsCommentsElon.numberOfComments}
              </span>

              {statsCommentsElon.percentageChange > 0 && (
                <span
                  className={`${styles.graphBadge} ${
                    statsCommentsElon.percentageChange < 0 && styles.graphBadgeNeg
                  }`}
                >
                  {statsCommentsElon.percentageChange}%
                </span>
              )}
            </div>
          </div>

          <LineChart
            h={'100%'}
            w={'100%'}
            data={transformDailyComments(statsCommentsElon.dailyComments)}
            dataKey="date"
            series={[{ name: 'commentaires', color: '#375DFB' }]}
            curveType="bump"
            tickLine="none"
            gridAxis="y"
            withYAxis={false}
            withDots={false}
          />
        </div>

        {percentageIncraseAfterElon && percentageIncraseAfterElon > 0 ? (
          <div
            className={styles.smallWidgetContainer}
            style={{ position: 'relative', overflow: 'hidden' }}
          >
            <div style={{ zIndex: 1 }}>
              <div className={styles.titleContainer}>
                <p className={styles.graphTitle}>{t('dashboard.statsElonTitle')}</p>
                <div className={styles.valueContainer}>
                  <span className={styles.graphValue}>{engagementAfterElon}</span>
                  <span className={styles.graphBadge}>
                    +{percentageIncraseAfterElon}%
                  </span>
                </div>
              </div>
            </div>

            <img src={BadgeElon} alt="" className={styles.badgeElon} />
          </div>
        ) : null}

        <div className={styles.smallWidgetContainer}>
          <TaskWidget tasks={filteredTasks} />
        </div>
      </div>

      <div className={styles.rowContainer}>
        {/* <div className={styles.widgetContainer} style={{ width: '30%' }}>
          <div className={styles.title}>Commentaires récents</div>
          <div className={styles.commentsContainer}>
            {lastComments && lastComments.length > 0
              ? lastComments?.map((comment, index) => (
                  // TODO: Ref vers le bon com
                  <div
                    className={styles.item}
                    key={index}
                    onClick={() => navigate(`/posts/${comment.post_id}`)}
                  >
                    <CommentComponent
                      comment={comment}
                      channel={comment.channel}
                      post={comment.post}
                    />
                  </div>
                ))
              : null}
          </div>
        </div> */}

        <div className={styles.widgetContainer} style={{ width: '50%' }}>
          <div className={styles.title}>{t('dashboard.elonCommentsTitle')}</div>

          <div className={styles.elonContainer}>
            {commentsByElon && commentsByElon.length > 0
              ? commentsByElon.map((comment, index) => (
                  // TODO: Ref vers le bon com
                  <div
                    className={`${styles.item} ${styles.itemElon}`}
                    key={index}
                    onClick={() => navigate(`/posts/${comment.post_id}`)}
                  >
                    <CommentByElon
                      comment={comment}
                      channel={comment.channel}
                      post={comment.post}
                    />
                  </div>
                ))
              : null}
          </div>
        </div>

        <div className={styles.doubleWidget} style={{ width: '50%' }}>
          <div className={styles.widgetContainer}>
            <div className={styles.title}>{t('dashboard.topUsers.title')}</div>
            <div className={styles.topUsers}>
              {topUsers &&
                topUsers.length > 0 &&
                topUsers?.map((topUser, index) => (
                  <div className={styles.item} key={index}>
                    <div className={styles.container}>
                      <div className={styles.index}>{(index as number) + 1}</div>
                      <ImageLoader
                        width={40}
                        height={40}
                        borderRadius={'50%'}
                        src={
                          topUser.profilePictureUrl ??
                          `https://api.dicebear.com/7.x/micah/svg?seed=${topUser.username}&flip=true&radius=50&baseColor=ac6651,f9c9b6,transparent&eyeShadowColor=d2eff3,e0ddff,ffeba4,ffedef,ffffff,transparent&backgroundColor=ffdfbf,d1d4f9,c0aede,b6e3f4,ffd5dc`
                        }
                        src2={`https://api.dicebear.com/7.x/micah/svg?seed=${topUser.username}&flip=true&radius=50&baseColor=ac6651,f9c9b6,transparent&eyeShadowColor=d2eff3,e0ddff,ffeba4,ffedef,ffffff,transparent&backgroundColor=ffdfbf,d1d4f9,c0aede,b6e3f4,ffd5dc`}
                        alt=""
                        className={''}
                      />

                      <div className={styles.text}>
                        <div className={styles.name}>@{topUser.username}</div>
                        {
                          <div className={styles.description}>
                            {t('dashboard.topUsers.description.firstPart')}{' '}
                            {topUser.commentedPostsPercent}%{' '}
                            {t('dashboard.topUsers.description.secondPart')}
                          </div>
                        }
                      </div>
                    </div>

                    <div className={styles.stats}>
                      <img src={CommentIcon} alt={''} width={'24px'} />
                      {topUser.commentsCount}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {currentInstructions && currentInstructions.length > 0 && (
            <div className={styles.widgetContainer}>
              <div className={styles.title}>{t('dashboard.instructions.title')}</div>

              <div className={styles.instructionsElon}>
                {currentInstructions?.map((instruction, index) => (
                  <div
                    key={index}
                    className={styles.instructionContainer}
                    onClick={() => navigate('/elon')}
                  >
                    <img className={styles.ElonIcon} src={ElonIcon} alt="" />
                    {instruction}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { Dashboard };
