import { Reply } from '@axios/queries';
import { useAxiosInstance } from '@axios/useAxiosInstance';
import { MutationFunction, useMutation, UseMutationOptions } from '@tanstack/react-query';

type HideCommentInput = {
  channel_id: string;
  comment_id: string;
  hide: boolean;
};

type HideCommentOutput = {
  reply: Reply;
};

const useHideComment = (
  options?: Omit<
    UseMutationOptions<HideCommentOutput, Error, HideCommentInput>,
    'mutationFn'
  >,
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const hideComment: MutationFunction<HideCommentOutput, HideCommentInput> = async (
    input: HideCommentInput,
  ) => {
    if (!authenticated) {
      throw new Error('User must be authenticated to send reply');
    }

    const instance = await getAxiosInstance();

    return instance
      .post('api/assistant/hide-comment', input)
      .then((response) => response.data);
  };

  return useMutation<HideCommentOutput, Error, HideCommentInput>(hideComment, {
    ...options,
    onSuccess:
      options?.onSuccess ||
      ((data) => {
        console.log('Hidding comment successfully!', data);
      }),
    onError:
      options?.onError ||
      ((error, input) => {
        console.error('Error hidding comment:', error, input);
      }),
  });
};

export { useHideComment };
export type { HideCommentInput, HideCommentOutput };
