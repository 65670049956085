import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './ImageLoader.module.scss';

type ImageLoaderProps = {
  src: string;
  src2?: string;
  alt: string;
  className: any;
  width: any;
  height: any;
  borderRadius?: number | string;
  fallback?: JSX.Element; // Optional prop for custom fallback content
  retryDelay?: number; // Delay between retries in milliseconds
  maxRetries?: number; // Maximum number of retries
  loadingTimeout?: number; // Maximum duration to wait for image to load before retrying
};

const ImageLoader = ({
  src,
  src2,
  alt,
  className,
  width,
  height,
  borderRadius,
  fallback,
  retryDelay = 1000,
  maxRetries = 3,
  loadingTimeout = 1000, // Default timeout of 5 seconds
}: ImageLoaderProps) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [currentSrc, setCurrentSrc] = useState<string>(null);
  const [retryCount, setRetryCount] = useState(0);
  const loadingTimerRef = useRef<NodeJS.Timeout | null>(null);
  const imageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setCurrentSrc(src); // Start loading the image
          }
        });
      },
      { threshold: 0.01 },
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [src]);

  const handleLoad = () => {
    if (loadingTimerRef.current) {
      clearTimeout(loadingTimerRef.current);
    }
    setLoaded(true);
    setError(false);
  };

  const handleError = () => {
    if (retryCount < maxRetries && currentSrc !== src2) {
      setTimeout(() => {
        setCurrentSrc(src2); // Try secondary source if available
        setRetryCount(retryCount + 1);
      }, retryDelay);
    } else {
      // All retries exhausted or no secondary source available
      setLoaded(false);
      setError(true);

      console.log('SRC:', src);
      console.log('SRC 2:', src2);
    }
  };

  // useEffect(() => {
  //   setCurrentSrc(src); // Attempt to load the primary source initially
  //   setLoaded(false);
  //   setError(false);
  //   setRetryCount(0);

  //   // Start a timer to handle timeout error
  //   loadingTimerRef.current = setTimeout(() => {
  //     if (!loaded) {
  //       handleError();
  //     }
  //   }, loadingTimeout);

  //   return () => {
  //     // Cleanup timer on component unmount or source change
  //     if (loadingTimerRef.current) {
  //       clearTimeout(loadingTimerRef.current);
  //     }
  //   };
  // }, []);

  return (
    <div ref={imageRef} className={`${className ? className : ''} ${styles.image}`}>
      {!loaded && !error && (
        <Skeleton
          baseColor={'#EBF1FF'}
          highlightColor={'#F9FAFB'}
          duration={1.2}
          height={height}
          width={width}
          borderRadius={borderRadius}
        />
      )}
      {error ? (
        fallback || (
          <div
            className={className ? className : ''}
            style={{ width, height, borderRadius }}
          ></div>
        )
      ) : (
        <img
          src={currentSrc}
          alt={alt}
          className={`${className ? className : ''} ${styles.image}`}
          onLoad={handleLoad}
          onError={handleError}
          style={{
            width,
            height,
            borderRadius,
            display: loaded ? 'block' : 'none',
            flexShrink: 0,
          }}
        />
      )}
    </div>
  );
};

export default ImageLoader;
