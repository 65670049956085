// SettingsPopup.tsx

import '@mantine/core/styles.css';

import { Channel, useUpdateUser } from '@axios/mutations';
import { StripeData, User } from '@axios/queries';
import channelsData from '@components/onboarding/connect-channels/connectChannel.config';
import backButton from '@images/back_btn.svg';
import ChatIcon from '@images/chat_smile.svg';
import checkIcon from '@images/check_white.svg';
import elonIcon from '@images/elon_custom_icon.png';
import creditIcon from '@images/token_icon.svg';
import { MultiSelect, Select, Switch } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './SettingsPopup.module.scss'; // Ensure you have a corresponding CSS module

type SettingsPopupProps = {
  onClose: () => void;
  channels: Channel[];
  user: User;
  stripeData: StripeData;
  adsAccounts: { value: string; name: string }[];
};

const SettingsPopup = ({
  onClose,
  channels,
  user,
  stripeData,
  adsAccounts,
}: SettingsPopupProps) => {
  const { t } = useTranslation();
  const popupRef = useRef(null);

  const commentsLeft = user.commentLimitPerMonth - user.currentCommentCount;

  // Updated useUpdateUser hook with onSuccess callback
  const { mutateAsync: updateUser } = useUpdateUser(user.user_id);

  const alreadySelected = user?.setting_tasks?.channels;
  // State to track selected channels
  const [selectedChannels, setSelectedChannels] = useState(alreadySelected || []);
  const [onlyAds, setOnlyAds] = useState(user?.setting_tasks?.onlyAds ?? false);

  const alreadyAdsAccountsSelected = user?.setting_tasks?.adsAccounts;

  const [selectedAdsAccounts, setSelectedAdsAccounts] = useState(
    alreadyAdsAccountsSelected
      ? alreadyAdsAccountsSelected.every((element) => typeof element === 'string')
        ? alreadyAdsAccountsSelected
        : (alreadyAdsAccountsSelected as any)?.map((a) => a.value)
      : adsAccounts?.map((a) => a.value),
  );

  const [numberOfComments, setNumberOfComments] = useState(
    user?.setting_tasks?.tasks_per_day?.toString() || '',
  );
  const [autoHide, setAutoHide] = useState<boolean>(
    user?.setting_tasks?.autoHide ?? false,
  );

  const [autoLike, setAutoLike] = useState<boolean>(
    user?.setting_tasks?.autoLike ?? false,
  );

  // Handle selecting/deselecting channels
  const toggleChannelSelection = (channel) => {
    setSelectedChannels((currentSelection) =>
      currentSelection.includes(channel.channel_id)
        ? currentSelection.filter((ch) => ch !== channel.channel_id)
        : [...currentSelection, channel.channel_id],
    );
  };

  // Fonction pour ouvrir le portail client Stripe dans un nouvel onglet
  const handleManageSubscriptionClick = () => {
    window.open(stripeData.customer_portal, '_blank');
  };

  const commentOptions = [
    { value: '0', label: `0 ${t('comment')}` },
    { value: '10', label: `10 ${t('comments')}` },
    { value: '20', label: `20 ${t('comments')}` },
    { value: '50', label: `50 ${t('comments')}` },
    { value: '100', label: `100 ${t('comments')}` },
    // { value: '200', label: `200 ${t('comments')}` },
  ];

  // Function to handle the confirmation action
  const handleConfirm = async () => {
    console.log('Selected Channels:', selectedChannels);
    console.log('Number of Comments:', numberOfComments);
    console.log('Auto Hide:', autoHide);
    console.log('Auto Like:', autoLike);
    console.log('Only Ads:', onlyAds);
    console.log('Ads Accounts:', selectedAdsAccounts);

    await updateUser({
      user_id: user.user_id,
      setting_tasks: {
        tasks_per_day: Number(numberOfComments),
        channels: selectedChannels,
        autoHide: autoHide,
        autoLike: autoLike,
        onlyAds: onlyAds,
        adsAccounts: selectedAdsAccounts,
      },
    });

    user.setting_tasks.tasks_per_day = Number(numberOfComments);
    user.setting_tasks.channels = selectedChannels;
    user.setting_tasks.autoHide = autoHide;
    user.setting_tasks.autoLike = autoLike;
    user.setting_tasks.onlyAds = onlyAds;
    user.setting_tasks.adsAccounts = selectedAdsAccounts;

    onClose();
  };

  return (
    <div className={styles.root}>
      <div className={styles.popupContainer} ref={popupRef}>
        <button onClick={onClose} className={styles.returnButton}>
          <img src={backButton} alt="" />
        </button>

        <div className={styles.titleContainer}>
          <img src={elonIcon} alt="" className={styles.customIcon} />

          {/* <h2 className={styles.title}>{t('tasks.setting.title')}</h2> */}
        </div>

        <div className={styles.channelsContainer}>
          <p className={styles.titleChannel}>{t('tasks.setting.title')}</p>

          {channels.map((channel) => (
            <div
              key={channel.channel_id}
              className={`${styles.channel} ${
                selectedChannels.includes(channel.channel_id)
                  ? styles.channelSelected
                  : ''
              }`}
              onClick={() => toggleChannelSelection(channel)}
            >
              <img
                alt=""
                src={channelsData[channel.type].image}
                className={styles.channelIcon}
              />
              {channelsData[channel.type].name}

              <div
                className={`${styles.checkbox} ${
                  selectedChannels.includes(channel.channel_id)
                    ? styles.checkboxSelected
                    : ''
                }`}
              >
                {selectedChannels.includes(channel.channel_id) && (
                  <img alt="" src={checkIcon} />
                )}
              </div>
            </div>
          ))}
        </div>

        <div className={styles.commentsContainer}>
          {/* <p className={styles.text}>{t('tasks.setting.nbComments')}</p> */}

          <Select
            style={{ width: '100%' }}
            leftSection={<img src={ChatIcon} alt="" width={'20px'} />}
            label={t('tasks.setting.nbComments')}
            checkIconPosition="left"
            data={commentOptions}
            onChange={setNumberOfComments}
            placeholder={
              user?.setting_tasks?.tasks_per_day?.toString() || `50 ${t('comments')}`
            }
            defaultValue={
              user?.setting_tasks?.tasks_per_day?.toString() || `50 ${t('comments')}`
            }
            maxDropdownHeight={200}
            allowDeselect={false}
            styles={{
              input: {
                borderRadius: '10px',
                gap: '8px',
                width: '100%',
                backgroundColor: '#FFFFFF',
                border: '1px solid #E2E4E9',
                boxShadow: '0px 1px 2px 0px #E4E5E73D',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#0A0D14',
              },
              dropdown: {
                width: '100%', // Ensure the dropdown matches the width of the input
                borderRadius: '10px',
                backgroundColor: '#FFFFFF',
                border: '1px solid #E2E4E9',
                boxShadow: '0px 1px 2px 0px #E4E5E73D',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '24px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#0A0D14',
              },
              label: {
                marginBottom: '12px',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#0A0D14',
              },
              description: {
                lineHeight: '20px',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '14px',
                fontWeight: '400',
                letterSpacing: '-0.006em',
                textAlign: 'left',
              },
            }}
          />
        </div>

        <div className={styles.commentsContainer}>
          {user?.subscription_plan != 2 &&
            user?.stripe_subscription_status != 'trialing' && (
              <button
                onClick={handleManageSubscriptionClick}
                className={styles.buttonError}
              >
                {`${t('adsFilterTitleNoAccess')} `}
              </button>
            )}

          <Switch
            checked={onlyAds}
            disabled={
              user?.subscription_plan != 2 &&
              user?.stripe_subscription_status != 'trialing'
            }
            onChange={(event) => setOnlyAds(event.currentTarget.checked)}
            color="#155EEF"
            labelPosition="left"
            label={t('tasks.setting.onlyAds.title')}
            description={t('tasks.setting.onlyAds.description')}
            size="md"
            styles={{
              track: {
                flexShrink: 0,
                cursor: 'pointer',
              },
              body: {
                justifyContent: 'space-between',
                alignItems: 'center',
              },
              label: {
                flexShrink: '0',
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#0A0D14',
              },
              description: {
                lineHeight: '20px',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '14px',
                fontWeight: '400',
                letterSpacing: '-0.006em',
                textAlign: 'left',
              },
            }}
          />
        </div>

        <div className={styles.commentsContainer}>
          {user?.subscription_plan != 2 &&
            user?.stripe_subscription_status != 'trialing' && (
              <button
                onClick={handleManageSubscriptionClick}
                className={styles.buttonError}
              >
                {`${t('adsFilterTitleNoAccess')} `}
              </button>
            )}

          <MultiSelect
            disabled={
              user?.subscription_plan != 2 &&
              user?.stripe_subscription_status != 'trialing'
            }
            style={{ width: '100%' }}
            checkIconPosition="left"
            data={adsAccounts as any}
            label={t('selectAdsAccount')}
            description={t('tasks.setting.adTitle')}
            defaultValue={
              user?.subscription_plan != 2 &&
              user?.stripe_subscription_status != 'trialing'
                ? []
                : selectedAdsAccounts
            }
            onChange={(value) => setSelectedAdsAccounts(value)} // Correctly update state when selection changes
            maxDropdownHeight={200}
            styles={{
              label: {
                marginBottom: '12px',
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#0A0D14',
              },
              input: {
                borderRadius: '10px',
                gap: '8px',
                width: '100%',
                backgroundColor: '#FFFFFF',
                border: '1px solid #E2E4E9',
                boxShadow: '0px 1px 2px 0px #E4E5E73D',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#0A0D14',
              },
              dropdown: {
                width: '100%', // Ensure the dropdown matches the width of the input
                borderRadius: '10px',
                backgroundColor: '#FFFFFF',
                border: '1px solid #E2E4E9',
                boxShadow: '0px 1px 2px 0px #E4E5E73D',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '24px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#0A0D14',
              },
              description: {
                lineHeight: '20px',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '14px',
                fontWeight: '400',
                letterSpacing: '-0.006em',
                textAlign: 'left',
              },
            }}
          />
        </div>

        <div className={styles.commentsContainer}>
          {user?.subscription_plan < 2 &&
            user?.stripe_subscription_status != 'trialing' && (
              <button
                onClick={handleManageSubscriptionClick}
                className={styles.buttonError}
              >
                {`${t('adsFilterTitleNoAccess')} `}
              </button>
            )}

          <Switch
            checked={autoHide}
            disabled={
              user?.subscription_plan < 2 &&
              user?.stripe_subscription_status != 'trialing'
            }
            onChange={(event) => setAutoHide(event.currentTarget.checked)}
            color="#155EEF"
            labelPosition="left"
            label={t('tasks.setting.autoHide.title')}
            description={t('tasks.setting.autoHide.description')}
            size="md"
            styles={{
              track: {
                flexShrink: 0,
                cursor: 'pointer',
              },
              body: {
                justifyContent: 'space-between',
                alignItems: 'center',
              },
              label: {
                flexShrink: '0',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#0A0D14',
              },
              description: {
                lineHeight: '20px',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '14px',
                fontWeight: '400',
                letterSpacing: '-0.006em',
                textAlign: 'left',
              },
            }}
          />
        </div>

        <div className={styles.commentsContainer}>
          {user?.subscription_plan == 0 &&
            user?.stripe_subscription_status != 'trialing' && (
              <button
                onClick={handleManageSubscriptionClick}
                className={styles.buttonError}
              >
                {`${t('adsFilterTitleNoAccess')} `}
              </button>
            )}

          <Switch
            checked={autoLike}
            disabled={
              user?.subscription_plan == 0 &&
              user?.stripe_subscription_status != 'trialing'
            }
            onChange={(event) => setAutoLike(event.currentTarget.checked)}
            color="#155EEF"
            labelPosition="left"
            label={t('tasks.setting.autoLike.title')}
            description={t('tasks.setting.autoLike.description')}
            size="md"
            styles={{
              track: {
                flexShrink: 0,
                cursor: 'pointer',
              },
              body: {
                justifyContent: 'space-between',
                alignItems: 'center',
              },
              label: {
                flexShrink: '0',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#0A0D14',
              },
              description: {
                lineHeight: '20px',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                fontSize: '14px',
                fontWeight: '400',
                letterSpacing: '-0.006em',
                textAlign: 'left',
              },
            }}
          />
        </div>

        {/* 
        <button
          className={styles.buttonGenerateReply}
          onClick={() => handleGenerateNewTasks()}
        >
          {t('writeReply')} ✨
        </button> */}

        <p className={styles.description}>
          <i>{t('tasks.popup.information')}</i>
        </p>

        <div className={styles.tokensContainer}>
          <div className={styles.valueContainer}>
            <img alt="" src={creditIcon} width={'24px'} />

            <div className={styles.textContainer}>
              <p className={styles.title}>
                {commentsLeft > 1
                  ? t('tasks.popup.commentsLeft')
                  : t('tasks.popup.commentLeft')}
              </p>
              <p className={styles.value}>
                {commentsLeft} {commentsLeft > 1 ? t('comments') : t('comment')}
              </p>
            </div>
          </div>

          <div className={styles.planEdit} onClick={handleManageSubscriptionClick}>
            {t('setting.subscription.editPlan')}
          </div>
        </div>

        <button onClick={handleConfirm} className={styles.button}>
          {t('confirm')}
        </button>
      </div>
    </div>
  );
};

export default SettingsPopup;
