import { CommentComponent } from '@components/comment/CommentComponent';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './Dashboard.module.scss';

const DashboardSkeleton = () => {
  // Mock the skeleton data
  const sampleData = React.useMemo(
    () =>
      Array.from({ length: 20 }).map(() => ({
        // Adjust the length for the number of rows you want
        thumbnail_url: '',
        caption: '',
        timestamp: '',
        comments: [],
        likeCount: 0,
      })),
    [],
  );

  return (
    <div className={styles.root}>
      <div className={styles.headerContainer}>
        <Skeleton
          baseColor={'#EBF1FF'}
          highlightColor={'#F9FAFB'}
          duration={1.2}
          width={225}
          height={45}
        />
      </div>

      <div className={styles.rowSmallContainer}>
        <div className={styles.smallWidgetContainer}>
          <div className={styles.titleContainer}>
            <Skeleton
              baseColor={'#EBF1FF'}
              highlightColor={'#F9FAFB'}
              duration={1.2}
              width={190}
              height={20}
            />

            <div className={styles.valueContainer}>
              <Skeleton
                baseColor={'#EBF1FF'}
                highlightColor={'#F9FAFB'}
                duration={1.2}
                width={60}
                height={20}
              />
            </div>
          </div>

          <Skeleton
            baseColor={'#EBF1FF'}
            highlightColor={'#F9FAFB'}
            duration={1.2}
            width={340}
            height={75}
          />
        </div>

        <div className={styles.smallWidgetContainer}>
          <div style={{ zIndex: 1 }}>
            <div className={styles.titleContainer}>
              <Skeleton
                baseColor={'#EBF1FF'}
                highlightColor={'#F9FAFB'}
                duration={1.2}
                width={150}
                height={20}
              />

              <div className={styles.valueContainer}>
                <Skeleton
                  baseColor={'#EBF1FF'}
                  highlightColor={'#F9FAFB'}
                  duration={1.2}
                  width={60}
                  height={20}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.smallWidgetContainer}>
          <div className={styles.titleContainer}>
            <Skeleton
              baseColor={'#EBF1FF'}
              highlightColor={'#F9FAFB'}
              duration={1.2}
              width={190}
              height={20}
            />

            <div className={styles.valueContainer}>
              <Skeleton
                baseColor={'#EBF1FF'}
                highlightColor={'#F9FAFB'}
                duration={1.2}
                width={200}
                height={30}
              />
            </div>
          </div>

          <Skeleton
            baseColor={'#EBF1FF'}
            highlightColor={'#F9FAFB'}
            duration={1.2}
            width={340}
            height={60}
          />
        </div>
      </div>

      <div className={styles.rowContainer}>
        <div className={styles.widgetContainer} style={{ width: '30%' }}>
          <div className={styles.title}>
            <Skeleton
              baseColor={'#EBF1FF'}
              highlightColor={'#F9FAFB'}
              duration={1.2}
              width={190}
              height={20}
            />
          </div>

          <div className={styles.commentsContainer}>
            <Skeleton
              baseColor={'#EBF1FF'}
              highlightColor={'#F9FAFB'}
              duration={1.2}
              width={350}
              height={55}
              count={10}
            />
          </div>
        </div>

        <div className={styles.widgetContainer} style={{ width: '35%' }}>
          <div className={styles.title}>
            {' '}
            <Skeleton
              baseColor={'#EBF1FF'}
              highlightColor={'#F9FAFB'}
              duration={1.2}
              width={190}
              height={20}
            />
          </div>

          <div className={styles.elonContainer}>
            <Skeleton
              baseColor={'#EBF1FF'}
              highlightColor={'#F9FAFB'}
              duration={1.2}
              width={450}
              height={150}
              count={10}
            />
          </div>
        </div>

        <div className={styles.doubleWidget} style={{ width: '35%' }}>
          <div className={styles.widgetContainer}>
            <div className={styles.title}>
              <Skeleton
                baseColor={'#EBF1FF'}
                highlightColor={'#F9FAFB'}
                duration={1.2}
                width={190}
                height={20}
              />
            </div>
            <div className={styles.topUsers}>
              <Skeleton
                baseColor={'#EBF1FF'}
                highlightColor={'#F9FAFB'}
                duration={1.2}
                width={450}
                height={55}
                count={10}
              />
            </div>
          </div>

          <div className={styles.widgetContainer}>
            <div className={styles.title}>
              <Skeleton
                baseColor={'#EBF1FF'}
                highlightColor={'#F9FAFB'}
                duration={1.2}
                width={190}
                height={20}
              />
            </div>

            <div className={styles.instructionsElon}>
              <Skeleton
                baseColor={'#EBF1FF'}
                highlightColor={'#F9FAFB'}
                duration={1.2}
                width={450}
                height={70}
                count={10}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DashboardSkeleton };
