import { useAuth0 } from '@auth0/auth0-react';
import { useStripeData, useUser } from '@axios/queries';
import { AppWrapper } from '@components';
import ErrorPage from '@components/error-page/ErrorPage';
import { Setting } from '@containers';
import { SettingSkeleton } from '@containers/setting/SettingSkeleton';
import { useEffect, useMemo, useState } from 'react';

const SettingRoute = () => {
  const {
    isAuthenticated,
    isLoading: isLoadingAuth,
    loginWithRedirect,
    user,
  } = useAuth0();
  const [fetchData, setFetchData] = useState(true);

  const { data: userDetails, isLoading: isLoadingUser } = useUser({
    user_id: user?.sub,
  });

  const { data: stripeData, isLoading: isLoadingStripeData } = useStripeData();

  useEffect(() => {
    if (userDetails || isLoadingUser) {
      setFetchData(false);
    }
  }, [userDetails, isLoadingUser]);

  const isLoading = useMemo<boolean>(
    () => isLoadingUser || isLoadingStripeData,
    [isLoadingUser, isLoadingStripeData],
  );

  return (
    <AppWrapper
      activePath="/setting"
      isLoading={isLoading}
      isLoadingAuth={isLoadingAuth}
      isAuthenticated={isAuthenticated}
      loginWithRedirect={loginWithRedirect}
      user={user}
      loadingSkeleton={<SettingSkeleton />}
    >
      <Setting user={userDetails} stripeData={stripeData} />
    </AppWrapper>
  );
};

export default SettingRoute;
export const Catch = () => {
  return <ErrorPage />;
};
