import { useAuth0 } from '@auth0/auth0-react';
import {
  useChannels,
  useComments,
  useCommentsByElon,
  usePosts,
  useStatsCommentsElon,
  useStatsElon,
  useStripeSubscriptionStatus,
  useTasks,
  useTopUsers,
  useUser,
} from '@axios/queries';
import { AppWrapper } from '@components';
import ErrorPage from '@components/error-page/ErrorPage';
import { Dashboard } from '@containers';
import { DashboardSkeleton } from '@containers/dashboard/DashboardSkeleton';
import { useEffect, useMemo, useState } from 'react';

const DashboardRoute = () => {
  const {
    isAuthenticated,
    isLoading: isLoadingAuth,
    loginWithRedirect,
    user,
  } = useAuth0();

  const { data: stripeStatus, isLoading: isLoadingStripeStatus } =
    useStripeSubscriptionStatus();

  console.log('STRIPE STATUS:', stripeStatus);
  const { data, isLoading: isLoadingUser } = useUser(
    { user_id: user?.sub },
    {
      onSuccess: (userData) => {
        setUserData(userData);
      },
      enabled: !!user?.sub && !!stripeStatus?.hasAccess,
    },
  );

  const [userData, setUserData] = useState(data);

  const { data: initialTasks, isLoading: isLoadingTasks } = useTasks(
    {},
    {
      onSuccess: (newTasks) => {
        setTasks(newTasks?.filter((t) => !t.reply));
      },
      enabled:
        !!userData &&
        !!userData?.fine_tune_model &&
        !!userData?.onboarding_done &&
        !!(userData?.channels.length > 0) &&
        !!stripeStatus?.hasAccess,
    },
  );

  // const { data: initialComments, isLoading: isLoadingComments } = useComments(
  //   {},
  //   {
  //     onSuccess: (newComments) => {
  //       setComments(newComments);
  //     },
  //     enabled:
  //       !!userData &&
  //       !!userData?.fine_tune_model &&
  //       !!userData?.onboarding_done &&
  //       !!(userData?.channels.length > 0),
  //   },
  // );

  const { data: initialCommentsByElon, isLoading: isLoadingCommentsByElon } =
    useCommentsByElon(
      {},
      {
        onSuccess: (newComments) => {
          setCommentsByElon(newComments);
        },
        enabled:
          !!userData &&
          !!userData?.fine_tune_model &&
          !!userData?.onboarding_done &&
          !!(userData?.channels.length > 0) &&
          !!stripeStatus?.hasAccess,
      },
    );

  const { data: initialStatsCommentsElon, isLoading: isLoadingStatsCommentsElon } =
    useStatsCommentsElon(
      {},
      {
        onSuccess: (statsElon) => {
          setStatsCommentsElon(statsElon);
        },
        enabled:
          !!userData &&
          !!userData?.fine_tune_model &&
          !!userData?.onboarding_done &&
          !!(userData?.channels.length > 0) &&
          !!stripeStatus?.hasAccess,
      },
    );

  const { data: initialStatsElon, isLoading: isLoadingStatsElon } = useStatsElon({
    onSuccess: (statsElon) => {
      setStatsElon(statsElon);
    },
    enabled:
      !!userData &&
      !!userData?.fine_tune_model &&
      !!userData?.onboarding_done &&
      !!(userData?.channels.length > 0) &&
      !!stripeStatus?.hasAccess,
  });

  const { data: initialTopUsers, isLoading: isLoadingTopUsers } = useTopUsers({
    onSuccess: (topUsers) => {
      setTopUsers(topUsers);
    },
    enabled:
      !!userData &&
      !!userData?.fine_tune_model &&
      !!userData?.onboarding_done &&
      !!(userData?.channels.length > 0) &&
      !!stripeStatus?.hasAccess,
  });

  const [tasks, setTasks] = useState(initialTasks);
  // const [comments, setComments] = useState(initialComments);
  const [comments, setComments] = useState([]);

  const [commentsByElon, setCommentsByElon] = useState(initialCommentsByElon);
  const [statsElon, setStatsElon] = useState(initialStatsElon);
  const [topUsers, setTopUsers] = useState(initialTopUsers);
  const [statsCommentsElon, setStatsCommentsElon] = useState(initialStatsCommentsElon);

  const isLoading =
    isLoadingUser ||
    !user ||
    isLoadingTasks ||
    !tasks ||
    isLoadingAuth ||
    // isLoadingComments ||
    !comments ||
    isLoadingCommentsByElon ||
    !commentsByElon ||
    isLoadingStatsElon ||
    !statsElon ||
    isLoadingStatsCommentsElon ||
    !statsCommentsElon ||
    isLoadingTopUsers ||
    !topUsers ||
    isLoadingStripeStatus;

  return (
    <AppWrapper
      activePath="/dashboard"
      isLoading={isLoading}
      isLoadingAuth={isLoadingAuth}
      isAuthenticated={isAuthenticated}
      loginWithRedirect={loginWithRedirect}
      user={user}
      loadingSkeleton={<DashboardSkeleton />}
    >
      <Dashboard
        topUsers={topUsers}
        user={userData}
        tasks={tasks}
        lastComments={comments}
        commentsByElon={commentsByElon}
        statsElon={statsElon}
        statsCommentsElon={statsCommentsElon}
      />
    </AppWrapper>
  );
};

export default DashboardRoute;
export const Catch = () => {
  return <ErrorPage />;
};
