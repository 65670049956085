/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
import { Channel } from '@axios/mutations';
import { Comment, Post } from '@axios/queries';
import ImageLoader from '@components/image-loader/ImageLoader';
import facebookSVG from '@images/facebook.svg';
import instagramSVG from '@images/instagram.svg';
import tiktokSVG from '@images/tiktok.svg';
import { formatTimeToNowShort } from '@utils/formatTimeToNowShort';
import { useMemo } from 'react';

import styles from './CommentComponent.module.scss';

type CommentComponentProps = {
  channel: Channel;
  post: Post;
  comment: Comment;
};

const CommentComponent = ({ channel, post, comment }: CommentComponentProps) => {
  const CHANNEL_ICONS = {
    facebook: facebookSVG,
    instagram: instagramSVG,
    tiktok: tiktokSVG,
  };

  // Utilize the formatTimeToNow utility function for the timestamp
  const formattedTimestamp = useMemo(
    () => formatTimeToNowShort(comment?.timestamp),
    [comment?.timestamp],
  );

  return (
    <div className={styles.commentComponent}>
      <div className={styles.userInfo}>
        <div className={styles.accountIconContainer}>
          <ImageLoader
            key={comment._id}
            src2={`https://api.dicebear.com/7.x/micah/svg?seed=${
              comment.username ?? comment.comment_id
            }&flip=true&radius=50&baseColor=ac6651,f9c9b6,transparent&eyeShadowColor=d2eff3,e0ddff,ffeba4,ffedef,ffffff,transparent&backgroundColor=ffdfbf,d1d4f9,c0aede,b6e3f4,ffd5dc`}
            src={post?.thumbnail_url}
            alt={''}
            className={styles.postMedia}
            width={40}
            height={40}
            borderRadius={100}
          />
          {/* <img
            src={
              comment.profilePictureUrl ??
              `https://api.dicebear.com/7.x/micah/svg?seed=${comment.username}&flip=true&radius=50&baseColor=ac6651,f9c9b6,transparent&eyeShadowColor=d2eff3,e0ddff,ffeba4,ffedef,ffffff,transparent&backgroundColor=ffdfbf,d1d4f9,c0aede,b6e3f4,ffd5dc`
              // `https://api.dicebear.com/7.x/initials/svg?seed=${interaction.username}&radius=50&backgroundColor=00897b,00acc1,039be5,1e88e5,3949ab,43a047,5e35b1,7cb342,8e24aa,c0ca33,d81b60,e53935,f4511e,fb8c00,fdd835,ffb300,ffd5dc,ffdfbf&fontFamily=Arial&chars=1`
              // `https://api.dicebear.com/7.x/avataaars-neutral/svg?seed=${interaction.username}&radius=50&backgroundColor=d08b5b,edb98a,f8d25c,fd9841,ffdbb4,c0aede,b6e3f4,ffd5dc,ffdfbf,d1d4f9,ae5d29&eyebrows=default,defaultNatural,flatNatural,frownNatural,raisedExcited,raisedExcitedNatural,upDown,upDownNatural&eyes=closed,default,eyeRoll,happy,hearts,side,squint,surprised,wink,winkWacky&mouth=default,disbelief,eating,grimace,sad,screamOpen,serious,smile,tongue,twinkle,concerned`
            }
            alt=""
            className={styles.accountIcon}
          /> */}
          <img src={CHANNEL_ICONS[channel.type]} alt="" className={styles.channelIcon} />
        </div>

        <div className={styles.postCaption}>
          {comment.username && (
            <strong style={{ color: '#101828' }}>@{comment.username} :</strong>
          )}
          {comment.text}
        </div>
      </div>

      <div className={styles.postDetails}>
        <div className={styles.postTimestamp}>{formattedTimestamp}</div>
        {/* <ImageLoader
          src={post.thumbnail_url}
          alt={''}
          className={styles.postMedia}
          width={40}
          height={40}
        /> */}
        {/* <img src={post.thumbnail_url} alt={''} className={styles.postMedia} /> */}
      </div>
    </div>
  );
};

export { CommentComponent };
