/* eslint-disable react/jsx-key */
/* eslint-disable react/no-unstable-nested-components */
// ChannelsSkeleton.tsx
import 'react-loading-skeleton/dist/skeleton.css';

import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useTable } from 'react-table';

import styles from './Channels.module.scss';

const ChannelsSkeleton = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <Skeleton
            baseColor={'#EBF1FF'}
            highlightColor={'#F9FAFB'}
            duration={1.2}
            width={200}
            height={35}
          />
        ),
        accessor: 'thumbnail_url',
        Cell: () => (
          <div className={`${styles.cell} ${styles.postCell}`}>
            <Skeleton
              baseColor={'#EBF1FF'}
              highlightColor={'#F9FAFB'}
              duration={1.2}
              height={85}
              width={295}
            />
          </div>
        ),
      },
      {
        Header: () => (
          <Skeleton
            baseColor={'#EBF1FF'}
            highlightColor={'#F9FAFB'}
            duration={1.2}
            width={200}
            height={35}
          />
        ),
        accessor: 'timestamp',
        Cell: () => (
          <div className={`${styles.cell} ${styles.dateCell}`}>
            <Skeleton
              baseColor={'#EBF1FF'}
              highlightColor={'#F9FAFB'}
              duration={1.2}
              height={30}
              width={125}
            />
          </div>
        ),
      },
      {
        Header: () => (
          <Skeleton
            baseColor={'#EBF1FF'}
            highlightColor={'#F9FAFB'}
            duration={1.2}
            width={200}
            height={35}
          />
        ),
        accessor: 'likeCount',
        Cell: () => (
          <div className={`${styles.cell} ${styles.interactionCell}`}>
            <div className={styles.interaction}>
              <Skeleton
                baseColor={'#EBF1FF'}
                highlightColor={'#F9FAFB'}
                duration={1.2}
                height={30}
                width={175}
              />
            </div>
          </div>
        ),
      },
    ],
    [],
  );

  // Mock the skeleton data
  const skeletonData = React.useMemo(
    () =>
      Array.from({ length: 10 }).map(() => ({
        // Adjust the length for the number of rows you want
        thumbnail_url: '',
        caption: '',
        timestamp: '',
        comments: [],
        likeCount: 0,
      })),
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data: skeletonData,
  });

  return (
    <div className={styles.root}>
      <div className={styles.profileHeader}>
        <div className={styles.accountIconContainer}>
          <Skeleton
            baseColor={'#EBF1FF'}
            highlightColor={'#F9FAFB'}
            duration={1.2}
            circle={true}
            height={66}
            width={66}
          />
        </div>
        <div>
          <Skeleton
            baseColor={'#EBF1FF'}
            highlightColor={'#F9FAFB'}
            duration={1.2}
            width={250}
            height={40}
          />
          <Skeleton
            baseColor={'#EBF1FF'}
            highlightColor={'#F9FAFB'}
            duration={1.2}
            width={220}
            height={24}
            style={{ marginTop: '10px' }}
          />
        </div>
      </div>

      <hr
        className={styles.divider}
        style={{ marginBottom: '15px', marginTop: '50px' }}
      />

      <table {...getTableProps()} width={'100%'}>
        <thead style={{ marginBottom: '100px' }}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={styles.header}
                  style={{ height: '85px' }}
                >
                  <div className={styles.headerCell}>{column.render('Header')}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} className={styles.bodyCell}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export { ChannelsSkeleton };
