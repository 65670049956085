// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/channels/:channel_id`
  | `/dashboard`
  | `/elon`
  | `/onboarding`
  | `/posts/:post_id`
  | `/setting`
  | `/task`

export type Params = {
  '/channels/:channel_id': { channel_id: string }
  '/posts/:post_id': { post_id: string }
}

export type ModalPath = never

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
