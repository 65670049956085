import { useAxiosInstance } from '@axios/useAxiosInstance';
import { QueryFunction, useQuery, UseQueryOptions } from '@tanstack/react-query';

type User = {
  _id: string;
  user_id: string;
  is_admin: boolean;
  name: string;
  email: string;
  onboarding_done: boolean;
  channels: string[];
  fine_tune_model?: string;
  fine_tune_job?: string;
  stripe_subscription_id: string;
  subscription_plan: 0 | 1 | 2;
  stripe_subscription_status: string;
  elon_instructions: string[];
  commentLimitPerMonth: number;
  currentCommentCount: number;
  lastCommentCountReset: Date;
  nextQuotaReset: Date;
  setting_tasks: {
    tasks_per_day: number;
    channels: string[];
    autoHide: boolean;
    autoLike: boolean;
    onlyAds: boolean;
    adsAccounts: string[];
  };
  stripe_customer_id: string;
  referral_id: string;
  last_update?: string;
  created_date?: string;
  dailyTasks: { [key: string]: Task[] };
  language: 'fr' | 'en';
};

export type Task = {
  comment_id: string; // Assuming comment_id is a string; adjust if it's actually an ObjectId type
  status: 'pending' | 'completed';
};

const useUser = (
  input: string | Partial<User>,
  options?: Omit<
    UseQueryOptions<User, Error, User, [string, string]>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  },
) => {
  const { getAxiosInstance, authenticated } = useAxiosInstance();

  const fetchUser: QueryFunction<User, [string, string]> = async () => {
    const instance = await getAxiosInstance(false);

    let body = {};

    if (typeof input === 'string') {
      body = { user_id: input };
    } else if (input && typeof input === 'object') {
      body = input;
    }

    return instance
      .get('/api/users', {
        params: body,
      })
      .then((response) => response.data);
  };

  const userIdForQueryKey = typeof input === 'string' ? input : input?.user_id;
  const queryKey: [string, string] = ['user', userIdForQueryKey];

  return useQuery<User, Error, User, [string, string]>(queryKey, fetchUser, {
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
    onSuccess:
      options?.onSuccess ||
      ((data) => {
        if (import.meta.env.VITE_PRINT_SUCCESSFUL_ENDPOINTS === 'true') {
          console.log('User data fetched successfully!', data);
        }
      }),
    onError:
      options?.onError ||
      ((error) => {
        console.error('Error fetching user data:', error);
      }),

    enabled: options?.enabled && authenticated,
  });
};

export type { User };
export { useUser };
