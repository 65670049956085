import {
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
} from 'date-fns';

export const formatTimeToNowShort = (date) => {
  const now = new Date();
  const pastDate = new Date(date);
  const diffYears = differenceInYears(now, pastDate);
  const diffMonths = differenceInMonths(now, pastDate);
  const diffDays = differenceInCalendarDays(now, pastDate);
  const diffHours = differenceInHours(now, pastDate);
  const diffMinutes = differenceInMinutes(now, pastDate);
  const diffSeconds = differenceInSeconds(now, pastDate);

  if (diffYears >= 1) return `${diffYears}y`;
  if (diffMonths >= 1) return `${diffMonths}mo`;
  if (diffDays >= 2) return `${diffDays}d`;
  if (diffHours >= 1) return `${diffHours}h`;
  if (diffMinutes >= 1) return `${diffMinutes}m`;
  return 'just now';
};
